import BusinessSignin from './BusinessSignin';
import BusinessAuthWrapper from './BusinessAuthWrapper';

const Login = () => {
  return (
    <BusinessAuthWrapper>
      <BusinessSignin />
    </BusinessAuthWrapper>
  );
};

export default Login;
