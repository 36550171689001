import React from 'react';

type Props = { children: React.ReactNode };

const BusinessAuthScreen = ({ children }: Props) => {
  return (
    <div className="md:flex h-screen overflow-y-hidden">
      <div className="md:block hidden w-6/12 bg-tertiary" />
      <div className="md:w-6/12 w-full">{children}</div>
    </div>
  );
};

export default BusinessAuthScreen;
