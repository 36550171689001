import { useMutation } from 'react-query';
import toast from 'react-hot-toast';
import { userAxiosInstance } from '../../../../utils/userAxiosInstance';
import { useGetUserDataQuery } from '../auth/useGetUserDataQuery';

export const useInitiateUserVerification = () => {
  // const user = localStorage.getItem('BSUSER');
  // const thisUser = JSON.parse(`${user}`);

  // console.log('object thisUser', thisUser?.id);
  const { data } = useGetUserDataQuery();

  return useMutation(
    () =>
      userAxiosInstance
        .post(`auth/send-otp/${data?.data?.id}`)
        .then((res: any) => res.data),
    {
      onSuccess: async () => {
        // console.log('✅ useInitiateBusinessVerification');
      },
      onError: (error: any) => {
        console.log(
          '❌ useInitiateBusinessVerification ',
          error?.response?.data?.message
        );
        toast(error?.response?.data?.message);
      },
    }
  );
};
