import React, { useState } from 'react';

interface PricingOption {
  _id: string;
  monthsPaidFor: number;
  price: number;
  invoicePeriod: number;
}

const PricingOptions = ({
  data,
  payload,
  setPayload,
}: {
  data: any;
  payload: any;
  setPayload: any;
}) => {
  const [selectedPricingOption, setSelectedPricingOption] = useState<
    PricingOption[]
  >([]);

  const handlePlanChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedPlanCode = e.target.value;
    if (selectedPlanCode && data) {
      const selectedPlan = data.data.find(
        (item: any) => item.code === selectedPlanCode
      );
      setSelectedPricingOption(selectedPlan?.availablePricingOptions);
      setPayload({
        ...payload,
        productCode: selectedPlan?.code,
        // serviceType: selectedPlan?.serviceType,
      });
    }
  };

  const handlePricing = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedOptionId = e.target.value;
    const selectedOption = selectedPricingOption.find(
      (option) => option._id === selectedOptionId
    );

    if (selectedOption) {
      const { monthsPaidFor, price } = selectedOption;
      setPayload({
        ...payload,
        totalAmount: price,
        monthsPaidFor: monthsPaidFor,
      });
    }
  };

  return (
    <div>
      {data?.data && (
        <div>
          <label htmlFor="amount" className="mb-1 block heading text-tmd">
            Select Plan
          </label>
          <select
            className="w-full border-oslo border-[1px] p-3 rounded-md mb-4"
            onChange={handlePlanChange}
          >
            <option value="">-- Select a package --</option>
            {data &&
              data.data.map((item: any) => (
                <option key={item.code} value={item.code}>
                  {item.name}
                </option>
              ))}
          </select>
          {selectedPricingOption.length > 0 && (
            <div>
              <label className="mb-1 block heading text-tmd">
                Select Pricing
              </label>
              <select
                onChange={handlePricing}
                className="w-full border-oslo border-[1px] p-3 rounded-md mb-4"
              >
                <option value="">--Select a pricing option--</option>
                {selectedPricingOption.map((option) => (
                  <option key={option._id} value={option._id}>
                    {option.monthsPaidFor} month
                    {option.monthsPaidFor > 1 && 's'} -{' '}
                    {option.price.toLocaleString()}
                  </option>
                ))}
              </select>
            </div>
          )}
          {/* {JSON.stringify(payload?.productCode)}
          {JSON.stringify(payload?.serviceType)} */}
          {payload.productCode !== '' && payload.serviceType !== '' && (
            <div className="mb-4">
              <label
                htmlFor="smartCardNo"
                className="mb-1 block heading text-tmd"
              >
                Smart Card Number (258914450)
              </label>
              <input
                type="number"
                name="smartCardNo"
                maxLength={11}
                autoCorrect="none"
                className="w-full border-oslo border-[1px] p-3 rounded-md"
                value={payload.smartCardNo || ''}
                onChange={(e) => {
                  setPayload({ ...payload, smartCardNo: e.target.value });
                }}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default PricingOptions;
