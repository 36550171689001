import { useQuery, useQueryClient } from 'react-query';
import { axiosInstance } from '../../../../utils/axiosInstance';

export const useGetBusinessData = () => {
  const queryClient = useQueryClient();

  const { data, isLoading, error } = useQuery({
    queryKey: 'getBusinessData',
    queryFn: () => axiosInstance.get('/company/me').then((res) => res.data),
    onSuccess: (data) => {
      console.log('✅ getBusinessData');
      localStorage.setItem('businessData', JSON.stringify(data?.data));
    },
    onError: (error) => {
      console.log('❌ getBusinessData ==>', error);
    },
  });

  const handleRefresh = () => {
    queryClient.invalidateQueries('getBusinessData');
  };

  return { data, isLoading, error, handleRefresh };
};
