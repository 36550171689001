import React, { ReactNode, useState } from 'react';
import BusinessAuthScreen from '../components/Auth/BusinessAuthScreen';
import { Link, useLocation } from 'react-router-dom';

type Props = {
  children: ReactNode;
};

const BusinessAuthWrapper = ({ children }: Props) => {
  const [active, setActive] = useState(0);
  const logo = require('../../../assets/logo.png');

  const location = useLocation();

  return (
    <BusinessAuthScreen>
      <div className="p-4 max-w-[500px] mx-auto md:mt-20 mt-10 overflow-y-scroll">
        <span className="flex mb-6">
          <Link
            to="/"
            className="mr-[60px] text-[20px] font-bold flex gap-3 items-center heading"
          >
            <img src={logo} alt="BillSpot Logo" className="h-[40px]" />
            BillSpot
          </Link>
        </span>

        <h3 className="heading text-black md:text-dmd text-txs mt-2">
          BillSpot Business
        </h3>
        <p className="text-off">Open your business beyond boundaries</p>
        <div className="my-5">
          <div className="flex gap-2">
            <Link
              to="/business/register"
              className={`cursor-pointer py-2 px-4 rounded-full ${
                location?.pathname.includes('/business/register')
                  ? 'bg-tertiary text-white font-semibold'
                  : 'bg-water text-[#8d8d8d]'
              }`}
              onClick={() => setActive(0)}
            >
              Register
            </Link>
            <Link
              to="/business/login"
              className={`cursor-pointer py-2 px-4 rounded-full ${
                location?.pathname.includes('/business/login')
                  ? 'bg-tertiary text-white font-semibold'
                  : 'bg-water text-[#8d8d8d]'
              }`}
              onClick={() => setActive(1)}
            >
              Login
            </Link>
          </div>
        </div>
        {children}
      </div>
    </BusinessAuthScreen>
  );
};

export default BusinessAuthWrapper;
