import UserRank from '../../../../components/UserRank';
import { useGetUserDataQuery } from '../../../../hooks/api/app/auth/useGetUserDataQuery';
import { useNavigate } from 'react-router-dom';

const Profile = () => {
  const { data } = useGetUserDataQuery();

  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.clear();
    navigate('/');
  };

  return (
    <div className="flex md:flex-row flex-col md:gap-10 gap-5">
      {/* <p>{JSON.stringify(data?.data)}</p> */}
      <UserRank edit={true} />
      <div className="flex-1 mb-[20px]">
        <div className="max-w-[400px] rounded-md p-4 border-[1px] border-[#ddd] mb-4">
          <h3 className="heading text-tlg">Full Name</h3>
          <p className="text-oslo mb-4">
            {data?.data?.firstName} {data?.data?.lastName}
          </p>
          <h3 className="heading text-tlg">Phone Number</h3>
          <p className="text-oslo mb-4">{data?.data?.phone}</p>
          <h3 className="heading text-tlg">Username</h3>
          <p className="text-oslo mb-4">
            {data?.data?.username ? `@${data?.data?.username}` : '--'}
          </p>
          <h3 className="heading text-tlg">Email</h3>
          <p className="text-oslo mb-4">
            {data?.data?.email ? `${data?.data?.email}` : '--'}
          </p>
          <h3 className="heading text-tlg">Tier</h3>
          <span className="text-black p-2 rounded-full text-[12px] bg-offline">
            {data?.data?.userTier ? `${data?.data?.userTier}` : '--'}
          </span>
          <div className="mt-5 mb-3 bg-tertiary p-3 rounded-md ease-in-out duration-300 hover:scale-95 cursor-pointer">
            <div className="heading text-water text-center">
              Become a BillSpot Ambassador
            </div>
          </div>
        </div>
        <button
          onClick={handleLogout}
          className="p-3 text-white bg-red-400 rounded-lg font-bold md:hidden block"
        >
          Logout
        </button>
      </div>
    </div>
  );
};

export default Profile;
