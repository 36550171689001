import { useMutation } from 'react-query';
import toast from 'react-hot-toast';
import { axiosInstance } from '../../../../utils/axiosInstance';
// import { getUpdatedDetails } from '../getUpdatedDetails';
// import { useGetBusinessData } from '../profile/useGetBusinessData';
import { useSubmitAccount } from './useSubmitAccount';

export const useVerifyBankAccount = ({ accountNumber, bankCode }: any) => {
  const { mutateAsync } = useSubmitAccount();

  return useMutation(
    () =>
      axiosInstance
        .get(`/misc/banks/resolve/${accountNumber}/${bankCode}`)
        .then((res: any) => res.data),
    {
      onSuccess: async (data) => {
        console.log('✅ useUpdateBasicInfo');

        console.log('hehehehehe accountNumber ==>', typeof accountNumber);
        // getUpdatedDetails();
        mutateAsync({ accountNumber });
        // handleRefresh();
      },
      onError: (error: any) => {
        console.log('❌ useUpdateBasicInfo ');
        toast(error?.response?.data?.message);
      },
    }
  );
};
