export const apiConfig = {
  REGISTER: 'company/register',
  UPLOAD: 'company/upload-medias',
  REGISTER_USER: 'auth/register',
  LOGIN_USER: 'auth/login',
  USER_DATA: 'auth/me',
  WALLET: 'wallet/me',
  UPGRADE_TO_TIER_2: 'users/upgrade-to-tier-two',
  BUSINESS_STORES: '/giftcards/markets',
};
