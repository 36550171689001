import React from 'react';
import { AiFillFilter } from 'react-icons/ai';
import { transactionHistory } from '../../../../data';
import { In, Out } from '../../../../components/Icons';

type Props = {};

const Ngn = () => {
  const img = require('../../../../images/cards/ngn.png');

  return (
    <div className="flex md:flex-row flex-col gap-[60px]">
      <div className="md:w-5/12 w-full">
        <img src={img} alt="" />
        <button className="p-3 rounded-md bg-off cursor-pointer shadow-md text-white font-bold w-full mt-3 mb-10">
          Fund Card
        </button>

        <div className="border-b-[1px] border-[#ddd] mb-3">
          <p className="heading font-medium mt-4">Card Name</p>
          <div className="text-oslo mb-3">BillSpot Tech</div>
        </div>
        <div className="border-b-[1px] border-[#ddd] mb-3">
          <p className="heading">Card Number</p>
          <div className="text-oslo mb-3">0000 0000 0000 0000</div>
        </div>
        <div className="border-b-[1px] border-[#ddd] mb-3">
          <div className="flex">
            <div className="w-6/12">
              <p className="heading">CVV</p>
              <div className="text-oslo mb-3">123</div>
            </div>
            <div className="w-6/12">
              <p className="heading">Expiry Date</p>
              <div className="text-oslo mb-3">23/25</div>
            </div>
          </div>
        </div>
      </div>
      <div className="md:w-7/12 w-full">
        <div className="flex flex-row justify-between items-center mb-10">
          <h3 className="heading text-txl font-bold">Usage History</h3>
          <span className="cursor-pointer flex gap-3 items-center">
            <span>Sort</span>
            <AiFillFilter />
          </span>
        </div>
        <div className="h-[500px] overflow-y-scroll pr-5">
          {transactionHistory.map(({ id, title, date, status, type, time }) => (
            <div key={id} className="flex gap-3 mb-5 cursor-pointer">
              {type === 'in' ? <In /> : <Out />}
              <div className="flex-1">
                <div className="flex flex-row items-center justify-between">
                  <div>
                    <h3 className="heading">{title}</h3>
                    <span className="text-gray500 text-oslo text-tsm">
                      {date} - {time}
                    </span>
                  </div>
                  <span className="text-[#4caf50] font-medium">{status}</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Ngn;
