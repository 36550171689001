import React from 'react';
import { AiFillApple } from 'react-icons/ai';
import { IoLogoGooglePlaystore } from 'react-icons/io5';

type Props = {};

const DownloadBanner = () => {
  return (
    <div className="bg-offline rounded-md h-[320px]">
      <h3 className="md:text-tlg heading text-secondary text-center font-bold pt-[50px]">
        Download BillSpot App
      </h3>
      <p className="mx-auto max-w-[80%] text-center py-5 text-[#3d3d3d]">
        The one stop point for all essential bill payments, local gift cards ,
        airtime recharge, digital wallets, and more...
      </p>
      <div className="md:flex gap-4 pb-[60px] mt-[20px] justify-center">
        <span className="manrope500 flex items-center gap-3 bg-white text-tertiary rounded-full py-4 px-4 cursor-pointer ease-in-out duration-300 hover:shadow-md hover:bg-tertiary hover:text-white hover:translate-y-[4px]">
          <AiFillApple className="text-[30px]" />
        </span>
        <span className="md:mt-0 mt-4 manrope500 flex items-center gap-3 bg-white text-tertiary rounded-full py-4 px-4 cursor-pointer ease-in-out duration-300 hover:shadow-md hover:bg-tertiary hover:text-white hover:translate-y-[4px]">
          <IoLogoGooglePlaystore className="text-[30px]" />
        </span>
      </div>
    </div>
  );
};

export default DownloadBanner;
