import { useQuery } from 'react-query';
import { userAxiosInstance } from '../../../../utils/userAxiosInstance';

export const useSearchUserQuery = (
  id: string,
  enabled: boolean,
  setSearchTriggered: any
) => {
  const token = localStorage.getItem('BSUserToken');

  return useQuery({
    queryKey: ['useSearchUserQuery', id],
    queryFn: () =>
      userAxiosInstance
        .get(`wallet/find-user?search=${id}`)
        .then((res) => res.data),
    onSuccess: (data) => {
      setSearchTriggered(false);
    },
    onError: (error) => {
      console.log(`❌ useSearchUserQuery`, error);
    },
    enabled: !!id && enabled && !!token,
  });
};
