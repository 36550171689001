import { ChangeEvent, FormEvent, useState, useEffect } from 'react';
import { useModal } from '../../../../contexts/ModalContext';
import { useTierUpgradeMutation } from '../../../../hooks/api/app/wallet/TierUpgrade/useTierUpgradeMutation';
import { BiSolidBadgeCheck } from 'react-icons/bi';
import {
  useGetLGA,
  useGetStates,
} from '../../../../hooks/api/app/auth/useGetStates';
import { useSidebar } from '../../../../contexts/SidebarContext';
import Sidebar from '../../../../components/SideBar';

const initialState = {
  fullName: '',
  bvnNo: '',
  ninNo: '',
  // address: '',
  // city: '',
  // state: '',
};

const UpgradeAccount = () => {
  const [payload, setPayload] = useState({
    fullName: '',
    bvnNo: '',
    ninNo: '',
  });

  const [checker, setChecker] = useState(false);
  const { mutateAsync: upgrade, isLoading } = useTierUpgradeMutation();

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setPayload((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmission = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    upgrade(payload);
  };

  const handleClose = () => {
    setChecker(false);
    setPayload({
      fullName: '',
      bvnNo: '',
      ninNo: '',
    });
  };

  // const [selectedState, setSelectedState] = useState('');
  // const [selectedLGA, setSelectedLGA] = useState('');
  // const [payload, setPayload] = useState(initialState);
  // const { isModalOpen, closeModal, openModal } = useModal();
  // const { data: states } = useGetStates();
  // const { data: lgas, refetch: refetchLGAs } = useGetLGA(selectedState);
  // const { mutate, isLoading: verifying } = useTierUpgradeMutation(payload);

  // const handleInputChange = (
  //   e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  // ) => {
  //   const { name, value } = e.target;
  //   setPayload({
  //     ...payload,
  //     [name]: value,
  //   });
  // };

  // const handleStateChange = (e: ChangeEvent<HTMLSelectElement>) => {
  //   const state = e.target.value;
  //   setSelectedState(state);
  //   setPayload((prevPayload) => ({
  //     ...prevPayload,
  //     state,
  //     city: '',
  //   }));

  //   setSelectedLGA('');
  //   refetchLGAs();
  // };

  // const handleLGAChange = (e: ChangeEvent<HTMLSelectElement>) => {
  //   const lga = e.target.value;
  //   setSelectedLGA(lga);
  //   setPayload((prevPayload) => ({
  //     ...prevPayload,
  //     city: lga,
  //   }));
  // };

  // const handleVerification = (e: FormEvent<HTMLFormElement>) => {
  //   e.preventDefault();
  //   console.log('Submitting payload:', payload);
  //   mutate();
  // };

  // const handleClose = () => {
  //   closeModal();
  //   setPayload(initialState);
  //   setSelectedState('');
  //   setSelectedLGA('');
  // };

  // Fetch LGAs from API
  // const getLGAsFromApi = async (state: string) => {
  //   if (!state) return;
  //   try {
  //     const response = await fetch(
  //       `https://nga-states-lga.onrender.com/?state=${state}`
  //     );
  //     if (!response.ok) throw new Error('Failed to fetch LGAs');
  //     const data = await response.json();
  //     setLgas(data);
  //   } catch (error) {
  //     console.error(error);
  //     setLgas([]); // Clear LGAs if there's an error
  //   }
  // };

  // const handleStateChange = (e: ChangeEvent<any>) => {
  //   const selectedState = e.target.value;
  //   setSelectedState(selectedState);
  //   setPayload((prevPayload) => ({
  //     ...prevPayload,
  //     state: selectedState,
  //     city: '', // Reset city when state changes
  //   }));
  //   setSelectedLGA(''); // Clear selected LGA
  //   getLGAsFromApi(selectedState);
  // };

  // Handle LGA change
  // const handleLGAChange = (e: ChangeEvent<HTMLSelectElement>) => {
  //   const selectedLGA = e.target.value;
  //   setSelectedLGA(selectedLGA);
  //   setPayload((prevPayload) => ({ ...prevPayload, city: selectedLGA }));
  // };

  // useEffect(() => {
  //   if (selectedState) {
  //     refetchLGAs(); // Refetch LGAs when the selected state changes
  //   }
  // }, [selectedState, refetchLGAs]);

  return (
    <div>
      <div
        onClick={() => setChecker(true)}
        className="mt-4 mb-5 border-[1px] border-[#ddd] p-3 rounded-md flex gap-3 bg-offline cursor-pointer"
      >
        <span className="text-[40px]">
          <BiSolidBadgeCheck />
        </span>
        <span>
          <h3 className="heading font-bold">Upgrade Account to Tier 2</h3>
          <p className="text-[#3D3D3D] text-tsm">
            Verify your identity to enjoy higher transaction limits
          </p>
        </span>
      </div>
      <Sidebar
        title="Upgrade Account to Tier 2"
        isOpen={checker}
        onClose={handleClose}
      >
        <form onSubmit={handleSubmission}>
          <div className="">
            <div className="mb-4">
              <label htmlFor="fullName" className="mb-3 block heading text-tmd">
                Full name
              </label>
              <input
                type="text"
                name="fullName"
                placeholder=""
                disabled={isLoading}
                autoCorrect="none"
                className="w-full border-oslo border-[1px] p-3 rounded-md"
                value={payload.fullName}
                onChange={handleChange}
              />
            </div>
            <div className="mb-4">
              <label htmlFor="bvnNo" className="mb-3 block heading text-tmd">
                BVN
              </label>
              <input
                type="number"
                name="bvnNo"
                placeholder=""
                disabled={isLoading}
                autoCorrect="none"
                className="w-full border-oslo border-[1px] p-5 rounded-md"
                value={payload.bvnNo}
                onChange={handleChange}
              />
            </div>
            <div className="mb-4">
              <label htmlFor="ninNo" className="mb-3 block heading text-tmd">
                NIN
              </label>
              <input
                type="number"
                name="ninNo"
                placeholder=""
                disabled={isLoading}
                autoCorrect="none"
                className="w-full border-oslo border-[1px] p-3 rounded-md"
                value={payload.ninNo}
                onChange={handleChange}
              />
            </div>

            <button
              type="submit"
              className="p-3 text-white bg-tertiary rounded-md text-center w-full font-bold"
              disabled={isLoading}
            >
              {isLoading ? 'Submitting...' : 'Submit'}
            </button>
          </div>
        </form>
      </Sidebar>
      {/* <Modal
        isOpen={isModalOpen}
        onClose={handleClose}
        title="Upgrade account to Tier 2"
      >
        <form onSubmit={handleVerification}>
          <div className="mb-4">
            <label htmlFor="fullName" className="mb-1 block heading text-tmd">
              Full Name
            </label>
            <input
              type="text"
              name="fullName"
              placeholder="Enter your full name"
              autoCorrect="none"
              className="w-full border-oslo border-[1px] p-5 rounded-md"
              value={payload.fullName}
              onChange={handleInputChange}
            />
          </div>
          <div className="mb-4">
            <label htmlFor="bvnNo" className="mb-1 block heading text-tmd">
              BVN
            </label>
            <input
              type="text"
              name="bvnNo"
              placeholder="Enter your BVN"
              autoCorrect="none"
              maxLength={11}
              className="w-full border-oslo border-[1px] p-5 rounded-md"
              value={payload.bvnNo}
              onChange={handleInputChange}
            />
          </div>
          <div className="mb-4">
            <label htmlFor="ninNo" className="mb-1 block heading text-tmd">
              NIN
            </label>
            <input
              type="text"
              name="ninNo"
              placeholder="Enter your NIN"
              autoCorrect="none"
              className="w-full border-oslo border-[1px] p-5 rounded-md"
              value={payload.ninNo}
              onChange={handleInputChange}
            />
          </div>
          {/* <div className="mb-4">
            <label htmlFor="state" className="mb-1 block heading text-tmd">
              State
            </label>
            <select
              name="state"
              value={selectedState}
              onChange={handleStateChange}
              className="w-full border-oslo border-[1px] p-3 rounded-md"
            >
              <option value="">Select state</option>
              {states &&
                states.map((state: string) => (
                  <option key={state} value={state}>
                    {state}
                  </option>
                ))}
            </select>
          </div> */}
      {/* {Array.isArray(lgas) && ( */}
      {/* <div className="mb-4">
            <label htmlFor="lga" className="mb-1 block heading text-tmd">
              LGA
            </label>
            <select
              name="lga"
              value={selectedLGA}
              onChange={handleLGAChange}
              className="w-full border-oslo border-[1px] p-3 rounded-md"
              disabled={!selectedState}
            >
              <option value="">Select LGA</option>
              {lgas &&
                lgas.map((lga: string) => (
                  <option key={lga} value={lga}>
                    {lga}
                  </option>
                ))}
            </select>
          </div> */}
      {/* )} */}

      {/* <div className="mb-4">
            <label htmlFor="state" className="mb-1 block heading text-tmd">
              State
            </label>
            <select
              value={selectedState}
              onChange={handleStateChange}
              className="w-full border-oslo border-[1px] p-3 rounded-md"
            >
              <option value="">Select state</option>
              {states &&
                states.map((state: string) => (
                  <option key={state} value={state}>
                    {state}
                  </option>
                ))}
            </select>
          </div> */}

      {/* <div className="mb-4">
            <label htmlFor="lga" className="mb-1 block heading text-tmd">
              LGA
            </label>
            <select
              value={selectedLGA}
              disabled={selectedState === ''}
              onChange={handleLGAChange}
              className="w-full border-oslo border-[1px] p-3 rounded-md"
            >
              <option value="">Select LGA</option>
              {lgas &&
                lgas.map((lga: string) => (
                  <option key={lga} value={lga}>
                    {lga}
                  </option>
                ))}
            </select>
          </div> */}
      {/*<div className="mb-4">
            <label htmlFor="address" className="mb-1 block heading text-tmd">
              Address
            </label>
            <textarea
              name="address"
              placeholder="Enter your address"
              autoComplete="off"
              value={payload.address}
              onChange={handleInputChange}
              className="h-[150px] resize-none w-full border-oslo border-[1px] p-5 rounded-md"
            />
          </div>
          <button
            type="submit"
            className="p-5 text-white bg-tertiary rounded-md text-center w-full font-bold"
          >
            {verifying ? 'Submitting...' : 'Submit'}
          </button>
        </form>
      </Modal> */}
    </div>
  );
};

export default UpgradeAccount;
