import { useQuery } from 'react-query';
import { axiosInstance } from '../../../../utils/axiosInstance';

export const useGetBusinessWalletHistory = () => {
  const { data, isLoading, error } = useQuery({
    queryKey: 'getBusinessWalletHistory',
    queryFn: () =>
      axiosInstance.get('wallet/company-transactions').then((res) => res.data),
    onSuccess: (data) => {
      localStorage.setItem('businessData', JSON.stringify(data?.data));
    },
    onError: (error) => {
      console.log('❌ getBusinessWalletHistory ==>', error);
    },
  });

  return { data, isLoading, error };
};
