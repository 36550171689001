import { useMutation } from 'react-query';
import toast from 'react-hot-toast';
import { userAxiosInstance } from '../../../../utils/userAxiosInstance';
import { useNavigate } from 'react-router-dom';
import useGetUserDataQuery from '../../../../hooks/api/app/profile/useReloadUser';
import { useModal } from '../../../../contexts/ModalContext';

export const useSetTransactionPin = (transactionPin: string) => {
  const navigate = useNavigate();

  const { handleRefresh } = useGetUserDataQuery();

  return useMutation(
    () =>
      userAxiosInstance
        .post('auth/set-transaction-pin', { transactionPin })
        .then((res: any) => res.data),
    {
      onSuccess: async () => {
        handleRefresh();
        // openWelcomeModal;
        // setTimeout(() => {
        // openWelcomeModal;
        navigate('/app/home');

        // }, 2000);
      },
      onError: (error: any) => {
        console.log('❌ useSetTransactionPin ', error?.response?.data?.message);
        toast.error(
          error?.response?.data?.message || 'Failed to set transaction pin'
        );
      },
    }
  );
};
