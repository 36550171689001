import React, { createContext, useState, useContext, ReactNode } from 'react';

type KeyPadContextType = {
  isKeypadOpen: boolean;
  toggleKeypad: () => void;
  setIsKeypadOpen: any;
};

const KeypadContext = createContext<KeyPadContextType | undefined>(undefined);

export const KeypadProvider = ({ children }: { children: ReactNode }) => {
  const [isKeypadOpen, setIsKeypadOpen] = useState(false);

  const toggleKeypad = () => {
    setIsKeypadOpen(!isKeypadOpen);
  };

  return (
    <KeypadContext.Provider
      value={{ isKeypadOpen, toggleKeypad, setIsKeypadOpen }}
    >
      {children}
    </KeypadContext.Provider>
  );
};

export const useKeypad = () => {
  const context = useContext(KeypadContext);
  if (context === undefined) {
    throw new Error('Error from KeypadProvider');
  }
  return context;
};
