import { useMutation } from 'react-query';
import toast from 'react-hot-toast';
import { axiosInstance } from '../../../../utils/axiosInstance';
import { getUpdatedDetails } from '../getUpdatedDetails';
import { useGetBusinessData } from '../profile/useGetBusinessData';

export const useSubmitPriceRange = (payload: any) => {
  const { handleRefresh } = useGetBusinessData();

  return useMutation(
    () =>
      axiosInstance
        .post('company/set-price-range', {
          min: +payload.min,
          max: +payload.max,
        })
        .then((res: any) => res.data),
    {
      onSuccess: async () => {
        console.log('✅ useSubmitPriceRange');

        getUpdatedDetails();
        handleRefresh();
      },
      onError: (error: any) => {
        console.log('❌ useSubmitPriceRange');
        toast(error?.response?.data?.message);
      },
    }
  );
};
