import React, { ChangeEvent, useEffect, useState } from 'react';
import VerificationWrapper from './VerificationWrapper';
import { useGetBusinessFromLocalStorage } from '../../../../utils/useGetBusinessFromLocalStorage';
import { useSubmitSocialInfo } from '../../../../hooks/api/business/Onboarding/useSubmitSocialInfo';
import Loader from '../../../../components/Loader';

type Props = {};

const SocialInformation = (props: Props) => {
  const [payload, setPayload] = useState({
    instagram: '',
    facebook: '',
    twitter: '',
    whatsapp: '',
    linkedin: '',
  });
  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);

  const { BSBusinessData } = useGetBusinessFromLocalStorage();

  const { mutate, isLoading } = useSubmitSocialInfo(payload);

  useEffect(() => {
    const { instagram, facebook, twitter, whatsapp } = payload;
    const isFormValid = instagram && facebook && twitter && whatsapp;
    if (isFormValid) {
      setIsSubmitEnabled(true);
    } else setIsSubmitEnabled(false);
  }, [payload]);

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;

    setPayload((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    mutate();
  };
  return (
    <VerificationWrapper icon={''} title="Social Information">
      <form onSubmit={handleSubmit} className="mt-10">
        {/* <p className="text-xs">{JSON.stringify(payload)}</p> */}
        {/* <p className="text-xs">isFormValid {JSON.stringify(isSubmitEnabled)}</p> */}
        <div className="grid md:grid-cols-2 grid-cols-1 gap-4">
          <div className="mb-4">
            <label htmlFor="instagram" className="mb-3 block heading text-tmd">
              Instagram URL
            </label>
            <input
              type="url"
              name="instagram"
              placeholder=""
              autoComplete="off"
              value={payload.instagram}
              onChange={handleChange}
              className="w-full border-oslo border-[1px] p-3 rounded-md"
            />
          </div>

          <div className="mb-4">
            <label htmlFor="facebook" className="mb-3 block heading text-tmd">
              Facebook URL
            </label>
            <input
              type="url"
              name="facebook"
              placeholder=""
              value={payload.facebook}
              autoComplete="off"
              onChange={handleChange}
              className="w-full border-oslo border-[1px] p-3 rounded-md"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="twitter" className="mb-3 block heading text-tmd">
              Twitter URL
            </label>
            <input
              type="url"
              name="twitter"
              placeholder=""
              value={payload.twitter}
              onChange={handleChange}
              className="w-full border-oslo border-[1px] p-3 rounded-md"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="whatsapp" className="mb-3 block heading text-tmd">
              WhatsApp Number
            </label>
            <input
              type="tel"
              name="whatsapp"
              placeholder=""
              autoComplete="off"
              maxLength={11}
              value={payload.whatsapp}
              onChange={handleChange}
              className="resize-none w-full border-oslo border-[1px] p-3 rounded-md"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="linkedin" className="mb-3 block heading text-tmd">
              LinkedIn URL
            </label>
            <input
              type="url"
              name="linkedin"
              placeholder=""
              autoComplete="off"
              value={payload.linkedin}
              onChange={handleChange}
              className="resize-none w-full border-oslo border-[1px] p-3 rounded-md"
            />
          </div>
        </div>

        <button
          type="submit"
          className="bg-tertiary p-3 rounded-md text-center text-white font-medium cursor-pointer"
          disabled={isLoading}
        >
          Submit
        </button>
      </form>
    </VerificationWrapper>
  );
};

export default SocialInformation;
