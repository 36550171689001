import React from 'react';
import { Link } from 'react-router-dom';
import { FaFacebook, FaInstagram, FaLinkedin } from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';

type Props = { data: any };

const AppStoreFrontHeader = ({ data }: Props) => {
  return (
    <div className="">
      <div className="">
        <div className="text-tlg heading font-extrabold text-white mt-6 gap-4 flex items-center">
          <img
            style={{ height: '40px', width: '40px', borderRadius: '50%' }}
            src={data?.logo}
            alt=""
          />
          <span className="text-[#3d3d3d]">{data?.businessName}</span>
        </div>
        <div className="font-medium mt-4 heading">Bio</div>
        <div className="text-secondarysecondary  w-[80%]">{data?.bio}</div>
        <div className="font-medium mt-4 heading">Socials</div>
        <div className="flex gap-3 items-center text-secondarysecondary text-txs mt-3">
          <Link to={data?.socials?.facebook} target="_blank">
            <FaFacebook />
          </Link>
          <Link to={data?.socials?.twitter} target="_blank">
            <FaXTwitter />
          </Link>
          <Link to={data?.socials?.linkedin} target="_blank">
            <FaLinkedin />
          </Link>
          <Link to={data?.socials?.instagram} target="_blank">
            <FaInstagram />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default AppStoreFrontHeader;
