import { useMutation } from 'react-query';
import toast from 'react-hot-toast';
import { userAxiosInstance } from '../../../../utils/userAxiosInstance';
import { useSidebar } from '../../../../contexts/SidebarContext';

export const useValidatorGiftCard = () => {
  const { closeSidebar } = useSidebar();

  return useMutation(
    (payload: any) =>
      userAxiosInstance
        .post('giftcards/validate', payload)
        .then((res: any) => res.data),
    {
      onSuccess: async () => {
        // closeSidebar();
      },
      onError: (error: any) => {
        console.log('❌ useValidatorGiftCard ', error?.response?.data?.message);
        toast(error?.response?.data?.message);
      },
    }
  );
}; 

export const useInvalidateGiftCard = (id: string) => {
  const { closeSidebar } = useSidebar();

  return useMutation(
    (code: string) =>
      userAxiosInstance
        .patch(`giftcards/${id}/invalidate`, { code: code })
        .then((res: any) => res.data),
    {
      onSuccess: async () => {
        closeSidebar();
      },
      onError: (error: any) => {
        console.log(
          '❌ useInvalidateGiftCard ',
          error?.response?.data?.message
        );
        toast(error?.response?.data?.message);
      },
    }
  );
};
