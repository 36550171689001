import { useState } from 'react';
import AppLayout from '../../../components/AppLayout';
import { AiFillFilter } from 'react-icons/ai';
import {
  useGetSingleTransactionHistoryQuery,
  useGetWalletHistoryQuery,
} from '../../../hooks/api/app/wallet/WalletService/useGetWalletHistoryQuery';
import Loader from '../../../components/Loader';
import DownloadBanner from '../../../components/DownloadBanner';
import { In, Out } from '../../../components/Icons';
import Empty from '../../../components/Empty';
import Moment from 'react-moment';
import Sidebar from '../../../components/SideBar';
import { useSidebar } from '../../../contexts/SidebarContext';

type Transaction = {
  id: string;
  title: string;
  note: string;
  balanceBefore: number;
  balanceAfter: number;
  status: string;
  source: string;
  purpose: string;
  reference: string;
  createdAt: string;
  updatedAt: string;
  type: 'Debit' | 'Credit';
  amount: number;
};

const AppHistory = () => {
  const [detailPreview, setDetailPreview] = useState('');
  const { isFetching, data: history } = useGetWalletHistoryQuery();
  // const [transactions, setTransactions] = useState([]);
  // const [totalPages, setTotalPages] = useState(1);
  const { data: singleTransaction, isLoading: loadingSingleTransaction } =
    useGetSingleTransactionHistoryQuery(detailPreview);
  // const { data: walletBalance } = useGetWalletBalanceQuery();
  // const { openModal, isModalOpen, closeModal } = useModal();
  const { isSidebarOpen, openSidebar, closeSidebar } = useSidebar();
  const [currentPage, setCurrentPage] = useState(history?.data?.current_page);
  // const { data } = useGetUserDataQuery();

  const openDebitDetails = (id: string) => {
    setDetailPreview(id);
    openSidebar();
  };
  const closeDebitDetails = () => {
    closeSidebar();
    setDetailPreview('');
  };

  return (
    <AppLayout>
      <div className="md:flex block gap-3">
        <div className="md:w-7/12 w-full">
          <div className="flex flex-row justify-between items-center mb-10">
            <h3 className="heading text-txl font-bold">Transaction History</h3>
            <span className="cursor-pointer flex gap-3 items-center">
              <span>Sort</span>
              <AiFillFilter />
            </span>
          </div>
          {isFetching && <Loader />}
          {!isFetching && history && history?.data?.data?.length > 0 && (
            <div className="h-[80vh] overflow-y-scroll pr-5">
              {history.data.data.map(
                ({
                  id,
                  title,
                  note,
                  balanceBefore,
                  balanceAfter,
                  status,
                  type,
                  amount,
                  createdAt,
                }: Transaction) => (
                  <div
                    key={id}
                    className="flex gap-3 mb-[10px] cursor-pointer border-b-[1px] border-[#e6e6e6] pb-[10px]"
                    onClick={() => openDebitDetails(id)}
                  >
                    <div className="w-2/12">
                      {title === 'Mobile data' && <Out />}
                      {title === 'Airtime' && <Out />}
                      {title === 'Debit' && <Out />}
                      {title === 'Sevis' && <Out />}
                      {title === 'WES Service' && <Out />}
                      {title === 'Credit' && <In />}
                    </div>
                    <div className="w-8/12">
                      <div className="flex flex-col">
                        <span className="font-bold text-[14px]">{title}</span>
                        <span className="text-[12px] text-gray-500">
                          {note}
                        </span>
                        <div className="text-gray-500 text-[12px] mt-[5px]">
                          <Moment interval={0} format="DD-MM-YYYY hh:mm a">
                            {createdAt}
                          </Moment>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`w-2/12 font-medium text-right text-[14px] ${
                        title !== 'Credit' ? 'text-red-400' : 'text-green'
                      }`}
                    >
                      {amount && amount.toLocaleString()}
                      {/* {JSON.stringify(balanceAfter)} */}
                    </div>
                  </div>
                )
              )}
              {/* <div className="flex gap-2 items-center">
                <button
                  disabled={history?.data?.prev_page === null}
                  onClick={() => setCurrentPage(currentPage - 1)}
                >
                  <CaretLeft />
                </button>

                {Array.from(
                  { length: history?.data.to - history?.data.from + 1 },
                  (_, i) => (
                    <button
                      key={i}
                      className={`${
                        currentPage === history?.data.from + i
                          ? 'bg-secondary text-white'
                          : ''
                      } p-2 bg-water`}
                    >
                      {history?.data.from + i}
                    </button>
                  )
                )}

                <button
                  disabled={history?.data.next_page === null}
                  onClick={() => setCurrentPage(currentPage + 1)}
                >
                  <CaretRight />
                </button>
                {currentPage}
              </div> */}
            </div>
          )}
          <div></div>
          {!isFetching && history && history?.data?.data?.length === 0 && (
            <Empty
              text={
                'No transactions yet, kindly fund your account to get started'
              }
            />
          )}
        </div>
        <div className="md:w-5/12 md:block hidden mt-20">
          <DownloadBanner />
          <Sidebar
            isOpen={isSidebarOpen}
            onClose={closeDebitDetails}
            title={'Transaction Details'}
          >
            <div>
              {loadingSingleTransaction && <Loader />}
              {singleTransaction && !loadingSingleTransaction && (
                <div>
                  <div className="bg-offline p-10 rounded-md mb-3">
                    <div className="flex mx-auto mb-3">
                      {singleTransaction?.data?.type === 'Debit' ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          className="size-12  rounded-full mx-auto bg-water text-red-400"
                        >
                          <path
                            fillRule="evenodd"
                            d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm.53 5.47a.75.75 0 0 0-1.06 0l-3 3a.75.75 0 1 0 1.06 1.06l1.72-1.72v5.69a.75.75 0 0 0 1.5 0v-5.69l1.72 1.72a.75.75 0 1 0 1.06-1.06l-3-3Z"
                            clipRule="evenodd"
                          />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          className="size-12  rounded-full mx-auto bg-tertiary text-green"
                        >
                          <path
                            fillRule="evenodd"
                            d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm-.53 14.03a.75.75 0 0 0 1.06 0l3-3a.75.75 0 1 0-1.06-1.06l-1.72 1.72V8.25a.75.75 0 0 0-1.5 0v5.69l-1.72-1.72a.75.75 0 0 0-1.06 1.06l3 3Z"
                            clipRule="evenodd"
                          />
                        </svg>
                      )}
                    </div>
                    <p className="text-center text-black text-txs font-bold">
                      &#8358;{singleTransaction?.data?.amount}
                    </p>
                  </div>
                  <p className="text-oslol">Title</p>
                  <h3 className="text-off mb-3">
                    {singleTransaction?.data?.title}
                  </h3>
                  <p className="text-oslol">Reference ID</p>
                  <h3 className="text-off mb-3">
                    {singleTransaction?.data?.reference}
                  </h3>
                  <p className="text-oslol">Status</p>
                  <h3 className="text-off mb-3">
                    {singleTransaction?.data?.status}
                  </h3>
                  <p className="text-oslol">Note</p>
                  <h3 className="text-off mb-3">
                    {singleTransaction?.data?.note}
                  </h3>
                  <p className="text-oslol">Timing</p>
                  <Moment interval={0} format="DD-MM-YYYY hh:mm a">
                    {singleTransaction?.data?.createdAt}
                  </Moment>
                  {/* <h3 className="font-bold text-black mb-3">
                  </h3> */}
                </div>
              )}
            </div>
          </Sidebar>
        </div>
      </div>
    </AppLayout>
  );
};

export default AppHistory;

// const Pagination = ({
//   currentPage,
//   totalPages,
//   onPageChange,
// }: {
//   currentPage: any;
//   totalPages: any;
//   onPageChange: any;
// }) => {
//   const pages = [];

//   for (let i = 1; i <= totalPages; i++) {
//     pages.push(i);
//   }

//   return (
//     <div>
//       {currentPage > 1 && (
//         <button onClick={() => onPageChange(currentPage - 1)}>Previous</button>
//       )}
//       {pages.map((page) => (
//         <button
//           key={page}
//           onClick={() => onPageChange(page)}
//           style={{
//             margin: '0 5px',
//             fontWeight: page === currentPage ? 'bold' : 'normal',
//           }}
//         >
//           {page}
//         </button>
//       ))}
//       {currentPage < totalPages && (
//         <button onClick={() => onPageChange(currentPage + 1)}>Next</button>
//       )}
//     </div>
//   );
// };

const CaretLeft = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="size-6"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M15.75 19.5 8.25 12l7.5-7.5"
      />
    </svg>
  );
};

const CaretRight = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="size-6"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m8.25 4.5 7.5 7.5-7.5 7.5"
      />
    </svg>
  );
};
