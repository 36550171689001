import React, { useState } from 'react';
import AppLayout from '../../../components/AppLayout';
import Usd from './tabs/Usd';
import Ngn from './tabs/Ngn';

type Props = {};

const AppCards = () => {
  const [active, setActive] = useState('usd');

  const activeClass = 'border-b-[2px] border-secondary text-secondary';

  return (
    <AppLayout>
      <h3 className="heading text-txl font-bold">Virtual Cards</h3>
      <p className="text-off">
        Pay with ease on any platform with our virtual cards
      </p>
      <div className="border-b-[1px] border-[#ddd] w-full flex gap-3 mt-5 font-medium heading">
        <span
          className={`p-2 cursor-pointer ${
            active === 'usd' ? activeClass : 'text-oslo'
          }`}
          onClick={() => setActive('usd')}
        >
          Dollar Card
        </span>
        <span
          className={`p-2 cursor-pointer ${
            active === 'ngn' ? activeClass : 'text-oslo'
          }`}
          onClick={() => setActive('ngn')}
        >
          Naira Card
        </span>
      </div>
      <div className="mt-10">{active === 'usd' ? <Usd /> : <Ngn />}</div>
    </AppLayout>
  );
};

export default AppCards;
