import { toast } from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';
import { userAxiosInstance } from '../../../../utils/userAxiosInstance';
import useGetUserDataQuery from './useReloadUser';

export const useDeleteProfileImage = () => {
  const queryClient = useQueryClient();
  const { data } = useGetUserDataQuery();

  return useMutation(
    () => {
      const response = userAxiosInstance.patch('auth/delete-profile-image', {
        imageUrl: JSON.stringify(data?.data?.profilePicture),
      });

      return response;
    },
    {
      onSuccess: () => {
        console.log('✅ useDeleteProfileImage');
        toast.success('Submitted Successfully');
        queryClient.invalidateQueries('useGetUserDataQuery');
      },
      onError: (error: any) => {
        console.log('❌ useDeleteProfileImage');
        toast.error(
          error?.response?.data?.message || 'Error, please try again'
        );
      },
    }
  );
};
