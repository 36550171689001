import React, { useState, useEffect } from 'react';

const InitialLoader: React.FC = () => {
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const texts = ['Bills', 'Local Gift Cards', 'International Bills'];
  const [currentTextIndex, setCurrentTextIndex] = useState<number>(0);

  useEffect(() => {
    const loaderDisplayed = localStorage.getItem('loaderDisplayed');

    // If the loader hasn't been displayed before, show it
    if (!loaderDisplayed) {
      setShowLoader(true);
      let index = 0;
      const interval = setInterval(() => {
        if (index < texts.length - 1) {
          setCurrentTextIndex(index + 1);
          index++;
        } else {
          clearInterval(interval);
          setTimeout(() => {
            localStorage.setItem('loaderDisplayed', 'true');
            setShowLoader(false);
          }, 1000);
        }
      }, 1500);
    }
  }, [texts.length]);

  if (!showLoader) {
    return null;
  }

  return (
    <div className="fixed top-0 left-0 w-full h-full bg-white flex items-center justify-center text-white">
      <div className="w-[300px] h-[100px] flex items-center justify-center overflow-hidden p-4">
        <h1
          className="text-[24px] font-bold heading animate-slide-in text-tertiary"
          key={currentTextIndex}
        >
          {texts[currentTextIndex]}
        </h1>
      </div>
    </div>
  );
};

export default InitialLoader;
