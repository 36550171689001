import React from 'react';
import { Fade } from 'react-awesome-reveal';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import ScrollToTop from '../hooks/ScrollToTop';

type Props = {};

const Terms = () => {
  return (
    <ScrollToTop>
      <Fade>
        <section className="container mx-auto md:px-[100px]">
          <div className="mt-10 manrope800">
            Terms and Conditions for BillSpot Bill Payment App
          </div>

          <section className="text-off">
            <h3 className="my-4">
              <b>1. Acceptance of Terms:</b> By downloading, installing, or
              using the BillSpot Bill Payment App ("the App"), you agree to
              abide by these Terms and Conditions.
            </h3>
            <h3 className="my-4">
              <b>2. Use of the App:</b> The App is intended solely for the
              purpose of facilitating bill payments. You agree to use the App
              only for lawful purposes and in accordance with these Terms and
              Conditions.
            </h3>
            <h3 className="my-4">
              <b>3. Registration:</b> To use certain features of the App, you
              may be required to register and provide accurate, current, and
              complete information. You are responsible for maintaining the
              confidentiality of your account credentials and for all activities
              that occur under your account.
            </h3>
            <h3 className="my-4">
              <b>4. Bill Payments:</b> The App enables users to make payments to
              various billers. We are not responsible for any errors, delays, or
              inaccuracies in bill payments caused by incorrect information
              provided by users or by biller systems
            </h3>
            <h3 className="my-4">
              <b>5. Fees and Charges:</b> Some bill payments may incur fees or
              charges, which will be clearly disclosed to you before you confirm
              the payment.
            </h3>
            <h3 className="my-4">
              <b>6. Security:</b> We employ industry-standard security measures
              to protect your information. However, we cannot guarantee the
              security of your data and cannot be held liable for any
              unauthorized access or breach of security.
            </h3>
            <h3 className="my-4">
              <b>7. Intellectual Property:</b> All content and materials
              provided through the App, including but not limited to software,
              logos, trademarks, and service marks, are owned by or licensed to
              us and are protected by intellectual property laws.
            </h3>
            <h3 className="my-4">
              <b>8. Privacy Policy:</b> Your use of the App is also subject to
              our Privacy Policy, which governs how we collect, use, and
              disclose your personal information. By using the App, you consent
              to our collection and use of your information as described in the
              Privacy Policy
            </h3>
            <h3 className="my-4">
              <b>9. Limitation of Liability:</b> To the extent permitted by law,
              we shall not be liable for any direct, indirect, incidental,
              special, or consequential damages arising out of or in any way
              connected with your use of the App.
            </h3>
            <h3 className="my-4">
              <b>10. Modification of Terms:</b> We reserve the right to modify
              or update these Terms and Conditions at any time without prior
              notice. Your continued use of the App after any changes indicates
              your acceptance of the revised terms
            </h3>
            <h3 className="my-4">
              <b>11. Termination:</b> We reserve the right to terminate or
              suspend your access to the App at any time, with or without cause,
              and without prior notice.
            </h3>
            <h3 className="my-4">
              <b>12. Governing Law:</b> These Terms and Conditions shall be
              governed by and construed in accordance with the laws of Nigeria,
              without regard to its conflict of law principles.
            </h3>
            <h3 className="my-4">
              <b>13. Contact Us :</b> If you have any questions or concerns
              about these Terms and Conditions, please contact us at
            </h3>
          </section>
        </section>
      </Fade>
      <Footer />
    </ScrollToTop>
  );
};

export default Terms;
