import React, { ChangeEvent, FormEvent, useState } from 'react';
import { InVisible, Visible } from '../../../components/Icons';
import { Fade } from 'react-awesome-reveal';
import { useSigninUser } from '../../../hooks/api/app/auth/useSigninUser';
import AuthWrapper from './AuthWrapper';
import { Link } from 'react-router-dom';

const AppSignIn = () => {
  const [passwordVisibility, setPasswordVisibility] = useState(false);

  const [payload, setPayload] = useState({
    email: '',
    password: '',
  });

  const { mutateAsync: signin, isLoading } = useSigninUser(payload);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setPayload((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    signin();
  };

  return (
    <AuthWrapper>
      <Fade duration={1000}>
        <form onSubmit={handleSubmit}>
          <div className="mt-10">
            <div className="mb-4">
              <label htmlFor="email" className="mb-3 block heading text-tmd">
                Email Address
              </label>
              <input
                type="email"
                name="email"
                placeholder=""
                disabled={isLoading}
                autoCorrect="none"
                className="w-full border-oslo border-[1px] p-3 rounded-md"
                value={payload.email}
                onChange={handleChange}
              />
            </div>
            <div className="mb-4">
              <label htmlFor="password" className="mb-3 block heading">
                <div className="flex justify-between items-center">
                  <span>Password</span>
                  <Link to="/forgot-password" className="text-green">
                    Forgot Password?
                  </Link>
                </div>
              </label>
              <div className="relative">
                <input
                  type={passwordVisibility ? 'text' : 'password'}
                  name="password"
                  placeholder=""
                  disabled={isLoading}
                  autoCorrect="none"
                  className="w-full border-oslo border-[1px] p-3 rounded-md"
                  value={payload.password}
                  onChange={handleChange}
                />
                <span
                  onClick={() => setPasswordVisibility(!passwordVisibility)}
                  className="cursor-pointer absolute right-5 top-3"
                >
                  {passwordVisibility ? <InVisible /> : <Visible />}
                </span>
              </div>
            </div>
            <button
              type="submit"
              className="p-3 text-white bg-tertiary rounded-md text-center w-full font-bold"
              disabled={isLoading}
            >
              {isLoading ? 'Submitting...' : 'Submit'}
            </button>
          </div>
        </form>
      </Fade>
    </AuthWrapper>
  );
};

export default AppSignIn;
