import { useMutation, useQueryClient } from 'react-query';
import toast from 'react-hot-toast';
import { axiosInstance } from '../../../../utils/axiosInstance';

export const useCreateValidator = ({
  setBranch,
  setEmail,
  closeModal,
}: {
  setBranch: any;
  setEmail: any;
  closeModal: any;
}) => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ email, branch }: { email: string; branch: string }) =>
      axiosInstance
        .post('/company/assign-validator', {
          email: email,
          branchId: branch,
        })
        .then((res: any) => res.data),
    {
      onSuccess: async () => {
        console.log('✅ useCreateValidator');
        setEmail('');
        setBranch('');
        closeModal();
        queryClient.invalidateQueries('useGetValidators');
      },
      onError: (error: any) => {
        console.log('❌ useCreateValidator ');
        toast(error?.response?.data?.message);
      },
    }
  );
};
