import { motion, AnimatePresence } from 'framer-motion';
import { useEffect, useRef, useState } from 'react';

interface KeyPadProps {
  isKeypadOpen: boolean;
  toggleKeypad: () => void;
  payload: { [key: string]: any };
  setPayload: any;
  handleSubmit: any;
}

const KeyPad = ({
  isKeypadOpen,
  toggleKeypad,
  payload,
  setPayload,
  handleSubmit,
}: KeyPadProps) => {
  const [pin, setPin] = useState<string>(''); // State to store the pin
  const [activeIndex, setActiveIndex] = useState<number>(0); // Active input index
  const inputRefs = useRef<HTMLInputElement[]>([]); // Refs for input fields

  useEffect(() => {
    if (inputRefs.current[0]) {
      inputRefs.current[0].focus(); // Focus on the first input when keypad opens
    }
  }, [isKeypadOpen]);

  // Handle input changes for the PIN
  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const { value } = e.target;
    if (!/^\d*$/.test(value)) return;

    const newPin = pin.split('');
    newPin[index] = value;
    const updatedPin = newPin.join('');
    setPin(updatedPin);

    // Update the payload with the new transactionPin
    setPayload({
      ...payload,
      transactionPin: updatedPin,
    });

    // Automatically move to the next input
    if (value !== '' && index < 3) {
      setActiveIndex(index + 1);
      if (inputRefs.current[index + 1]) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  // Handle backspace keypress
  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) => {
    if (e.key === 'Backspace' && index > 0 && !pin[index]) {
      setActiveIndex(index - 1);
      if (inputRefs.current[index - 1]) {
        inputRefs.current[index - 1].focus();
      }
    }
  };

  // Handle input focus
  const handleFocus = (index: number) => {
    setActiveIndex(index);
  };

  // Handle form submission when Submit button is clicked
  const onSubmit = () => {
    handleSubmit();
  };

  // Function to handle keypad close and reset the pin
  const handleClose = () => {
    toggleKeypad(); // Close the keypad
    setPin(''); // Clear the pin state
    setPayload({ ...payload, transactionPin: '' }); // Clear the transactionPin in payload
  };

  return (
    <AnimatePresence>
      {isKeypadOpen && (
        <motion.div
          className="fixed inset-0 flex justify-center items-end bg-black bg-opacity-50 backdrop-blur-sm z-10"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <motion.div
            className="relative w-full max-w-md bg-white p-8 rounded-t-lg shadow-lg"
            initial={{ y: '100%' }}
            animate={{ y: '0%' }}
            exit={{ y: '100%' }}
            transition={{ type: 'spring', stiffness: 300, damping: 30 }}
            onClick={(e) => e.stopPropagation()}
          >
            <div className="heading mb-4 flex justify-between items-center">
              Enter your PIN
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="size-6 cursor-pointer text-oslol"
                onClick={handleClose} // Use handleClose to clear input on close
              >
                <path
                  fillRule="evenodd"
                  d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm-1.72 6.97a.75.75 0 1 0-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 1 0 1.06 1.06L12 13.06l1.72 1.72a.75.75 0 1 0 1.06-1.06L13.06 12l1.72-1.72a.75.75 0 1 0-1.06-1.06L12 10.94l-1.72-1.72Z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
            <div className="flex justify-between mb-6 gap-2">
              {Array.from({ length: 4 }).map((_, index) => (
                <input
                  key={index}
                  type="password"
                  value={pin[index] || ''}
                  ref={(el) => (inputRefs.current[index] = el!)}
                  onChange={(e) => handleChange(e, index)}
                  onKeyDown={(e) => handleKeyDown(e, index)}
                  onFocus={() => handleFocus(index)}
                  maxLength={1}
                  autoCorrect="off"
                  autoComplete="off"
                  autoFocus
                  className={`w-3/12 p-3 text-2xl text-center border-2 rounded-lg ${
                    activeIndex === index
                      ? 'border-blue-500 border-4'
                      : 'border-gray-300'
                  }`}
                />
              ))}
            </div>
            <button
              className="w-full font-bold py-3 bg-tertiary text-white rounded-lg"
              onClick={onSubmit}
              disabled={pin.length < 4} // Disable the button if pin length is less than 4
            >
              Submit
            </button>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default KeyPad;
