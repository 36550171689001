import { useMutation } from 'react-query';
import toast from 'react-hot-toast';
import { axiosInstance } from '../../../../utils/axiosInstance';
import { useGetBusinessData } from './useGetBusinessData';

export const useUpdateSocialInfo = (payload: any) => {
  const updatedPayload = { ...payload };
  const { handleRefresh } = useGetBusinessData();

  if (updatedPayload.whatsapp.startsWith('0')) {
    updatedPayload.whatsapp = '+234' + updatedPayload.whatsapp.slice(1);
  }

  return useMutation(
    () =>
      axiosInstance
        .patch('company/profile/update-socials', updatedPayload)
        .then((res: any) => res.data),
    {
      onSuccess: async (data) => {
        console.log('✅ useUpdateBasicInfo');
        toast.success(data?.data);
        handleRefresh();
      },
      onError: (error: any) => {
        console.log('❌ useUpdateBasicInfo ');
        toast(error?.response?.data?.message);
      },
    }
  );
};
