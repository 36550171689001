import { useQuery } from 'react-query';
import { axiosInstance } from '../../../../utils/axiosInstance';

export const useGetBusinessBranches = () => {
  const { data, isLoading, error } = useQuery({
    queryKey: 'useGetBusinessBranches',
    queryFn: () =>
      axiosInstance.get('/company/branches').then((res) => res.data),
    onSuccess: (data) => {
      // localStorage.setItem('businessData', JSON.stringify(data?.data));
    },
    onError: (error) => {
      console.log('❌ useGetBusinessBranches ==>', error);
    },
  });

  return { data, isLoading, error };
};
