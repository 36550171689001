import React, { useState, ChangeEvent, FormEvent } from 'react';
import { Link } from 'react-router-dom';
import { InVisible, Visible } from '../../../components/Icons';
import { useRegisterBusiness } from '../../../hooks/api/business/auth/useRegisterBusiness';
import { Fade } from 'react-awesome-reveal';
import toast from 'react-hot-toast';
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';

const BusinessSignup = () => {
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const [agreement, setAgreemeent] = useState(false);
  const [phone, setPhone] = useState('');

  const [payload, setPayload] = useState({
    email: '',
    password: '',
    phoneNumber: '',
  });

  const { mutateAsync: register, isLoading } = useRegisterBusiness();

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setPayload((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (agreement === false) {
      toast.error(
        'Ensure you fill all the fields and agree to the terms and conditions'
      );
      return;
    }

    const updatedPayload = { ...payload };

    let updatedPayload2 = {
      ...updatedPayload,
      phoneNumber: phone,
    };

    try {
      await register(updatedPayload2);
      // navigate('/business/auth');
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    } catch (error) {
      console.error('Registration error:', error);
    }
  };

  return (
    <Fade duration={1000}>
      <form onSubmit={handleSubmit}>
        <div className="mt-10">
          <div className="mb-4">
            <label htmlFor="email" className="mb-3 block heading text-tmd">
              Email
            </label>
            <input
              type="email"
              name="email"
              placeholder="Email"
              disabled={isLoading}
              autoCorrect="none"
              autoComplete="off"
              className="w-full border-oslo border-[1px] p-3 rounded-md"
              value={payload.email}
              onChange={handleChange}
            />
          </div>

          {/* <div className="mb-4">
            <label
              htmlFor="phoneNumber"
              className="mb-3 block heading text-tmd"
            >
              Phone Number
            </label>
            <input
              type="text"
              name="phoneNumber"
              placeholder="Phone Number"
              disabled={isLoading}
              autoCorrect="none"
              autoComplete="off"
              maxLength={11}
              className="w-full border-oslo border-[1px] p-5 rounded-md"
              value={payload.phoneNumber}
              onChange={handleChange}
            />
          </div> */}
          <div className="mb-4">
            <label htmlFor="phone" className="mb-3 block heading text-tmd">
              Phone Number
            </label>
            <PhoneInput
              defaultCountry="ng"
              value={phone}
              onChange={(phone) => setPhone(phone)}
            />
          </div>

          <div className="mb-4">
            <label htmlFor="password" className="mb-3 block heading">
              Password
            </label>
            <div className="relative">
              <input
                type={passwordVisibility ? 'text' : 'password'}
                name="password"
                placeholder="Password"
                disabled={isLoading}
                autoCorrect="none"
                autoComplete="off"
                className="w-full border-oslo border-[1px] p-3 rounded-md"
                value={payload.password}
                onChange={handleChange}
              />
              <span
                onClick={() => setPasswordVisibility(!passwordVisibility)}
                className="cursor-pointer absolute right-5 top-3"
              >
                {passwordVisibility ? <InVisible /> : <Visible />}
              </span>
            </div>
          </div>

          <div className="mb-4 flex gap-3">
            <input type="checkbox" onClick={() => setAgreemeent(!agreement)} />
            <span>
              I agree to Billpot Business{' '}
              <Link
                to="/busisness/terms"
                className="text-tertiary underline underline-offset-[2px]"
              >
                Terms and Conditions
              </Link>
            </span>
          </div>
          <button
            type="submit"
            className="p-3 text-white bg-tertiary rounded-md text-center w-full font-bold"
            disabled={isLoading}
          >
            {isLoading ? 'Submitting...' : 'Submit'}
          </button>
        </div>
      </form>
    </Fade>
  );
};

export default BusinessSignup;
