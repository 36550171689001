import AppLayout from '../../../components/AppLayout';
import { useGetUserDataQuery } from '../../../hooks/api/app/auth/useGetUserDataQuery';

import BillPayment from './components/BillPayments';
import EducationalBills from './components/BillPayments/EducationalBills';
import BalancePreviewCard from './componentsV2/BalancePreviewCard';
import UpgradeAccount from './componentsV2/UpgradeAccount';
import UpgradeAccountTierThree from './componentsV2/UpgradeAccountTierThree';

const AppHome = () => {
  const { data } = useGetUserDataQuery();

  return (
    <AppLayout>
      <h3 className="heading text-txl font-bold capitalize">
        Hello {data ? `${data?.data?.username}` : 'There'},
      </h3>
      <p className="text-[#3d3d3d]">Our Beloved BillSpotter</p>
      <BalancePreviewCard />
      {data?.data?.tiers.length === 1 && <UpgradeAccount />}
      {data?.data?.tiers.length === 2 && <UpgradeAccountTierThree />}
      <h3 className="heading text-txl font-medium mt-5">Utility Bills</h3>
      <BillPayment />
      <h3 className="heading text-txl font-medium mt-5">Educational Bills</h3>
      <EducationalBills />
    </AppLayout>
  );
};

export default AppHome;
