import React, { ReactNode } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  children: ReactNode;
  subtitle?: string;
};

const Sidebar = ({ isOpen, onClose, children, title, subtitle }: Props) => {
  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          className="fixed inset-0 flex justify-end bg-black bg-opacity-50 backdrop-blur-sm z-10"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <motion.div
            className="relative w-[450px] bg-white h-full shadow-lg"
            initial={{ x: '100%' }}
            animate={{ x: '0%' }}
            exit={{ x: '100%' }}
            transition={{ type: 'spring', stiffness: 300, damping: 30 }}
            onClick={(e) => e.stopPropagation()}
          >
            <div className="bg-water mb-[10px] p-8 rounded-t-lg">
              <div className="flex items-center justify-between">
                <div>
                  {title && (
                    <h2 className="text-txl heading font-bold">{title}</h2>
                  )}
                  {subtitle && (
                    <div className="text-tmd font-medium normal text-oslo">
                      {subtitle}
                    </div>
                  )}
                </div>
                <span onClick={onClose} className="cursor-pointer">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 18 18 6M6 6l12 12"
                    />
                  </svg>
                </span>
              </div>
            </div>

            <div className="p-8 h-[80%] overflow-y-scroll">{children}</div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default React.memo(Sidebar);
