import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import VerificationWrapper from './VerificationWrapper';
import { useVerifyCacInfo } from '../../../../hooks/api/business/Onboarding/useVerifyCacInfo';

const CompanyCACVerification = () => {
  const [payload, setPayload] = useState({
    rcNumber: '',
    companyName: '',
    companyType: 'RC',
  });
  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);

  const { mutate, isLoading } = useVerifyCacInfo(payload);

  useEffect(() => {
    const { rcNumber, companyName } = payload;
    const isFormValid = companyName && rcNumber;
    if (isFormValid) {
      setIsSubmitEnabled(true);
    } else setIsSubmitEnabled(false);
  }, [payload]);

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;

    setPayload((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    mutate();
  };
  return (
    <VerificationWrapper icon={''} title="Company CAC Verification">
      <form onSubmit={handleSubmit} className="mt-10">
        <div className="grid md:grid-cols-2 grid-cols-1 gap-4">
          <div className="mb-4">
            <label htmlFor="rcNumber" className="block heading text-tmd">
              RC Number (092932)
            </label>
            <p className="mb-3 text-[#a5a5a5">
              The company's registration number
            </p>
            <input
              type="number"
              name="rcNumber"
              placeholder=""
              autoComplete="off"
              value={payload.rcNumber}
              onChange={handleChange}
              maxLength={6}
              className="w-full border-oslo border-[1px] p-3 rounded-md"
            />
          </div>

          <div className="mb-4">
            <label htmlFor="companyName" className="block heading text-tmd">
              Company Name (Test Company)
            </label>
            <p className="mb-3 text-[#a5a5a5">The company's name</p>
            <input
              type="text"
              name="companyName"
              placeholder=""
              value={payload.companyName}
              autoComplete="off"
              onChange={handleChange}
              className="w-full border-oslo border-[1px] p-3 rounded-md"
            />
          </div>
        </div>

        <button
          type="submit"
          className={`bg-tertiary p-3 rounded-md text-center text-white font-medium cursor-pointer ${
            isSubmitEnabled === false
              ? 'opacity-50 cursor-not-allowed'
              : 'cursor-pointer'
          } `}
          disabled={isLoading || isSubmitEnabled === false}
        >
          Submit
        </button>
      </form>
    </VerificationWrapper>
  );
};

export default CompanyCACVerification;
