// import React, { ChangeEvent, useState } from 'react';
// import VerificationWrapper from './VerificationWrapper';

// const CompanyLogo = () => {
// const [coverPhoto, setCoverPhoto] = useState<File | null>(null);

// const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {  const handleCoverPhotoChange = (event: ChangeEvent<HTMLInputElement>) => {
//   const file = event.target.files?.[0];
//   if (file) {
//     setCoverPhoto(file);
//   }
// };

//   return (
//     <VerificationWrapper icon={''} title="Company Logo">
//       <h3>Click the button below to upload your company logo</h3>
//       <label className="text-center text-black block cursor-pointer">
//         {coverPhoto ? 'Change photo' : 'Drag and drop or browse file to upload'}
//         <input
//           type="file"
//           className="hidden"
//           accept="image/*"
//           onChange={handleCoverPhotoChange}
//           aria-label="Upload Cover Photo Input"
//         />
//       </label>
//       <p className="text-center text-[#a5a5a5] mt-[7px]">
//         Max file size: 20MB, 3000x3000px
//       </p>
//     </VerificationWrapper>
//   );
// };

// export default CompanyLogo;

// import React, { ChangeEvent, useState } from 'react';
// import VerificationWrapper from './VerificationWrapper';
// import { useSubmitCompanyLogo } from '../../../../hooks/api/business/Onboarding/useSubmitCompanyLogo';

// type Props = {};

// const CompanyLogo = (props: Props) => {
//   const [coverPhoto, setCoverPhoto] = useState<File | null>(null);

//   const handleCoverPhotoChange = (event: ChangeEvent<HTMLInputElement>) => {
//     const file = event.target.files?.[0];
//     if (file) {
//       setCoverPhoto(file);
//     }
//   };

//   const { mutate, isLoading: uploading } = useSubmitCompanyLogo(coverPhoto);

//   const handleUpload = (e: any) => {
//     e.preventDefault();
//     mutate();
//   };
//   return (
//     <VerificationWrapper icon="" title="Company Logo">
//       <div className="mt-[30px] bg-[#F2F2F2] rounded-md py-[35px]">
//         <label className="text-center text-black block cursor-pointer">
//           {coverPhoto
//             ? 'Change photo'
//             : 'Drag and drop or browse file to upload'}
//           <input
//             type="file"
//             className="hidden"
//             accept="image/*"
//             onChange={handleCoverPhotoChange}
//             aria-label="Upload Cover Photo Input"
//           />
//         </label>
//         <p className="text-center text-[#a5a5a5] mt-[7px]">
//           Max file size: 20MB, 3000x3000px
//         </p>
//       </div>
//       {JSON.stringify(coverPhoto)}
//       {coverPhoto && (
//         <form onSubmit={handleUpload}>
//           <h3 className="mt-3">Logo Preview</h3>
//           <div className="my-3 flex gap-4 items-center">
//             <img
//               src={URL.createObjectURL(coverPhoto)}
//               alt="Cover Preview"
//               className="h-[50px] w-[50px] rounded-md"
//             />
//             <p className="text-center text-[#333] mt-2">{coverPhoto.name}</p>
//           </div>
//           <button
//             type="submit"
//             className="p-3 text-white bg-tertiary rounded-md text-center font-bold"
//             disabled={uploading}
//           >
//             {uploading ? 'Submitting...' : 'Submit'}
//           </button>
//         </form>
//       )}
//     </VerificationWrapper>
//   );
// };

// export default CompanyLogo;

import React, { ChangeEvent, useState } from 'react';
import VerificationWrapper from './VerificationWrapper';
import { useSubmitCompanyLogo } from '../../../../hooks/api/business/Onboarding/useSubmitCompanyLogo';

type Props = {};

const CompanyLogo = (props: Props) => {
  const [coverPhoto, setCoverPhoto] = useState<File | null>(null);

  const handleCoverPhotoChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setCoverPhoto(file);
    }
  };

  const { mutate, isLoading: uploading } = useSubmitCompanyLogo(coverPhoto);

  const handleUpload = (e: React.FormEvent) => {
    e.preventDefault();
    mutate();
  };

  return (
    <VerificationWrapper icon="" title="Company Logo">
      <div className="mt-[30px] bg-[#F2F2F2] rounded-md py-[35px]">
        <div className="flex mx-auto">
          <label className="text-center mx-auto cursor-pointer rounded-full bg-tertiary text-white text-tsm font-medium p-3">
            {coverPhoto ? 'Change photo' : 'Click to upload file'}
            <input
              type="file"
              className="hidden"
              accept="image/*"
              onChange={handleCoverPhotoChange}
              aria-label="Upload Cover Photo Input"
            />
          </label>
        </div>
        <p className="text-center text-[#a5a5a5] mt-[7px]">
          Maximum file size: 500kb
        </p>
      </div>
      {coverPhoto && (
        <form onSubmit={handleUpload}>
          <h3 className="mt-3">Logo Preview</h3>
          <div className="my-3 flex gap-4 items-center">
            <img
              src={URL.createObjectURL(coverPhoto)}
              alt="Cover Preview"
              className="h-[50px] w-[50px] rounded-md"
            />
            <p className="text-center text-[#333]">{coverPhoto.name}</p>
          </div>
          <button
            type="submit"
            className="p-3 text-white bg-tertiary rounded-md text-center font-bold"
            disabled={uploading}
          >
            {uploading ? 'Submitting...' : 'Submit'}
          </button>
        </form>
      )}
    </VerificationWrapper>
  );
};

export default CompanyLogo;
