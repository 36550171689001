import { useMutation, useQuery, useQueryClient } from 'react-query';
import toast from 'react-hot-toast';
import { axiosInstance } from '../../../../utils/axiosInstance';
import { useSidebar } from '../../../../contexts/SidebarContext';

interface PayloadProps {
  branchAddress: string;
  city: string;
  state: string;
  phone: string;
}

export const useCreateBranch = (
  setPayload: React.Dispatch<React.SetStateAction<PayloadProps>>,
  selectedLGA: React.Dispatch<React.SetStateAction<any>>,
  setStates: React.Dispatch<React.SetStateAction<any>>
) => {
  const { closeSidebar } = useSidebar();
  const queryClient = useQueryClient();

  return useMutation(
    (updatedPayload: PayloadProps) =>
      axiosInstance
        .post('/company/branches', updatedPayload)
        .then((res: any) => res.data),
    {
      onSuccess: (data: any) => {
        toast.success(data?.message);
        closeSidebar();
        setPayload({
          branchAddress: '',
          city: '',
          state: '',
          phone: '',
        });
        selectedLGA('');
        setStates('');
        queryClient.invalidateQueries('useGetBusinessBranches');
      },
      onError: (error: any) => {
        toast.error(error?.response?.data?.message || 'An error occurred');
      },
    }
  );
};
