import React from 'react';

type Props = {};

const Loader = (props: Props) => {
  return (
    <div className="flex mx-auto justify-center h-[400px]">
      <div className="dots">
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export default Loader;
