import { useMutation } from 'react-query';
import toast from 'react-hot-toast';
import { axiosInstance } from '../../../../utils/axiosInstance';
import { useNavigate } from 'react-router-dom';

interface Props {
  email: string;
  password: string;
}

export const useSigninBusiness = () => {
  const navigate = useNavigate();

  return useMutation(
    (payload: Props) =>
      axiosInstance
        .post('/company/login', payload)
        .then((res: any) => res.data),
    {
      onSuccess: async (data: any) => {
        localStorage.setItem(
          'BSbusinessToken',
          JSON.stringify(data?.data?.token)
        );
        console.log('token', data?.data?.token);

        const url = `${process.env.REACT_APP_BASE_URL}/company/me`;

        const options = {
          method: 'GET', // HTTP method
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${data?.data?.token} `, // if authentication is required
          },
        };

        fetch(url, options)
          .then((response) => {
            if (!response.ok) {
              toast('Network response was not ok');
            }
            return response.json();
          })
          .then((data) => {
            console.log(data);
            localStorage.setItem('@BSCompanyData', JSON.stringify(data?.data));
            toast.success('Sign in successful');

            if (data?.data?.progressCount < 10) {
              navigate('/business/onboarding');
            } else {
              navigate('/business/home');
            }
          })
          .catch((error) => {
            console.error(
              'There was a problem with the fetch operation:',
              error
            );
            toast('Error, Kindly try again');
          });
      },
      onError: (error: any) => {
        console.log('Registration failed', error);
        console.log(
          '==> Registration failed <==',
          error?.response?.data?.message
        );
        toast(error?.response?.data?.message);
      },
    }
  );
};
