import { useMutation } from 'react-query';
import toast from 'react-hot-toast';
import { userAxiosInstance } from '../../../../utils/userAxiosInstance';
import useReloadUser from './useReloadUser';

export const useCreateUsername = (username: string) => {
  const { handleRefresh } = useReloadUser();

  return useMutation(
    () =>
      userAxiosInstance
        .post('auth/create-update-profile/', { username: username })
        .then((res: any) => res.data),
    {
      onSuccess: async () => {
        handleRefresh();
        // console.log('✅ useInitiateBusinessVerification');
      },
      onError: (error: any) => {
        console.log('❌ useUpdateUsername ', error?.response?.data?.message);
        toast(error?.response?.data?.message);
      },
    }
  );
};
