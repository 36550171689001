import { useMutation } from 'react-query';
import toast from 'react-hot-toast';
import { axiosInstance } from '../../../../utils/axiosInstance';
import { useGetBusinessData } from './useGetBusinessData';

export const useUpdatePriceLimit = (payload: any) => {
  const { handleRefresh } = useGetBusinessData();
  return useMutation(
    () =>
      axiosInstance
        .patch('company/price-range', {
          min: +payload?.min,
          max: +payload?.max,
        })
        .then((res: any) => res.data),
    {
      onSuccess: async (data) => {
        console.log('✅ useUpdatePriceLimit');
        toast.success(data?.message);

        handleRefresh();
      },
      onError: (error: any) => {
        console.log('❌ useUpdatePriceLimit');
        toast(error?.response?.data?.message);
      },
    }
  );
};
