import React from 'react';
import AppLayout from '../../../components/AppLayout';
import AppStoreFrontHeader from './components/AppStoreFrontHeader';
import AppStoreFrontGallery from './components/AppStoreFrontGallery';
import AppStoreSelector from './components/AppStoreSelector';
import { useGetSingleStoreQuery } from '../../../hooks/api/app/appstore/useGetSingleStoreQuery';
import { useParams } from 'react-router-dom';
import Loader from '../../../components/Loader';

const AppStoreFront = () => {
  const { id } = useParams();
  const { data, isFetching } = useGetSingleStoreQuery(`${id}`);

  return (
    <AppLayout>
      {isFetching && <Loader />}
      {!isFetching && data && (
        <div>
          <AppStoreFrontGallery images={data?.data?.mediaImages} />
          <AppStoreFrontHeader data={data?.data} />
          <div className="overflow-y-auto">
            <AppStoreSelector data={data} />
          </div>
        </div>
      )}
    </AppLayout>
  );
};

export default AppStoreFront;
