import React from 'react';
import BusinessDashboardLayout from '../components/Layout/BusinessDashboardLayout';
import { useGetBusinessWalletHistory } from '../../../hooks/api/business/wallet/useGetBusinessWalletHistory';
import Empty from '../../../components/Empty';
import Loader from '../../../components/Loader';
import Moment from 'react-moment';

const BusinessSales = () => {
  const { data: history, isLoading } = useGetBusinessWalletHistory();

  return (
    <BusinessDashboardLayout>
      <div className="">
        <div className="heading text-txs font-medium mt-10">Sales</div>
        <p className="text-oslol mb-4">Track all gift card sales here</p>

        {isLoading && <Loader />}

        <div className="p-[20px] text-[13px]">
          {history && history?.data?.data.length === 0 && (
            <Empty text={'No Sold Gift Card yet'} />
          )}
        </div>

        {history && history?.data?.data.length > 0 && (
          <div>
            <div className="flex gap-2 py-3 border-b-[1px] border-ash font-bold bg-[#3d3d3d] text-white">
              <div className="w-3/12 truncate">ID</div>
              <div className="w-4/12 truncate">Date</div>
              <div className="w-4/12 truncate">Amount</div>
            </div>

            <div className="overflow-y-scroll h-[50vh]">
              {history?.data?.data.map(
                ({
                  id,
                  createdAt,
                  balanceBefore,
                  balanceAfter,
                }: {
                  id: string;
                  createdAt: string;
                  balanceBefore: string;
                  balanceAfter: string;
                }) => (
                  <div
                    key={id}
                    className="flex gap-2 p-2 border-b-[1px] border-ash"
                  >
                    <span className="w-3/12 truncate">{id}</span>
                    <span className="w-4/12 truncate">
                      <Moment
                        format="DD/MM/YYYY  hh:mm a"
                        interval={0}
                        className="text-xs"
                      >
                        {createdAt}
                      </Moment>
                    </span>
                    <span className="w-4/12 truncate">
                      &#8358; {`${+balanceAfter - +balanceBefore}`}
                    </span>
                  </div>
                )
              )}
            </div>
          </div>
        )}
      </div>
    </BusinessDashboardLayout>
  );
};

export default BusinessSales;
