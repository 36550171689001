import { ChangeEvent, useEffect, useState } from 'react';
import { useModal } from '../../../contexts/ModalContext';
import { useSidebar } from '../../../contexts/SidebarContext';
import CreateBranch from '../home/CreateBranch';
import Modal from '../../../components/Modal';
import BusinessDashboardLayout from '../components/Layout/BusinessDashboardLayout';
import Validator from './tabs/Validator';
import Branches from './tabs/Branches';
import { useCreateValidator } from '../../../hooks/api/business/validators/useCreateValidator';
import { useGetBusinessBranches } from '../../../hooks/api/business/branches/useGetBranches';
import toast from 'react-hot-toast';

const BusinessSettings = () => {
  const { isModalOpen, closeModal, openModal } = useModal();
  const { isSidebarOpen, closeSidebar, openSidebar } = useSidebar();

  const [email, setEmail] = useState('');
  const [branch, setBranch] = useState('');

  const [active, setActive] = useState(0);

  const { mutate, isLoading } = useCreateValidator({
    setBranch,
    setEmail,
    closeModal,
  });

  const { data } = useGetBusinessBranches();

  const [valid, setValid] = useState(false);

  useEffect(() => {
    if (email && branch) {
      setValid(true);
    } else setValid(false);
  }, [branch, email]);

  const handleBranchSelection = (e: ChangeEvent<any>) => {
    const selectedBranch = e.target.value;
    setBranch(selectedBranch);
  };

  return (
    <BusinessDashboardLayout>
      <div className="container mx-auto px-3">
        <div className="flex gap-3">
          <button
            className={`p-3 text-tertiary ${
              active === 0 && 'bg-tertiary text-white'
            }`}
            onClick={() => setActive(0)}
          >
            Branches
          </button>
          <button
            className={`p-3 text-tertiary ${
              active === 1 && 'bg-tertiary text-white'
            }`}
            onClick={() => setActive(1)}
          >
            Validators
          </button>
        </div>

        <div className="rounded-md border-[1px] border-[#c8c8c8] overflow-hidden flex-1">
          <div className="p-[20px] border-b-[1px] border-[#c8c8c8] flex justify-between items-center">
            <h3 className="font-bold heading text-tlg text-black">
              {active === 0 ? 'Branches' : 'Validators'}
            </h3>
            {active === 0 ? (
              <button
                onClick={openSidebar}
                className="text-tertiary border-[1px] border-tertiary py-2 px-4 rounded-full heading"
              >
                Add Branch
              </button>
            ) : (
              <button
                onClick={openModal}
                className="bg-tertiary py-2 px-4 rounded-full text-white heading"
              >
                Add
              </button>
            )}
          </div>
          <div className="px-[20px] pb-[20px] text-black">
            <div>{active === 0 ? <Branches /> : <Validator />}</div>
          </div>
        </div>
        <CreateBranch
          isSidebarOpen={isSidebarOpen}
          closeSidebar={closeSidebar}
        />

        <Modal
          isOpen={isModalOpen}
          title={'Assign Validator'}
          onClose={closeModal}
        >
          <div className="mb-4">
            <label htmlFor="email" className="mb-3 block heading text-tmd">
              User Email
            </label>
            <input
              type="email"
              name="email"
              placeholder=""
              // disabled={isLoading}
              autoCorrect="none"
              autoComplete="off"
              // maxLength={11}
              className="w-full border-oslo border-[1px] p-3 rounded-md"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="mb-4">
            <label htmlFor="email" className="mb-3 block heading text-tmd">
              Branch
            </label>
            {/* {JSON.stringify(email)}
            {JSON.stringify(branch)} */}
            <select
              value={branch}
              onChange={handleBranchSelection}
              className="w-full border-oslo border-[1px] p-3 rounded-md"
            >
              <option value="">Select Address</option>
              {data &&
                data?.data?.data.map(({ branchAddress, id }: any) => (
                  <option key={branchAddress} value={id}>
                    {branchAddress}
                  </option>
                ))}
            </select>
          </div>

          <button
            type="submit"
            className={`p-3 text-white bg-tertiary rounded-md text-center w-full font-bold ${
              !valid && 'opacity-50'
            }`}
            disabled={!valid}
            onClick={() => {
              mutate({
                email,
                branch,
              });
            }}
          >
            {isLoading ? 'Submitting...' : 'Submit'}
          </button>
        </Modal>
      </div>
    </BusinessDashboardLayout>
  );
};

export default BusinessSettings;
