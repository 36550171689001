import React, { useState } from 'react';
import {
  useBuyInternationalAirtime,
  useGetCountryOperators,
  useGetInternationalAirtime,
} from '../../../../../../../hooks/api/app/InternationalAirtime/useGetInternationalAirtime';
import { useKeypad } from '../../../../../../../contexts/KeypadContext';
import KeyPad from '../../../../../../../components/KeyPad';

const InternationalAirtime = () => {
  const [payload, setPayload] = useState({
    serviceType: 'international-airtime',
    amount: '',
    productId: '',
    recipientAcctNumber: '',
    recipientAccountType: 'mobile_number',
    countryCode: '',
    transactionPin: '',
  });
  const { data } = useGetInternationalAirtime();

  const { data: providers, mutate } = useGetCountryOperators();
  const { mutateAsync: submitPayload } = useBuyInternationalAirtime();
  const { toggleKeypad, isKeypadOpen } = useKeypad();

  const handleSelectNetwork = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setPayload({ ...payload, countryCode: e.target.value });
    let exPayload = {
      countryCode: e.target.value,
    };
    mutate(exPayload);
  };

  const handleSubmit = () => {
    let updatedPayload = {
      ...payload,
      amount: +payload?.amount,
      productId: +payload?.productId,
    };

    submitPayload(updatedPayload);
  };

  return (
    <div>
      <div className="mb-4">
        <label htmlFor="countryCode" className="mb-1 block heading text-tmd">
          Select Country
        </label>
        <select
          name="countryCode"
          className="w-full border-oslo border-[1px] p-3 rounded-md"
          onChange={handleSelectNetwork}
        >
          <option value="">-- Select Option --</option>
          {data?.data?.map((datum: any) => (
            <option value={datum.code} key={datum.name}>
              {datum.name}
            </option>
          ))}
        </select>
      </div>
      {payload?.countryCode !== '' && (
        <div className="mb-4">
          <label htmlFor="country" className="mb-1 block heading text-tmd">
            Select Country Operator
          </label>
          <select
            name="countrry"
            id="network"
            className="w-full border-oslo border-[1px] p-3 rounded-md"
            onChange={(e) => {
              setPayload({ ...payload, productId: e?.target?.value });
            }}
          >
            <option value="">-- Select Option --</option>
            {providers?.data?.map((datum: any) => (
              <option value={datum.id} key={datum.name}>
                {datum.name}
              </option>
            ))}
          </select>
        </div>
      )}
      {payload.countryCode !== '' && payload.productId !== '' && (
        <div className="mb-4">
          <label
            htmlFor="recipientAcctNumber"
            className="mb-1 block heading text-tmd"
          >
            Recepient's Number
          </label>
          <input
            type="number"
            name="recipientAcctNumber"
            maxLength={11}
            autoCorrect="none"
            className="w-full border-oslo border-[1px] p-3 rounded-md"
            value={payload.recipientAcctNumber || ''}
            onChange={(e) => {
              setPayload({ ...payload, recipientAcctNumber: e.target.value });
            }}
          />
        </div>
      )}
      {payload.countryCode !== '' &&
        payload.productId !== '' &&
        payload.recipientAcctNumber !== '' && (
          <div className="mb-4">
            <label htmlFor="amount" className="mb-1 block heading text-tmd">
              Amount
            </label>
            <input
              type="number"
              name="amount"
              maxLength={11}
              autoCorrect="none"
              className="w-full border-oslo border-[1px] p-3 rounded-md"
              value={payload.amount || ''}
              onChange={(e) => {
                setPayload({ ...payload, amount: e.target.value });
              }}
            />
          </div>
        )}
      <button
        onClick={toggleKeypad}
        className="p-3 text-white bg-tertiary rounded-md text-center w-full font-bold"
        disabled={
          !payload.amount ||
          !payload.countryCode ||
          !payload.recipientAcctNumber
        }
      >
        Submit
      </button>
      <KeyPad
        isKeypadOpen={isKeypadOpen}
        toggleKeypad={toggleKeypad}
        payload={payload}
        setPayload={setPayload}
        handleSubmit={handleSubmit}
      />
    </div>
  );
};

export default InternationalAirtime;
