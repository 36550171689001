import React, { useEffect, useState } from 'react';
import Modal from './Modal';
import UserRankingModal from './Modals/UserRankingModal';
import { useGetUserDataQuery } from '../hooks/api/app/auth/useGetUserDataQuery';
import { useGetTotalTransactionsQuery } from '../hooks/api/app/wallet/WalletService/useGetTotalTransactionsQuery';
import { useLocation } from 'react-router-dom';
import UserImage from './UserImage';
import { transactions } from '../data';

type Props = { edit: boolean };

const UserRank = ({ edit }: Props) => {
  const img = require('../images/profile.png');
  let location = useLocation();

  const { data: totalTransactions } = useGetTotalTransactionsQuery();

  const image1 = require('../images/ranking/1.png');
  const image2 = require('../images/ranking/2.png');
  const image3 = require('../images/ranking/3.png');
  const image4 = require('../images/ranking/4.png');
  const image5 = require('../images/ranking/5.png');

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const { data } = useGetUserDataQuery();
  const [userRank, setUserRank] = useState('');
  const [userRankImage, setUserRankImage] = useState('');
  const [color, setColor] = useState('#000');

  useEffect(() => {
    if (totalTransactions?.data < 100000) {
      setUserRank('Explorer');
      setUserRankImage(image1);
      setColor('#86952E');
    } else if (
      totalTransactions?.data > 100000 &&
      totalTransactions?.data < 500000
    ) {
      setUserRank('Rising Star');
      setUserRankImage(image2);
      setColor('#7918F5');
    } else if (
      totalTransactions?.data > 500000 &&
      totalTransactions?.data < 1000000
    ) {
      setUserRank('Go Getter');
      setUserRankImage(image3);
      setColor('#580C07');
    } else if (
      totalTransactions?.data > 1000000 &&
      totalTransactions?.data < 10000000
    ) {
      setUserRank('Mr. Money');
      setUserRankImage(image4);
      setColor('#1B2BF5');
    } else if (totalTransactions?.data > 10000000) {
      setUserRank('Odogwu');
      setUserRankImage(image5);
      setColor('#ED8232');
    }
  }, [image1, image2, image3, image4, image5, totalTransactions?.data]);

  return (
    <div>
      <div></div>
      {/* <button className="bg-black text-white px-4 py-2 rounded absolute top-0">
        Open Modal
      </button> */}
      {/* <p className="text-center">{JSON.stringify(totalTransactions?.data)}</p> */}
      <UserImage
        img={img}
        color={color}
        userRankImage={userRankImage}
        edit={edit}
        openModal={openModal}
        data={data}
        userRank={userRank}
      />
      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        title="BillSpot Ranking"
        subtitle={'User ranks based on transactions'}
      >
        <UserRankingModal />
      </Modal>
    </div>
  );
};

export default UserRank;
