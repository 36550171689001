import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
// import { useGetUserDataQuery } from '../../../hooks/api/app/auth/useGetUserDataQuery';
import VerificationWrapper from '../../business/home/Verification/VerificationWrapper';
import SetUsername from './SetUsername';
import PhoneAndEmailVerification from './PhoneAndEmailVerification';
import SetTransactionPin from './SetTransactionPin';
import useGetUserDataQuery from '../../../hooks/api/app/profile/useReloadUser';
import Modal from '../../../components/Modal';
import { useModal } from '../../../contexts/ModalContext';
import ConfettiExplosion from 'react-confetti-explosion';

type Props = {};

const ProfileCompletion = (props: Props) => {
  const logo = require('../../../assets/logo.png');

  const user = JSON.parse(`${localStorage.getItem('BSUSER')}`);

  const { data, handleRefresh } = useGetUserDataQuery();
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.clear();
    navigate('/');
    // toast.success('Logout successfully');
  };

  let options = [
    'Set Username',
    'Verify Email & Phone Number',
    'Set Transaction PIN',
  ];

  const { isModalOpen, closeModal, openModal } = useModal();

  const handleNavigation = () => {
    setTimeout(() => {
      navigate('/app/home');
    }, 2000);
  };

  return (
    <div className="container mx-auto h-dvh">
      <div className="md:max-w-[1200px] mx-auto px-4">
        <div className="flex justify-between items-center py-10">
          <Link
            to="/"
            className="mr-[60px] text-[20px] font-bold text-tertiary flex gap-3 items-center heading"
          >
            <img src={logo} alt="" className="h-[40px]" /> BillSpot
          </Link>{' '}
          <div
            onClick={handleLogout}
            className="cursor-pointer p-3 flex items-center gap-2 font-medium"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m12.75 15 3-3m0 0-3-3m3 3h-7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
              />
            </svg>
            <span>Logout</span>
          </div>
        </div>
        <div className="flex md:flex-row flex-col gap-10 mt-[40px]">
          <div className="md:w-4/12 w-full">
            <div className="rounded-md border-[1px] border-[#c8c8c8] overflow-hidden">
              <div className="p-[20px] border-b-[1px] border-[#c8c8c8]">
                <h3 className="font-bold heading text-tlg text-black">
                  YOUR PROFILE - ({user?.progressCount}/3)
                </h3>
                <div className="mt-3 w-full rounded-[20px] overflow-hidden bg-[#dedede]">
                  <div
                    className="p-[5px] bg-yellow rounded-[20px]"
                    style={{
                      width: `${(user.progressCount / 3) * 100}%`,
                    }}
                  ></div>
                </div>
              </div>
              <div className="p-[20px] flex gap-[10px] overflow-x-scroll no-scrollbar whitespace-nowrap heading mb-4 text-[13px]">
                {options.map((item, index) => (
                  <div
                    key={index}
                    className={`p-3 rounded-[15px] border-[1px] flex gap-2 items-center ${
                      data?.data?.progressCount > index
                        ? 'bg-[#dedede] text-[#3d3d3d] border-[1px] opacity-50'
                        : 'text-[#3d3d3d] border-[#c8c8c8]'
                    }`}
                  >
                    {data?.data?.progressCount > index && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        className="size-4"
                      >
                        <path
                          fillRule="evenodd"
                          d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z"
                          clipRule="evenodd"
                        />
                      </svg>
                    )}
                    {index + 1}. {item}
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="md:w-8/12 w-full">
            {data?.data?.progressCount === 0 && <SetUsername />}
            {data?.data?.progressCount === 1 && <PhoneAndEmailVerification />}
            {data?.data?.progressCount === 2 && <SetTransactionPin />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileCompletion;
