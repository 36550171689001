import { useMutation, useQuery } from 'react-query';
import { userAxiosInstance } from '../../../../utils/userAxiosInstance';

// export const useGetDataPlanQuery = (firstPayload: any) => {
//   const token = localStorage.getItem('BSUserToken');

//   return useQuery({
//     queryKey: ['useGetDataPlanQuery', firstPayload?.serviceType],
//     queryFn: () =>
//       userAxiosInstance
//         .get(`/bills/data-${firstPayload?.serviceType}`, firstPayload)
//         .then((res) => res.data),
//     onSuccess: (data) => {
//       console.log('✅ useGetDataPlanQuery successful', data);
//     },
//     onError: (error) => {
//       console.error('❌ useGetDataPlanQuery failed', error);
//     },
//     enabled: !!token, // Only enable the query if the token exists
//   });
// };

export const useGetDataPlanQuery = () => {
  return useMutation(
    (data: string) =>
      userAxiosInstance
        .post(`/bills/data-${data}`, {
          serviceType: data,
        })
        .then((res: any) => res.data),
    {
      onSuccess: async (data: any) => {},
      onError: (error: any) => {},
    }
  );
};
