import React from 'react';
import AppLayout from '../../../components/AppLayout';

const AppEarnings = () => {
  return (
    <AppLayout>
      <h3 className="heading text-txl font-bold">Ambassadorship Earnings</h3>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Iusto officiis
        officia harum! Nam numquam eum impedit aliquam beatae itaque possimus et
        eligendi eius, quibusdam ab! Animi pariatur voluptate omnis velit?
      </p>
    </AppLayout>
  );
};

export default AppEarnings;
