import BusinessDashboardLayout from '../components/Layout/BusinessDashboardLayout';
import { Stats } from './Charts/Stats';
import { useGetBusinessData } from '../../../hooks/api/business/profile/useGetBusinessData';
import { useGetBusinessWalletBalance } from '../../../hooks/api/business/wallet/useGetBusinessWalletBalance';

const BusinessHome = () => {
  const { data } = useGetBusinessData();

  const { data: wallet } = useGetBusinessWalletBalance();

  return (
    <BusinessDashboardLayout>
      <div className="flex flex-col">
        <div className="heading text-txs font-medium mt-10">Overview</div>
        <p className="text-oslol mb-4">
          Monitor every activity of your company
        </p>

        <section className="p-4 rounded-md bg-water mb-4 md:flex md:gap-4 gap-10 flex-1">
          <div className="w-12/12 md:w-3/12 border-r-[1px]">
            <h3 className="text-oslol">Total Balance</h3>
            <p className="heading text-dsm">
              &#8358;{wallet?.data?.availableBalance.toLocaleString()}
            </p>
          </div>
          <div className="w-12/12 md:w-3/12 border-r-[1px]">
            <h3 className="text-oslol">Unclaimed Balance</h3>
            <p className="heading text-dsm">&#8358;124,456.62</p>
          </div>
          <div className="w-12/12 md:w-3/12 border-r-[1px]">
            <h3 className="text-oslol">Min. Gift Card Price</h3>
            <p className="heading text-dsm">
              &#8358;
              {data ? data?.data?.priceRanges?.min.toLocaleString() : '--'}
            </p>
          </div>
          <div className="w-12/12 md:w-3/12">
            <h3 className="text-oslol">Max. Gift Card Price</h3>
            <p className="heading text-dsm">
              &#8358;
              {data ? data?.data?.priceRanges?.max.toLocaleString() : '--'}
            </p>
          </div>
        </section>
        <div className="rounded-md border-[1px] border-[#c8c8c8] overflow-hidden flex-1">
          <div className="p-[20px] border-b-[1px] border-[#c8c8c8]">
            <h3 className="font-bold heading text-tlg text-black">
              Transaction Activities
            </h3>
          </div>
          {/* <div className="p-[20px] flex gap-[10px] overflow-x-scroll md:flex-wrap no-scrollbar whitespace-nowrap heading mb-4 text-[13px]"> */}
          <div className="p-[20px] text-[13px]">
            <div className="flex gap-4">
              <div className="md:w-8/12 w-12/12">
                <h3 className="font-bold heading text-tlg text-black">Sales</h3>

                <Stats />
              </div>
            </div>
          </div>
        </div>
      </div>
    </BusinessDashboardLayout>
  );
};

export default BusinessHome;
