import React, { useState } from 'react';
import { useGetDataProviderQuery } from '../../../../../../../hooks/api/app/Data/useGetDataProviderQuery';
import { useGetDataPlanQuery } from '../../../../../../../hooks/api/app/Data/useGetDataPlanQuery';
import PlanSelector from './PlanSelector';
import { useKeypad } from '../../../../../../../contexts/KeypadContext';
import KeyPad from '../../../../../../../components/KeyPad';
import { usePurchaseDataMutation } from '../../../../../../../hooks/api/app/Data/usePurchaseDataMutation';

type Props = {};

const Data = (props: Props) => {
  const [payload, setPayload] = useState({
    phone: '',
    serviceType: '',
    amount: '',
    dataCode: '',
  });

  const { data } = useGetDataProviderQuery();

  const { mutate } = usePurchaseDataMutation(setPayload);

  const { toggleKeypad, isKeypadOpen } = useKeypad();

  const { data: dataPlans, mutateAsync } = useGetDataPlanQuery();

  const handleSelectNetwork = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setPayload({ ...payload, serviceType: e.target.value });
    mutateAsync(e.target.value);
  };

  const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const phone = e.target.value.replace(/\D/g, '').slice(0, 11);
    setPayload({ ...payload, phone });
  };

  const handleSubmit = () => {
    let updatedPayload = { ...payload };

    if (updatedPayload.phone.startsWith('0')) {
      updatedPayload.phone = '+234' + updatedPayload.phone.slice(1);
    }
    mutate(updatedPayload);
  };

  return (
    <div>
      <div className="mb-4">
        <label htmlFor="network" className="mb-1 block heading text-tmd">
          Select Network
        </label>
        <select
          name="network"
          id="network"
          className="w-full border-oslo border-[1px] p-3 rounded-md"
          onChange={handleSelectNetwork}
        >
          <option value="">Select Option</option>
          {data?.data?.providers.map((datum: any) => (
            <option value={datum.service_type} key={datum.name}>
              {datum.name}
            </option>
          ))}
        </select>
      </div>
      {payload?.serviceType && (
        <PlanSelector
          plans={dataPlans?.data}
          setPayload={setPayload}
          payload={payload}
        />
      )}
      {payload?.dataCode !== '' && payload?.serviceType !== '' && (
        <div className="mb-4">
          <label htmlFor="phone" className="mb-1 block heading text-tmd">
            Phone Number
          </label>
          <input
            type="text"
            name="phone"
            maxLength={11}
            autoCorrect="none"
            className="w-full border-oslo border-[1px] p-3 rounded-md"
            value={payload.phone}
            onChange={handlePhoneNumberChange}
          />
        </div>
      )}
      <button
        onClick={toggleKeypad}
        className="p-3 text-white bg-tertiary rounded-md text-center w-full font-bold"
        disabled={
          !payload.amount ||
          !payload.dataCode ||
          !payload.phone ||
          !payload.serviceType
        }
      >
        Submit
      </button>
      <KeyPad
        isKeypadOpen={isKeypadOpen}
        toggleKeypad={toggleKeypad}
        payload={payload}
        setPayload={setPayload}
        handleSubmit={handleSubmit}
      />
    </div>
  );
};

export default Data;
