import { toast } from 'react-hot-toast';
import { useMutation } from 'react-query';
import { userAxiosInstance } from '../../../../utils/userAxiosInstance';

export const useUploadNinNewFunc = () => {
  return useMutation(
    async (imageData: string | Blob) => {
      if (!imageData) throw new Error('No file selected');

      const formData = new FormData();

      if (typeof imageData === 'string') {
        const blob = await (await fetch(imageData)).blob();
        formData.append('files', blob, 'image.png');
      } else {
        formData.append('files', imageData);
      }

      const response = await userAxiosInstance.post(
        'misc/file-uploads',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      return response.data;
    },
    {
      onSuccess: () => {
        console.log('✅ useUploadNin');
        // toast.success('uploaded Successfully');
      },
      onError: (error: any) => {
        console.log('❌ useUploadNin');
        toast.error(
          error?.response?.data?.message || 'Error, please try again'
        );
      },
    }
  );
};
