import React, { ChangeEvent, useEffect, useState } from 'react';
import { useGetWalletBalanceQuery } from '../../../../hooks/api/app/wallet/WalletService/useGetWalletBalanceQuery';
import { useModal } from '../../../../contexts/ModalContext';
import { useGetUserDataQuery } from '../../../../hooks/api/app/auth/useGetUserDataQuery';
import toast from 'react-hot-toast';
import FundWalletModal from '../../../../components/Modals/FundWalletModal';
import Sidebar from '../../../../components/SideBar';
import { useTransferMoney } from '../../../../hooks/api/app/wallet/Transfer';
import KeyPad from '../../../../components/KeyPad';
import { useKeypad } from '../../../../contexts/KeypadContext';
import { useSearchUserQuery } from '../../../../hooks/api/app/Transfer/useSearchUserQuery';
import { FiArrowDownLeft, FiArrowUpRight } from 'react-icons/fi';
import { useGetBanks } from '../../../../hooks/api/business/Onboarding/useGetBanks';

type Props = {};

const BalancePreviewCard: React.FC<Props> = () => {
  const [id, setId] = useState('');
  const [method, setMethod] = useState('username');
  const [bankCode, setBankCode] = useState('');
  const [level, setLevel] = useState(0);
  const [searchTriggered, setSearchTriggered] = useState(false);
  const { data: walletBalance } = useGetWalletBalanceQuery();
  const { data: result } = useSearchUserQuery(
    id,
    searchTriggered,
    setSearchTriggered
  );

  const { data } = useGetUserDataQuery();
  const { data: banks } = useGetBanks();

  const { isModalOpen, closeModal, openModal } = useModal();
  const { toggleKeypad, isKeypadOpen } = useKeypad();
  const img = require('../../../../images/profile.png');

  const handleSearch = () => {
    if (id) {
      setSearchTriggered(true); // Trigger the search
    }
  };

  const [payload, setPayload] = useState({
    amount: '',
    remarks: '',
  });

  const [isVisible, setIsVisible] = useState(true);
  const [openStatus, setOpenStatus] = useState(false);

  const sidebarOpener = () => {
    setOpenStatus(true);
  };

  const sidebarCloser = () => {
    setLevel(0);
    setMethod('username');
    setPayload({
      amount: '',
      remarks: '',
    });
    setId('');
    setSearchTriggered(false);
    setOpenStatus(false);
  };

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;

    setPayload((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(() => {
    const storedVisibility = localStorage.getItem('walletVisibility');
    setIsVisible(storedVisibility === 'true');
  }, []);

  const toggleVisibility = () => {
    const newVisibility = !isVisible;
    setIsVisible(newVisibility);
    localStorage.setItem('walletVisibility', newVisibility.toString());
  };

  function copyToClipboard(text: string | undefined) {
    if (!text) return;

    navigator.clipboard
      .writeText(text)
      .then(() => {
        toast.success('Copied Account Number');
      })
      .catch(() => {
        toast.error('Failed to copy Successfully');
      });
  }

  const { mutateAsync } = useTransferMoney(result?.data?.id, sidebarCloser);

  const handleTransferMoney = () => {
    const updatedPayload = { ...payload, amount: +payload.amount };
    mutateAsync(updatedPayload);
  };

  const handleNumberChange = (e: any) => {
    const value = e.target.value;

    // Check if the value is a valid number (no letters or special characters)
    if (/^\d*$/.test(value)) {
      setPayload({ ...payload, amount: value });
    }
  };

  return (
    <div className="p-4 rounded-md mt-4 bg-tertiary">
      <h3 className="heading text-tmd text-white text-center mt-1">
        Wallet Balance
      </h3>
      <div className="text-center text-white md:text-txs text-txs heading font-bold flex justify-center items-center gap-3">
        &#8358;
        {isVisible
          ? walletBalance
            ? walletBalance.availableBalance.toLocaleString()
            : '0.00'
          : '******'}
        <span className="cursor-pointer" onClick={toggleVisibility}>
          {isVisible ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
              />
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88"
              />
            </svg>
          )}
        </span>
      </div>
      {data?.data?.dedicatedAccountName && (
        <div
          className="bg-[#00000081] p-2 rounded-md max-w-[200px] mx-auto cursor-pointer"
          onClick={() => copyToClipboard(data?.data?.dedicatedAccountNumber)}
        >
          <p className="text-center text-white text-[12px] foont-bold">
            {data?.data?.dedicatedAccountName}
          </p>
          <div className="text-center text-white text-[12px] flex gap-3 items-center justify-center">
            {data?.data?.dedicatedAccountNumber}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-3"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 0 1-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 0 1 1.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 0 0-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 0 1-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 0 0-3.375-3.375h-1.5a1.125 1.125 0 0 1-1.125-1.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H9.75"
              />
            </svg>
          </div>
        </div>
      )}

      {/* <div className="flex justify-center gap-4 my-5 text-center font-bold text-white heading">
        <button
          onClick={sidebarOpener}
          className="w-[120px] p-2 bg-green rounded-md flex gap-2 items-center justify-center"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className="size-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M4.5 10.5 12 3m0 0 7.5 7.5M12 3v18"
            />
          </svg>
          Send
        </button>
        <button
          className="w-[120px] p-2 bg-green rounded-md flex gap-2 items-center justify-center"
          onClick={openModal}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className="size-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M19.5 13.5 12 21m0 0-7.5-7.5M12 21V3"
            />
          </svg>
          Receive
        </button>
      </div> */}
      <div className="flex justify-center space-x-8 items-center mt-2">
        <div className="flex flex-col items-center" onClick={sidebarOpener}>
          <button className="flex justify-center items-center bg-green text-white w-16 h-16 rounded-[8px]">
            <FiArrowUpRight className="text-[20px]" />
          </button>
          <span className="mt-2 text-sm font-bold text-white">Send Money</span>
        </div>

        <div className="flex flex-col items-center">
          <button className="flex justify-center items-center bg-green text-white w-16 h-16 rounded-[8px]">
            <FiArrowDownLeft className="text-[20px]" />
          </button>
          <span className="mt-2 text-sm font-bold text-white">Fund Wallet</span>
        </div>
      </div>

      <Sidebar title="Send Money" isOpen={openStatus} onClose={sidebarCloser}>
        <p className="heading">Select Destination</p>
        <div className="flex bg-offline p-2 rounded-md mt-3">
          <button
            onClick={() => setMethod('username')}
            className={`w-6/12 p-3 text-center cursor-pointer text-[14px] rounded-md heading ${
              method === 'username' ? 'bg-secondary text-white' : 'text-black'
            }`}
          >
            BillSpot user
          </button>
          <button
            onClick={() => setMethod('bank')}
            className={`w-6/12 p-3 text-center cursor-pointer text-black text-[14px] rounded-md heading ${
              method === 'bank' ? 'bg-secondary text-white' : 'text-black'
            }`}
          >
            External Banks
          </button>
        </div>
        <div>
          {level === 0 && method === 'bank' && (
            <div className="mb-4 mt-4">
              <label htmlFor="bank" className="mb-3 block heading text-tmd">
                Select Bank
              </label>
              <select
                value={bankCode}
                onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                  setBankCode(e.target.value)
                }
                className="w-full border-oslo border-[1px] p-3 rounded-md"
              >
                <option value="" disabled>
                  Select a bank
                </option>
                {banks &&
                  banks?.data.map(
                    ({
                      id,
                      name,
                      code,
                    }: {
                      id: string;
                      name: string;
                      code: string;
                    }) => (
                      <option key={id} value={code}>
                        {name}
                      </option>
                    )
                  )}
              </select>
              <label htmlFor="id" className="mb-3 block heading text-tmd mt-3">
                Amount
              </label>
              <input
                type="number"
                name="id"
                placeholder="Search username"
                autoComplete="off"
                autoCorrect="off"
                className="w-full border-oslo border-[1px] p-3 rounded-md"
                value={id}
                onChange={(e) => {
                  setId(e.target.value);
                  setSearchTriggered(false);
                }}
              />
              <label htmlFor="id" className="mb-3 block heading text-tmd mt-3">
                Description
              </label>
              <textarea
                placeholder=""
                autoComplete="off"
                autoCorrect="off"
                className="h-[150px] resize-none w-full border-oslo border-[1px] p-3 rounded-md"
                value={id}
                onChange={(e) => {
                  setId(e.target.value);
                  setSearchTriggered(false);
                }}
              />

              <button
                // onClick={handleSearch}
                disabled={!id}
                className="mt-3 p-3 text-white bg-tertiary rounded-md text-center w-full font-bold"
              >
                Send
              </button>
            </div>
          )}
          {level === 0 && method === 'username' && (
            <div className="mb-4 mt-4">
              <label htmlFor="id" className="mb-3 block heading text-tmd">
                Recepient
              </label>
              <input
                type="text"
                name="id"
                placeholder="Search username"
                autoComplete="off"
                autoCorrect="off"
                autoFocus={true}
                className="w-full border-oslo border-[1px] p-3 rounded-md"
                value={id}
                onChange={(e) => {
                  setId(e.target.value);
                  setSearchTriggered(false);
                }}
              />

              <button
                onClick={handleSearch}
                disabled={!id}
                className="mt-3 p-3 text-white bg-tertiary rounded-md text-center w-full font-bold"
              >
                Find user
              </button>

              <div className="mt-[10px]">
                {result && result?.data && (
                  <div
                    key={result?.data?.id}
                    className="flex gap-3 cursor-pointer bg-water p-[10px] rounded-md"
                    onClick={() => {
                      setLevel(1);
                    }}
                  >
                    <img
                      src={
                        result?.data?.profilePicture === ''
                          ? img
                          : result?.data?.profilePicture
                      }
                      alt=""
                      className="h-[50px] w-[50px] rounded-full"
                    />
                    <div>
                      <h3 className="font-bold text-black">
                        {result?.data?.firstName + ' ' + result?.data?.lastName}
                      </h3>
                      <p className="text-oslol">@{result?.data?.username}</p>
                    </div>
                  </div>
                )}
                {result?.data === 'null' && (
                  <p className="text-red-300">No userr with this username</p>
                )}
              </div>
            </div>
          )}
          {level === 1 && (
            <div>
              <span
                className="flex gap-2 items-center cursor-pointer mb-3"
                onClick={() => {
                  setLevel(0);
                  setPayload({ amount: '', remarks: '' });
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="size-10 p-3 rounded-full text-black bg-offline"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9 15 3 9m0 0 6-6M3 9h12a6 6 0 0 1 0 12h-3"
                  />
                </svg>
                <span>Back</span>
              </span>

              <p className="mb-4 font-bold text-tertiary">
                Sending to @{result && result.data.username}
              </p>

              <div className="mb-4">
                <label htmlFor="amount" className="mb-3 block heading text-tmd">
                  Amount
                </label>
                <input
                  type="number"
                  name="amount"
                  autoCorrect="none"
                  className="w-full border-oslo border-[1px] p-3 rounded-md"
                  value={payload.amount}
                  onChange={handleNumberChange}
                />
              </div>

              <div className="mb-4">
                <label
                  htmlFor="remarks"
                  className="mb-3 block heading text-tmd"
                >
                  Remark
                </label>
                <textarea
                  name="remarks"
                  placeholder="Leave a note"
                  autoComplete="off"
                  value={payload.remarks}
                  maxLength={40}
                  onChange={handleChange}
                  className="h-[100px] mb-3 resize-none w-full border-oslo border-[1px] p-3 rounded-md"
                />
              </div>

              <button
                onClick={toggleKeypad}
                disabled={!payload.amount || !payload.remarks}
                className={`p-3 text-white rounded-md text-center w-full font-bold
                ${
                  !payload.amount || !payload.remarks
                    ? 'bg-tertiary opacity-50'
                    : 'bg-tertiary opacity-100'
                }`}
              >
                Submit
              </button>
            </div>
          )}
          <KeyPad
            isKeypadOpen={isKeypadOpen}
            toggleKeypad={toggleKeypad}
            payload={payload}
            setPayload={setPayload}
            handleSubmit={handleTransferMoney}
          />
        </div>
      </Sidebar>
      <FundWalletModal
        isModalOpen={isModalOpen}
        closeModal={closeModal}
        data={data}
      />
    </div>
  );
};

export default BalancePreviewCard;
