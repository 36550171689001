import React, { useState } from 'react';
import { useSidebar } from '../../../../../contexts/SidebarContext';
import { Link } from 'react-router-dom';
import Sidebar from '../../../../../components/SideBar';
import Airtime from './SideBarOptions/Airtime';
import Data from './SideBarOptions/Data';
import Cable from './SideBarOptions/Cable';
import ElectricityBills from './SideBarOptions/ElectricityBills';
import LocalGiftCards from './SideBarOptions/LocalGiftCards';
import WesFee from './SideBarOptions/WesFee';
import SevisFee from './SideBarOptions/SevisFee/index';
import ScratchCards from './SideBarOptions/ScratchCards';
import InternationalAirtime from './SideBarOptions/InternationalAirtime';

type ComponentMap = {
  [key: string]: React.ComponentType<any>;
};

const EducationalBills = () => {
  const [active, setActive] = useState({ title: '', subtitle: '' });
  const { isSidebarOpen, closeSidebar, openSidebar } = useSidebar();

  const componentsMap: ComponentMap = {
    // Airtime: Airtime,
    // Data: Data,
    // Cable: Cable,
    // 'Electricity Bills': ElectricityBills,
    // 'Local Gift Cards': LocalGiftCards,
    'SEVIS Fee': SevisFee,
    'WES Fee': WesFee,
    'Scratch Cards': ScratchCards,
    // 'International Airtime': InternationalAirtime,
  };

  const ActiveComponent = componentsMap[active?.title] || null;

  return (
    <>
      <div className="mt-3 grid grid-cols-3 gap-3">
        {/* <span
          onClick={() => {
            openSidebar();
            setActive({
              title: 'Airtime',
              subtitle: 'Topup airtime with ease',
            });
          }}
          className="ease-in-out duration-300 hover:scale-90 cursor-pointer"
        >
          <div className="rounded-md bg-water mb-2 py-6">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="2"
              stroke="currentColor"
              className="size-6 mx-auto"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z"
              />
            </svg>
          </div>
          <div className="md:text-medium text-tsm text-center">Airtime</div>
        </span> */}
        {/* <span
          className="ease-in-out duration-300 hover:scale-90 cursor-pointer"
          onClick={() => {
            openSidebar();
            setActive({
              title: 'Data',
              subtitle: 'Topup data with ease',
            });
          }}
        >
          <div className="rounded-md bg-water mb-2 py-6">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="2"
              stroke="currentColor"
              className="size-6 mx-auto"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M7.5 21 3 16.5m0 0L7.5 12M3 16.5h13.5m0-13.5L21 7.5m0 0L16.5 12M21 7.5H7.5"
              />
            </svg>
          </div>
          <div className="md:text-medium text-tsm text-center">Data</div>
        </span> */}

        {/* <span
          className="ease-in-out duration-300 hover:scale-90 cursor-pointer"
          onClick={() => {
            openSidebar();
            setActive({
              title: 'Cable',
              subtitle: 'Renew cable subscription with ease',
            });
          }}
        >
          <div className="rounded-md bg-water mb-2 py-6">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="2"
              stroke="currentColor"
              className="size-6 mx-auto"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 20.25h12m-7.5-3v3m3-3v3m-10.125-3h17.25c.621 0 1.125-.504 1.125-1.125V4.875c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125Z"
              />
            </svg>
          </div>
          <div className="md:text-medium text-tsm text-center">Cable TV</div>
        </span> */}
        {/* <span
          className="ease-in-out duration-300 hover:scale-90 cursor-pointer"
          onClick={() => {
            openSidebar();
            setActive({
              title: 'Electricity Bills',
              subtitle: 'Top-up electricity units with ease',
            });
          }}
        >
          <div className="rounded-md bg-water mb-2 py-6">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="2"
              stroke="currentColor"
              className="size-6 mx-auto"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m3.75 13.5 10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75Z"
              />
            </svg>
          </div>
          <div className="md:text-medium text-tsm text-center">Electricity</div>
        </span> */}
        {/* <Link
          to="/app/marketplace"
          className="ease-in-out duration-300 hover:scale-90 cursor-pointer"
        >
          <div className="rounded-md bg-water mb-2 py-6">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="2"
              stroke="currentColor"
              className="size-6 mx-auto"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M21 11.25v8.25a1.5 1.5 0 0 1-1.5 1.5H5.25a1.5 1.5 0 0 1-1.5-1.5v-8.25M12 4.875A2.625 2.625 0 1 0 9.375 7.5H12m0-2.625V7.5m0-2.625A2.625 2.625 0 1 1 14.625 7.5H12m0 0V21m-8.625-9.75h18c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125h-18c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125Z"
              />
            </svg>
          </div>
          <div className="md:text-medium text-tsm text-center">
            Local Gift Cards
          </div>
        </Link> */}
        {/* <Link
          to="/app/cards"
          className="ease-in-out duration-300 hover:scale-90 cursor-pointer"
        >
          <div className="rounded-md bg-water mb-2 py-6">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="2"
              stroke="currentColor"
              className="size-6 mx-auto"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 0 0 2.25-2.25V6.75A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25v10.5A2.25 2.25 0 0 0 4.5 19.5Z"
              />
            </svg>
          </div>
          <div className="md:text-medium text-tsm text-center">
            Virtual Cards
          </div>
        </Link> */}
        <span
          className="ease-in-out duration-300 hover:scale-90 cursor-pointer"
          onClick={() => {
            openSidebar();
            setActive({
              title: 'SEVIS Fee',
              subtitle: 'Pay SEVIS FEE with ease',
            });
          }}
        >
          <div className="rounded-md bg-water mb-2 py-6">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="2"
              stroke="currentColor"
              className="size-6 mx-auto"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 21a9.004 9.004 0 0 0 8.716-6.747M12 21a9.004 9.004 0 0 1-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 0 1 7.843 4.582M12 3a8.997 8.997 0 0 0-7.843 4.582m15.686 0A11.953 11.953 0 0 1 12 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0 1 21 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0 1 12 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 0 1 3 12c0-1.605.42-3.113 1.157-4.418"
              />
            </svg>
          </div>
          <div className="md:text-medium text-tsm text-center">SEVIS</div>
        </span>
        <span
          className="ease-in-out duration-300 hover:scale-90 cursor-pointer"
          onClick={() => {
            openSidebar();
            setActive({
              title: 'WES Fee',
              subtitle: 'Pay WES FEE with ease',
            });
          }}
        >
          <div className="rounded-md bg-water mb-2 py-6">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="2"
              stroke="currentColor"
              className="size-6 mx-auto"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9 6.75V15m6-6v8.25m.503 3.498 4.875-2.437c.381-.19.622-.58.622-1.006V4.82c0-.836-.88-1.38-1.628-1.006l-3.869 1.934c-.317.159-.69.159-1.006 0L9.503 3.252a1.125 1.125 0 0 0-1.006 0L3.622 5.689C3.24 5.88 3 6.27 3 6.695V19.18c0 .836.88 1.38 1.628 1.006l3.869-1.934c.317-.159.69-.159 1.006 0l4.994 2.497c.317.158.69.158 1.006 0Z"
              />
            </svg>
          </div>
          <div className="md:text-medium text-tsm text-center">WES</div>
        </span>
        <span
          className="ease-in-out duration-300 hover:scale-90 cursor-pointer"
          onClick={() => {
            openSidebar();
            setActive({
              title: 'Scratch Cards',
              subtitle: 'Description goes here',
            });
          }}
        >
          <div className="rounded-md bg-water mb-2 py-6">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2}
              stroke="currentColor"
              className="size-6 mx-auto"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15 9h3.75M15 12h3.75M15 15h3.75M4.5 19.5h15a2.25 2.25 0 0 0 2.25-2.25V6.75A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25v10.5A2.25 2.25 0 0 0 4.5 19.5Zm6-10.125a1.875 1.875 0 1 1-3.75 0 1.875 1.875 0 0 1 3.75 0Zm1.294 6.336a6.721 6.721 0 0 1-3.17.789 6.721 6.721 0 0 1-3.168-.789 3.376 3.376 0 0 1 6.338 0Z"
              />
            </svg>
          </div>
          <div className="md:text-medium text-tsm text-center">
            Scratch Cards
          </div>
        </span>
        {/* <span
          className="ease-in-out duration-300 hover:scale-90 cursor-pointer"
          onClick={() => {
            openSidebar();
            setActive({
              title: 'International Airtime',
              subtitle: 'Description goes here',
            });
          }}
        >
          <div className="rounded-md bg-water mb-2 py-6">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2}
              stroke="currentColor"
              className="size-6 mx-auto"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M20.25 3.75v4.5m0-4.5h-4.5m4.5 0-6 6m3 12c-8.284 0-15-6.716-15-15V4.5A2.25 2.25 0 0 1 4.5 2.25h1.372c.516 0 .966.351 1.091.852l1.106 4.423c.11.44-.054.902-.417 1.173l-1.293.97a1.062 1.062 0 0 0-.38 1.21 12.035 12.035 0 0 0 7.143 7.143c.441.162.928-.004 1.21-.38l.97-1.293a1.125 1.125 0 0 1 1.173-.417l4.423 1.106c.5.125.852.575.852 1.091V19.5a2.25 2.25 0 0 1-2.25 2.25h-2.25Z"
              />
            </svg>
          </div>
          <div className="md:text-medium text-tsm text-center">
            International Airtime
          </div>
        </span> */}
      </div>
      <Sidebar
        title={active?.title}
        subtitle={active?.subtitle}
        isOpen={isSidebarOpen}
        onClose={closeSidebar}
      >
        {ActiveComponent && <ActiveComponent />}
      </Sidebar>
    </>
  );
};

export default EducationalBills;
