import React, { useState } from 'react';
import { useKeypad } from '../../../../../../../contexts/KeypadContext';
import KeyPad from '../../../../../../../components/KeyPad';
import { useGetAirtimeProvidersQuery } from '../../../../../../../hooks/api/app/Airtiime/useGetAirtimeProvidersQuery';
import { usePurchaseAirtimeMutation } from '../../../../../../../hooks/api/app/Airtiime/usePurchaseAirtimeMutation';

const Airtime = () => {
  const [payload, setPayload] = useState({
    serviceType: '',
    amount: '',
    phone: '',
    transactionPin: '',
  });

  const { data } = useGetAirtimeProvidersQuery();

  const { mutate } = usePurchaseAirtimeMutation(setPayload);

  const { toggleKeypad, isKeypadOpen } = useKeypad();

  const handleSelectNetwork = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setPayload({ ...payload, serviceType: e.target.value });
  };

  const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const phone = e.target.value.replace(/\D/g, '').slice(0, 11);
    setPayload({ ...payload, phone });
  };

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const amount = e.target.value.replace(/\D/g, '');
    if (amount !== '0') {
      setPayload({ ...payload, amount });
    } else {
      setPayload({ ...payload, amount: '' });
    }
  };

  const handleSubmit = () => {
    let updatedPayload = { ...payload, amount: +payload?.amount };

    if (updatedPayload.phone.startsWith('0')) {
      updatedPayload.phone = '+234' + updatedPayload.phone.slice(1);
    }
    mutate(updatedPayload);
  };

  return (
    <div>
      <div className="mb-4">
        <label htmlFor="network" className="mb-1 block heading text-tmd">
          Select Network
        </label>
        <select
          name="network"
          id="network"
          className="w-full border-oslo border-[1px] p-3 rounded-md"
          onChange={handleSelectNetwork}
        >
          <option value="">Select Option</option>
          {data?.data?.providers.map((datum: any) => (
            <option value={datum.service_type} key={datum.name}>
              {datum.name}
            </option>
          ))}
        </select>
      </div>
      {payload?.serviceType !== '' && (
        <div className="mb-4">
          <label htmlFor="phone" className="mb-1 block heading text-tmd">
            Phone Number
          </label>
          <input
            type="text"
            name="phone"
            maxLength={11}
            autoCorrect="none"
            autoComplete="off"
            className="w-full border-oslo border-[1px] p-3 rounded-md"
            value={payload.phone}
            onChange={handlePhoneNumberChange}
          />
        </div>
      )}
      {payload?.phone !== '' && payload?.phone.length === 11 && (
        <div className="mb-4">
          <label htmlFor="amount" className="mb-1 block heading text-tmd">
            Amount
          </label>
          <input
            type="text"
            name="amount"
            autoCorrect="none"
            autoComplete="off"
            className="w-full border-oslo border-[1px] p-3 rounded-md"
            value={payload.amount}
            onChange={handleAmountChange}
          />
        </div>
      )}
      <button
        onClick={toggleKeypad}
        className="p-3 text-white bg-tertiary rounded-md text-center w-full font-bold"
        disabled={!payload.amount || !payload.phone || !payload.serviceType}
      >
        Submit
      </button>
      <KeyPad
        isKeypadOpen={isKeypadOpen}
        toggleKeypad={toggleKeypad}
        payload={payload}
        setPayload={setPayload}
        handleSubmit={handleSubmit}
      />
    </div>
  );
};

export default Airtime;
