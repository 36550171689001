import React, { useState } from 'react';
import VerificationWrapper from '../../business/home/Verification/VerificationWrapper';
import { useCreateUsername } from '../../../hooks/api/app/profile/useCreateUsername';

type Props = {};

const SetUsername = (props: Props) => {
  const [username, setUsername] = useState('');

  const { mutate, isLoading } = useCreateUsername(username);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    mutate();
  };

  return (
    <VerificationWrapper title="Set Username" icon={undefined}>
      <form onSubmit={handleSubmit} className="mt-10">
        <div className="mb-4">
          <label
            htmlFor="username"
            className="mb-3 heading text-tmd flex items-center justify-between"
          >
            Username
            <span>{username.length}/12</span>
          </label>
          <input
            type="text"
            name="username"
            autoFocus
            placeholder=""
            maxLength={12}
            autoComplete="off"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            className="w-full border-oslo border-[1px] p-3 rounded-md"
          />
        </div>
        <button
          type="submit"
          className={`bg-tertiary p-3 rounded-md text-center text-white font-medium cursor-pointer ${
            username === '' ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'
          } `}
          disabled={isLoading === true && username === ''}
        >
          Submit
        </button>
      </form>
    </VerificationWrapper>
  );
};
export default SetUsername;
