import { useMutation } from 'react-query';
import toast from 'react-hot-toast';
import { apiConfig } from '../../../../constants/apiConfig';
import { userAxiosInstance } from '../../../../utils/userAxiosInstance';
import { useNavigate } from 'react-router-dom';

interface Props {
  email: string;
  password: string;
  phone: string;
  firstName: string;
  lastName: string;
}

export const useRegisterUser = () => {
  const navigate = useNavigate();

  return useMutation(
    (payload: Props) =>
      userAxiosInstance
        .post(`${apiConfig.REGISTER_USER}`, payload)
        .then((res: any) => res.data),
    {
      onSuccess: async (data: any) => {
        navigate('/app/login');
        toast('Registration successful, Proceed to login');
      },
      onError: (error: any) => {
        // console.log('Registration failed', error);
        toast(error?.response?.data?.message);
      },
    }
  );
};
