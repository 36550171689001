import React from 'react';
import Lottie from 'lottie-react';
import File from '../assets/Empty.json';

type Props = { text: string };

const Empty = ({ text }: Props) => {
  return (
    <div className="flex mx-auto item-center justify-center h-[400px]">
      <div className="text-center">
        <Lottie animationData={File} loop={false} />
        <p className="text-center font-medium max-w-[350px] text-oslol">
          {text}
        </p>
      </div>
    </div>
  );
};

export default Empty;
