import { useState } from 'react';
import toast from 'react-hot-toast';
import { useUploadProfileImage } from '../hooks/api/app/profile/useUploadProfileImage';
import { useDeleteProfileImage } from '../hooks/api/app/profile/useDeleteProfileImage';

const UserImage = ({
  img,
  userRankImage,
  edit,
  openModal,
  data,
  userRank,
  color,
}) => {
  const [selectedImage, setSelectedImage] = useState('');

  const { mutate } = useUploadProfileImage();
  const { mutate: deletePicture } = useDeleteProfileImage();

  const handleFileChange = async (event) => {
    const file = event.target.files?.[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = async () => {
        const imageBase64 = reader.result;
        setSelectedImage(imageBase64);

        try {
          if (typeof imageBase64 === 'string') {
            await mutate(imageBase64);
          }
        } catch (error) {
          console.error('Image upload failed:', error);
          toast.error('Error uploading file');
        }
      };

      reader.readAsDataURL(file);
    }
  };

  const handleDeletePicture = () => {
    deletePicture();
  };

  return (
    <div>
      <div className="relative mx-auto w-[100px]">
        <img
          src={
            data?.data?.profilePicture && data?.data?.profilePicture !== ''
              ? data.data.profilePicture
              : img
          }
          alt="user"
          className="w-[80px] h-[80px] mx-auto z-40 rounded-full"
        />
        <img
          src={userRankImage}
          alt="rank"
          className="absolute -top-[30px] left-[58%] h-[50px] rotate-[40deg] -z-30"
        />
      </div>
      <div onClick={openModal} className="cursor-pointer">
        <p className="heading text-center mt-1">
          {data && data?.data?.userName && `@${data.data.userName}`}
        </p>
        {/* <div className="text-center text-[#4caf50] font-bold">{userRank}</div>*/}
        <span className="flex mx-auto justify-center mb-10">
          <span
            className="p-2 text-[12px] text-white rounded-md"
            style={{ backgroundColor: color }}
          >
            {userRank}
          </span>
        </span>
      </div>
      {edit && (
        <div onClick={() => {}}>
          <div className="cursor-pointer">
            <label className="inline-block px-4 rounded-full py-2 mb-4 cursor-pointer">
              <span className="flex gap-3 cursor-pointer items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-10 bg-green p-2 text-white rounded-md"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m2.25 15.75 5.159-5.159a2.25 2.25 0 0 1 3.182 0l5.159 5.159m-1.5-1.5 1.409-1.409a2.25 2.25 0 0 1 3.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 0 0 1.5-1.5V6a1.5 1.5 0 0 0-1.5-1.5H3.75A1.5 1.5 0 0 0 2.25 6v12a1.5 1.5 0 0 0 1.5 1.5Zm10.5-11.25h.008v.008h-.008V8.25Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"
                  />
                </svg>
                <span className="text-green">Upload</span>
              </span>
              <input
                name="profileImage"
                type="file"
                accept="image/*"
                multiple={false}
                onChange={handleFileChange}
                className="mt-2 hidden"
              />
            </label>
          </div>
          {data?.data?.profilePicture !== '' && (
            <span
              className="flex gap-3 items-center cursor-pointer md:px-0 px-4"
              onClick={() => handleDeletePicture()}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-10 bg-red-400 p-2 text-white rounded-md"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                />
              </svg>
              <span className="text-red-400 ">Delete</span>
            </span>
            // <span className="cursor-pointer bg-red-400 rounded-full text-white py-2 px-4">
            //   <svg
            //     xmlns="http://www.w3.org/2000/svg"
            //     fill="none"
            //     viewBox="0 0 24 24"
            //     strokeWidth={1.5}
            //     stroke="currentColor"
            //     className="size-6"
            //     onClick={() => handleDeletePicture()}
            //   >
            //     <path
            //       strokeLinecap="round"
            //       strokeLinejoin="round"
            //       d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
            //     />
            //   </svg>
            // </span>
          )}
        </div>
      )}
    </div>
  );
};

export default UserImage;
