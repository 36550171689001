import React, { useState } from 'react';
import { useGetValidators } from '../../../../hooks/api/business/validators/useGetValidators';
import { useDeleteValidator } from '../../../../hooks/api/business/validators/useDeleteValidator';
import Loader from '../../../../components/Loader';
import Empty from '../../../../components/Empty';

const Validator = () => {
  const { data, isLoading } = useGetValidators();
  const [activated, setActivated] = useState('');

  const { mutate } = useDeleteValidator();

  const handleDelete = () => {
    mutate({ activated });
  };

  return (
    <div>
      {isLoading && <Loader />}

      {data && data?.data?.data.length === 0 && <Empty text="No validator" />}

      {data && data?.data?.data.length > 0 && (
        <div>
          {/* Header row */}
          <div className="flex gap-2 py-3 border-b-[1px] border-ash font-bold bg-[#3d3d3d] text-white">
            <div className="w-3/12 truncate">FirstName</div>
            <div className="w-3/12 truncate">LastName</div>
            <div className="w-2/12 truncate">Username</div>
            <div className="w-2/12 truncate">Branch</div>
            <div className="w-2/12 truncate">Action</div>
          </div>

          {/* Validator data rows */}
          {data?.data?.data.map(
            ({
              id,
              firstName,
              lastName,
              username,
            }: {
              id: string;
              firstName: string;
              lastName: string;
              username: string;
            }) => (
              <div
                key={id}
                className="flex gap-2 py-3 border-b-[1px] border-ash"
              >
                <div className="w-3/12 truncate">{firstName}</div>
                <div className="w-3/12 truncate">{lastName}</div>
                <div className="w-2/12 truncate">{username}</div>
                <div className="w-2/12 truncate">branch</div>
                <div>
                  {activated === id ? (
                    <span
                      className="size-6 text-red-400 font-bold cursor-pointer"
                      onClick={handleDelete}
                    >
                      Delete
                    </span>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="size-6 cursor-pointer"
                      onClick={() => setActivated(id)}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                      />
                    </svg>
                  )}
                </div>
              </div>
            )
          )}
        </div>
      )}
    </div>
  );
};

export default Validator;
