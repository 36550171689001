import React, { ChangeEvent, useState, useEffect } from 'react';
import Sidebar from '../../../../components/SideBar';
import { useCreateBranch } from '../../../../hooks/api/business/branches/useCreateBranch';

type Props = { isSidebarOpen: any; closeSidebar: any };

const CreateBranch = ({ isSidebarOpen, closeSidebar }: Props) => {
  const [payload, setPayload] = useState({
    branchAddress: '',
    city: '',
    state: '',
    phone: '',
  });

  const [lgas, setLgas] = useState<string[]>([]);
  const [selectedState, setSelectedState] = useState('');
  const [selectedLGA, setSelectedLGA] = useState<string>('');
  const [states, setStates] = useState<string[]>([]); // Added states

  const [valid, setValid] = useState(false);

  const { mutate } = useCreateBranch(setPayload, setSelectedLGA, setStates);

  useEffect(() => {
    const { branchAddress, city, state, phone } = payload;

    if (!branchAddress || !city || !state || !phone) {
      setValid(false);
    } else setValid(true);
  }, [payload]);

  const getStatesFromApi = async () => {
    try {
      const response = await fetch('https://nga-states-lga.onrender.com/fetch');
      const data = await response.json();
      setStates(data);
    } catch (error) {
      console.error(error);
      setStates([]);
    }
  };

  useEffect(() => {
    getStatesFromApi();
  }, []);

  const handleChange = (e: any) => {
    const { name, value } = e.target;

    setPayload((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const getLGAsFromApi = async (state: string) => {
    if (!state) return;
    try {
      const response = await fetch(
        `https://nga-states-lga.onrender.com/?state=${state}`
      );
      if (!response.ok) throw new Error('Failed to fetch LGAs');
      const data = await response.json();
      setLgas(data);
    } catch (error) {
      console.error(error);
      setLgas([]); // Clear LGAs if there's an error
    }
  };

  // Handle state change and fetch corresponding LGAs
  const handleStateChange = (e: ChangeEvent<any>) => {
    const selectedState = e.target.value;
    setSelectedState(selectedState);
    setPayload((prevPayload) => ({
      ...prevPayload,
      state: selectedState,
      city: '', // Reset city when state changes
    }));
    setSelectedLGA(''); // Clear selected LGA
    getLGAsFromApi(selectedState);
  };

  // Handle LGA change
  const handleLGAChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const selectedLGA = e.target.value;
    setSelectedLGA(selectedLGA);
    setPayload((prevPayload) => ({ ...prevPayload, city: selectedLGA }));
  };

  const handleSubmit = () => {
    const updatedPayload = { ...payload };

    if (updatedPayload.phone.startsWith('0')) {
      updatedPayload.phone = '+234' + updatedPayload.phone.slice(1);
    }
    mutate(updatedPayload);
  };

  return (
    <div></div>
    // <Sidebar
    //   isOpen={isSidebarOpen}
    //   onClose={closeSidebar}
    //   title={'Add a Branch'}
    // >
    //   <div>
    //     <div className="mb-4">
    //       <label htmlFor="phone" className="mb-3 block heading text-tmd">
    //         Phone Number
    //       </label>
    //       <input
    //         type="text"
    //         name="phone"
    //         placeholder=""
    //         autoCorrect="none"
    //         autoComplete="off"
    //         maxLength={11}
    //         className="w-full border-oslo border-[1px] p-5 rounded-md"
    //         value={payload.phone}
    //         onChange={handleChange}
    //       />
    //     </div>

    //     <div className="mb-4">
    //       <label htmlFor="state" className="mb-1 block heading text-tmd">
    //         State
    //       </label>
    //       <select
    //         value={selectedState}
    //         onChange={handleStateChange}
    //         className="w-full border-oslo border-[1px] p-3 rounded-md"
    //       >
    //         <option value="">Select state</option>
    //         {states &&
    //           states.map((state: string) => (
    //             <option key={state} value={state}>
    //               {state}
    //             </option>
    //           ))}
    //       </select>
    //     </div>

    //     <div className="mb-4">
    //       <label htmlFor="lga" className="mb-1 block heading text-tmd">
    //         LGA
    //       </label>
    // <select
    //   value={selectedLGA}
    //   disabled={selectedState === ''}
    //   onChange={handleLGAChange}
    //   className="w-full border-oslo border-[1px] p-3 rounded-md"
    // >
    // <option value="">Select LGA</option>
    // {lgas &&
    //   lgas.map((lga: string) => (
    //     <option key={lga} value={lga}>
    //       {lga}
    //     </option>
    //   ))}
    // </select>
    //     </div>
    //     <div className="mb-4">
    //       <label
    //         htmlFor="branchAddress"
    //         className="mb-3 block heading text-tmd"
    //       >
    //         Address
    //       </label>
    //       <textarea
    //         name="branchAddress"
    //         placeholder="Address"
    //         autoComplete="off"
    //         value={payload.branchAddress}
    //         onChange={handleChange}
    //         className="h-[150px] resize-none w-full border-oslo border-[1px] p-5 rounded-md"
    //       />
    //     </div>
    //     <button
    //       type="submit"
    //       onClick={handleSubmit}
    //       disabled={!valid}
    //       className={`p-3 text-white bg-tertiary rounded-md text-center w-full font-bold ${
    //         valid === false ? 'opacity-50' : ''
    //       }`}
    //     >
    //       Submit
    //     </button>
    //   </div>
    // </Sidebar>
  );
};

export default CreateBranch;
