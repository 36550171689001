import React, { ChangeEvent, FormEvent, useState } from 'react';
import BusinessAuthScreen from '../components/Auth/BusinessAuthScreen';
import { Link, useNavigate } from 'react-router-dom';
import { useSigninBusiness } from '../../../hooks/api/business/auth/useSigninBusiness';
import { InVisible, Visible } from '../../../components/Icons';
import { Fade } from 'react-awesome-reveal';
import { Helmet } from 'react-helmet-async';

const BusinessSignin = () => {
  const [passwordVisibility, setPasswordVisibility] = useState(false);

  const [payload, setPayload] = useState({
    email: '',
    password: '',
  });

  const { mutateAsync: signin, isLoading } = useSigninBusiness();

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setPayload((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    signin(payload);
  };

  return (
    <Fade duration={1000}>
      <Helmet>
        <title>Login</title>
        <meta name="description" content="Login to your account" />
        <meta name="robots" content="index,follow" />
        <link rel="canonical" href="https://www.billspot.app/app/register" />
      </Helmet>

      <form onSubmit={handleSubmit}>
        <div className="mt-10">
          <div className="mb-4">
            <label htmlFor="email" className="mb-3 block heading text-tmd">
              Email
            </label>
            <input
              type="email"
              name="email"
              placeholder="Email"
              disabled={isLoading}
              autoCorrect="none"
              className="w-full border-oslo border-[1px] p-3 rounded-md"
              value={payload.email}
              onChange={handleChange}
            />
          </div>
          <div className="mb-4">
            <label htmlFor="password" className="mb-3 block heading">
              Password
            </label>
            <div className="relative">
              <input
                type={passwordVisibility ? 'text' : 'password'}
                name="password"
                placeholder="Password"
                disabled={isLoading}
                autoCorrect="none"
                className="w-full border-oslo border-[1px] p-3 rounded-md"
                value={payload.password}
                onChange={handleChange}
              />
              <span
                onClick={() => setPasswordVisibility(!passwordVisibility)}
                className="cursor-pointer absolute right-5 top-3"
              >
                {passwordVisibility ? <InVisible /> : <Visible />}
              </span>
            </div>
          </div>
          <button
            type="submit"
            className="p-3 text-white bg-tertiary rounded-md text-center w-full font-bold"
            disabled={isLoading}
          >
            {isLoading ? 'Submitting...' : 'Submit'}
          </button>
        </div>
      </form>
    </Fade>
  );
};

export default BusinessSignin;
