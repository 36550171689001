import { useQuery } from 'react-query';
import { userAxiosInstance } from '../../../../../utils/userAxiosInstance';

export const useGetWalletHistoryQuery = () => {
  const token = localStorage.getItem('BSUserToken');

  return useQuery({
    queryKey: 'wallethistory',
    queryFn: () =>
      userAxiosInstance.get('wallet/transactions').then((res) => res.data),
    onSuccess: (data) => {
      console.log(`✅ wallethistory`);
    },
    onError: (error) => {
      console.log(`❌ wallethistory`);
    },
    enabled: token ? true : false,
  });
};

export const useGetSingleTransactionHistoryQuery = (id: string) => {
  const token = localStorage.getItem('BSUserToken');

  return useQuery({
    queryKey: ['singleTransaction', id],
    queryFn: () =>
      userAxiosInstance
        .get(`wallet/transactions/${id}`)
        .then((res) => res.data),
    onSuccess: (data) => {
      console.log(`✅ singleTransaction`);
    },
    onError: (error) => {
      console.log(`❌ singleTransaction`);
    },
    enabled: token ? true : false,
  });
};
