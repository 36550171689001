import { useQuery, useQueryClient } from 'react-query';
import { apiConfig } from '../../../../constants/apiConfig';
import { userAxiosInstance } from '../../../../utils/userAxiosInstance';
import { useNavigate } from 'react-router-dom';

const useGetUserDataQuery = () => {
  const queryClient = useQueryClient();

  const { data, isLoading, error } = useQuery({
    queryKey: 'getReloadClient',
    queryFn: () =>
      userAxiosInstance.get(`${apiConfig.USER_DATA}`).then((res) => res.data),
    onSuccess: (data) => {
      console.log('✅ getClientData');
      localStorage.setItem('BSUSER', JSON.stringify(data?.data));
    },
    onError: (error) => {
      console.log('❌ getClientData ==>', error);
    },
  });

  const handleRefresh = () => {
    queryClient.invalidateQueries('getReloadClient');
  };

  return { data, isLoading, error, handleRefresh };
};

export default useGetUserDataQuery;
