import { useState } from 'react';
import { Fade } from 'react-awesome-reveal';
import Footer from '../components/Footer';
import DownloadApp from '../components/DownloadApp';
import NavBar from '../components/NavBar';
import ScrollToTop from '../hooks/ScrollToTop';
import { Link, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

type Props = {};

const Business = () => {
  const [display, setDisplay] = useState(0);

  const faq = [
    {
      id: 1,
      title: 'What payment methods do you accept?',
      answer:
        'We accept various payment methods including credit/debit cards, bank transfers, and mobile payment services such as Apple Pay and Google Pay. You can choose the method that is most convenient for you.',
    },
    {
      id: 2,
      title: 'How long does it take for payments to process?',
      answer:
        'The time taken for payments to process can vary depending on the payment method and the utility provider. While most payments are made instantly, some take up to 1-3 business days to process.',
    },
    {
      id: 3,
      title: 'Is my payment information secure?',
      answer:
        'Yes, we prioritize the security of your payment information. We use industry-standard encryption and security measures to protect your data. Additionally, we do not store your payment details on our servers.',
    },
    {
      id: 4,
      title: 'Can I schedule recurring payments?',
      answer:
        'Yes, our app supports scheduling recurring payments for your convenience. You can set up automatic payments to ensure your bills are paid on time each month without the need for manual intervention.',
    },
    {
      id: 5,
      title: 'What should I do if my payment fails?',
      answer:
        'If your payment fails, please double-check the payment details you entered and ensure that you have sufficient funds in your account. If the issue persists, you can contact our customer support team for assistance.',
    },
    {
      id: 6,
      title: 'Can I view my payment history?',
      answer:
        "Yes, you can easily view your payment history within the app. Simply navigate to the 'Payment History' section where you'll find a detailed record of all your past payments, including dates and amounts.",
    },
    {
      id: 7,
      title: 'Are there any fees for using the app?',
      answer:
        // 'We do not charge any fees for using our bill payment app. However, please note that your bank or payment provider may impose transaction fees depending on your chosen payment method.',
        'We do not charge any fees for using our bill payment app. However, please note that SEVIS and WES Payments require processing fees which would be clearly outlined before you proceed to payment',
    },
  ];

  const placeholder = require('../images/business.png');

  const navigate = useNavigate();

  return (
    <ScrollToTop>
      <Helmet>
        <title>Business</title>
        <meta
          name="description"
          content="Join our community and start benefiting from our local giftcards feature. Sign up now and take the first step towards achieving your goals with us."
        />
        <meta name="robots" content="index,follow" />
        <link rel="canonical" href="https://www.billspot.app/business/" />
      </Helmet>

      <div className="bg-[#f1f4ff] mb-20">
        <section className="container max-w-[1250px] mx-auto">
          <p className="text-center font-bold heading pt-40 md:text-dlg text-dsm ">
            Open your business to the world
          </p>
          <h3 className="mt-3 mb-[20px] md:max-w-[70%] text-off text-tlg mx-auto md:w-[90%] md:text-center">
            Sign up and create personalized gift cards for your customers,
            enhancing their shopping experience and driving more patronage.
          </h3>
          <div className="flex mx-auto">
            <span
              onClick={() => navigate('/business/register')}
              className="mx-auto heading md:mt-0 mt-4 manrope500 shadow-md flex items-center gap-3 bg-tertiary text-white font-bold rounded-full py-4 px-10 cursor-pointer ease-in-out duration-300 hover:shadow-md"
            >
              Get Started
            </span>
          </div>
          <img src={placeholder} alt="" className="mt-10 w-[80%] mx-auto" />
        </section>
      </div>
      <Fade>
        <section className="container max-w-[1250px] mx-auto my-40">
          <h3 className="heading text-center text-dsm font-extrabold text-secondary">
            Perks of setting up a Gift Cards for
            <br /> your Business
          </h3>
          <div className="flex flex-wrap flex-row md:justify-center gap-3 my-10">
            <div className="heading font-bold w-6/12 md:w-auto md:flex gap-4 p-[40px] hover:cursor-pointer hover:bg-secondary hover:text-white bg-water md:rounded-full rounded-[20px] ease-in-out duration-300">
              Increase Sales and Revenue
            </div>
            <div className="heading font-bold w-6/12 md:w-auto md:flex gap-4 p-[40px] hover:cursor-pointer hover:bg-secondary hover:text-white bg-water md:rounded-full rounded-[20px] ease-in-out duration-300">
              Enhance Customer Loyalty
            </div>
            <div className="heading font-bold w-6/12 md:w-auto md:flex gap-4 p-[40px] hover:cursor-pointer hover:bg-secondary hover:text-white bg-water md:rounded-full rounded-[20px] ease-in-out duration-300">
              Easy to Implement and Manage
            </div>
            <div className="heading font-bold w-6/12 md:w-auto md:flex gap-4 p-[40px] hover:cursor-pointer hover:bg-secondary hover:text-white bg-water md:rounded-full rounded-[20px] ease-in-out duration-300">
              Boost Marketing and Promotions
            </div>
            <div className="heading font-bold w-6/12 md:w-auto md:flex gap-4 p-[40px] hover:cursor-pointer hover:bg-secondary hover:text-white bg-water md:rounded-full rounded-[20px] ease-in-out duration-300">
              Flexible Redemption Options
            </div>
            <div className="heading font-bold w-6/12 md:w-auto md:flex gap-4 p-[40px] hover:cursor-pointer hover:bg-secondary hover:text-white bg-water md:rounded-full rounded-[20px] ease-in-out duration-300">
              Secure and Reliable Transactions
            </div>
            <div className="heading font-bold w-6/12 md:w-auto md:flex gap-4 p-[40px] hover:cursor-pointer hover:bg-secondary hover:text-white bg-water md:rounded-full rounded-[20px] ease-in-out duration-300">
              Attract New Customers
            </div>
            <div className="heading font-bold w-6/12 md:w-auto md:flex gap-4 p-[40px] hover:cursor-pointer hover:bg-secondary hover:text-white bg-water md:rounded-full rounded-[20px] ease-in-out duration-300">
              Encourage Prepaid Purchases
            </div>
            <div className="heading font-bold w-6/12 md:w-auto md:flex gap-4 p-[40px] hover:cursor-pointer hover:bg-secondary hover:text-white bg-water md:rounded-full rounded-[20px] ease-in-out duration-300">
              Customizable Designs
            </div>
            <div className="heading font-bold w-6/12 md:w-auto md:flex gap-4 p-[40px] hover:cursor-pointer hover:bg-secondary hover:text-white bg-water md:rounded-full rounded-[20px] ease-in-out duration-300">
              Seamless Online Integration
            </div>
            <div className="heading font-bold w-6/12 md:w-auto md:flex gap-4 p-[40px] hover:cursor-pointer hover:bg-secondary hover:text-white bg-water md:rounded-full rounded-[20px] ease-in-out duration-300">
              Comprehensive Reporting and Analytics
            </div>
            <div className="heading font-bold w-6/12 md:w-auto md:flex gap-4 p-[40px] hover:cursor-pointer hover:bg-secondary hover:text-white bg-water md:rounded-full rounded-[20px] ease-in-out duration-300">
              Support for Promotions and Special Events
            </div>
            <div className="heading font-bold w-6/12 md:w-auto md:flex gap-4 p-[40px] hover:cursor-pointer hover:bg-secondary hover:text-white bg-water md:rounded-full rounded-[20px] ease-in-out duration-300">
              Scalable for All Business Sizes
            </div>
            <div className="heading font-bold w-6/12 md:w-auto md:flex gap-4 p-[40px] hover:cursor-pointer hover:bg-secondary hover:text-white bg-water md:rounded-full rounded-[20px] ease-in-out duration-300">
              Incentivize Employee Performance
            </div>
          </div>
        </section>
      </Fade>
      <Fade>
        <section className="container max-w-[1250px] mx-auto mb-40 bg-secondary md:rounded-[30px] md:p-[40px]">
          <div className="md:flex block md:gap-[20px] md:py-0 py-10">
            <div className="md:w-6/12 w-full">
              <h4 className="heading text-white md:text-dmd text-txs font-bold">
                Still got questions?
              </h4>
              <p className="mt-3 max-w-[80%] text-tlg w-[90%] text-water md:mb-0 mb-10">
                Explore our list of frequently asked questions to find the
                information you need. If you don't see your question here, feel
                free to reach out to our support team for further assistance.
              </p>
            </div>
            <div className="md:w-6/12 w-full">
              {faq.map(({ id, title, answer }) => (
                <div
                  className={`p-4 border-b-[1.2px] mb-4 ease-in-out duration-300 ${
                    display === id ? '' : ''
                  }`}
                  key={id}
                >
                  <div
                    className={`cursor-pointer flex justify-between text-dxs ${
                      display === id ? 'text-offline' : 'text-white'
                    }`}
                    onClick={() => setDisplay(display === id ? 0 : id)}
                  >
                    <span
                      className={`manrope700 font-bold ${
                        display === id ? 'text-offline' : 'text-white'
                      }`}
                    >
                      {title}
                    </span>
                    <span className={`text-[12px] ${display === id ? '' : ''}`}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.2}
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="m19.5 8.25-7.5 7.5-7.5-7.5"
                        />
                      </svg>
                    </span>
                  </div>
                  {display === id && (
                    <div className="mt-4 text-[#fff2bf]">{answer}</div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </section>
      </Fade>
      <DownloadApp />
      <Footer />
    </ScrollToTop>
  );
};

export default Business;
