import { useMutation } from 'react-query';
import toast from 'react-hot-toast';
import { axiosInstance } from '../../../../utils/axiosInstance';
import { getUpdatedDetails } from '../getUpdatedDetails';
import { useGetBusinessData } from '../profile/useGetBusinessData';

export const useSubmitSocialInfo = (payload: any) => {
  const updatedPayload = { ...payload };
  const { handleRefresh } = useGetBusinessData();

  if (updatedPayload.whatsapp.startsWith('0')) {
    updatedPayload.whatsapp = '+234' + updatedPayload.whatsapp.slice(1);
  }

  return useMutation(
    () =>
      axiosInstance
        .post('company/profile/create-socials', updatedPayload)
        .then((res: any) => res.data),
    {
      onSuccess: async () => {
        console.log('✅ useUpdateBasicInfo');

        getUpdatedDetails();
        handleRefresh();
      },
      onError: (error: any) => {
        console.log('❌ useUpdateBasicInfo ');
        toast(error?.response?.data?.message);
      },
    }
  );
};
