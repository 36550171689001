import React, { useState } from 'react';
import VerificationWrapper from '../../business/home/Verification/VerificationWrapper';
import { useSetTransactionPin } from '../../../hooks/api/app/auth/useSetTransactionPin';

const SetTransactionPin = () => {
  const [transactionPin, setTransactionPin] = useState('');

  const { mutate, isLoading } = useSetTransactionPin(transactionPin);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    mutate();
  };

  return (
    <VerificationWrapper title="Set Transaction PIN" icon={undefined}>
      <form onSubmit={handleSubmit} className="mt-10">
        <div className="mb-4">
          <label
            htmlFor="transactionPin"
            className="mb-2 block heading text-tmd"
          >
            Transaction PIN
          </label>
          <p className="mb-3">
            Create a secure 4 digit PIN for all your transactions
          </p>
          <input
            type="tel"
            name="transactionPin"
            autoFocus
            placeholder=""
            maxLength={4}
            autoComplete="off"
            value={transactionPin}
            onChange={(e) => setTransactionPin(e.target.value)}
            className="w-full border-oslo border-[1px] p-3 rounded-md"
          />
        </div>
        <button
          type="submit"
          className={`bg-tertiary p-3 rounded-md text-center text-white font-medium cursor-pointer ${
            transactionPin === ''
              ? 'opacity-50 cursor-not-allowed'
              : 'cursor-pointer'
          } `}
          disabled={isLoading === true && transactionPin === ''}
        >
          Submit
        </button>
      </form>
    </VerificationWrapper>
  );
};
export default SetTransactionPin;
