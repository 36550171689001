import { useMutation, useQueryClient } from 'react-query';
import { userAxiosInstance } from '../../../../utils/userAxiosInstance';
import { useKeypad } from '../../../../contexts/KeypadContext';
import { useSidebar } from '../../../../contexts/SidebarContext';
import toast from 'react-hot-toast';

export const useTransferMoney = (id: string, sidebarCloser: any) => {
  const { toggleKeypad } = useKeypad();
  const { closeSidebar } = useSidebar();
  const queryClient = useQueryClient();

  return useMutation(
    (payload: any) =>
      userAxiosInstance
        .post(`/wallet/transfer/${id}`, payload)
        .then((res: any) => res.data),
    {
      onSuccess: async (data: any) => {
        toggleKeypad();
        sidebarCloser();
        toast(data?.message);
        queryClient.invalidateQueries('walletbalance');
      },
      onError: (error: any) => {},
    }
  );
};
