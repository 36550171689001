// SidebarContext.jsx
import React, { createContext, useState, useContext, ReactNode } from 'react';

type SidebarContextType = {
  isSidebarOpen: boolean;
  openSidebar: () => void;
  closeSidebar: () => void;
  isUtilityBillSidebar: boolean;
  closeUtilitySidebar: () => void;
  openUtilitySidebar: () => void;
};

const SidebarContext = createContext<SidebarContextType | undefined>(undefined);

export const SidebarProvider = ({ children }: { children: ReactNode }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isUtilityBillSidebar, setIsUtilityBillSidebar] = useState(false);

  const openSidebar = () => setIsSidebarOpen(true);
  const closeSidebar = () => setIsSidebarOpen(false);

  const closeUtilitySidebar = () => {
    setIsUtilityBillSidebar(false);
  };

  const openUtilitySidebar = () => {
    setIsUtilityBillSidebar(true);
  };

  return (
    <SidebarContext.Provider
      value={{
        isSidebarOpen,
        openSidebar,
        closeSidebar,
        isUtilityBillSidebar,
        closeUtilitySidebar,
        openUtilitySidebar,
      }}
    >
      {children}
    </SidebarContext.Provider>
  );
};

export const useSidebar = () => {
  const context = useContext(SidebarContext);
  if (context === undefined) {
    throw new Error('useSidebar must be used within a SidebarProvider');
  }
  return context;
};
