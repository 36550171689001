import React, { ChangeEvent, useState } from 'react';
import VerificationWrapper from './VerificationWrapper';
import { useSubmitCompanyLogo } from '../../../../hooks/api/business/Onboarding/useSubmitCompanyLogo';
import { useSubmitCompanyMediaData } from '../../../../hooks/api/business/Onboarding/useSubmitCompanyMediaData';

type Props = {};

const CompanyPhotos = (props: Props) => {
  const [photos, setPhotos] = useState<File[]>([]);

  const handlePhotoChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      const newPhotos = Array.from(files).slice(0, 5 - photos.length);
      setPhotos((prevPhotos) => [...prevPhotos, ...newPhotos]);
    }
  };

  const { mutate, isLoading: uploading } = useSubmitCompanyMediaData(photos);

  const handleUpload = (e: React.FormEvent) => {
    e.preventDefault();
    mutate();
  };

  return (
    <VerificationWrapper icon="" title="Company Photos">
      <div className="mt-[30px] bg-[#F2F2F2] rounded-md py-[35px] mx-auto flex flex-col">
        <label className="text-center mx-auto cursor-pointer rounded-full bg-tertiary text-white text-tsm font-medium p-3">
          {photos.length < 5 ? 'Click to upload' : 'Maximum 5 photos uploaded'}
          <input
            type="file"
            className="hidden"
            accept="image/*"
            onChange={handlePhotoChange}
            aria-label="Upload Photos Input"
            multiple
            disabled={photos.length >= 5}
          />
        </label>
        <p className="text-center text-[#a5a5a5] mt-[7px]">
          Upload a maximum of 5 photos
        </p>
      </div>
      {photos.length > 0 && (
        <>
          <div className="mt-3">
            <h3 className="heading mb-3">Photos Preview</h3>
            <div className="flex flex-nowrap gap-[20px] no-scrollbar mb-3">
              {photos.map((photo, index) => (
                <div key={index} className="flex flex-col items-center">
                  <img
                    src={URL.createObjectURL(photo)}
                    alt={`Preview ${index + 1}`}
                    className="h-[150px] w-[250px] rounded-md"
                  />
                  <p className="text-center text-[#333] mt-2">{photo.name}</p>
                </div>
              ))}
            </div>
          </div>
          {photos.length > 1 && (
            <form onSubmit={handleUpload}>
              <button
                type="submit"
                className="p-3 text-white bg-tertiary rounded-md text-center font-bold"
                disabled={uploading}
              >
                {uploading ? 'Submitting...' : 'Submit'}
              </button>
            </form>
          )}
        </>
      )}
    </VerificationWrapper>
  );
};

export default CompanyPhotos;
