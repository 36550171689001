import React, { useState } from 'react';
import AppLayout from '../../../components/AppLayout';
import { useGetStoresQuery } from '../../../hooks/api/app/appstore/useGetStoresQuery';
import AppStoreCard from './components/AppStoreCard';
import { Link } from 'react-router-dom';
import Loader from '../../../components/Loader';
import Empty from '../../../components/Empty';

const AppMarketplace = () => {
  const [category, setCategory] = useState<string>(''); // Ensure correct typing
  const { data, isFetching } = useGetStoresQuery(category);

  // const handleFilter = (e: React.ChangeEvent<HTMLSelectElement>) => {
  //   setCategory(e.target.value);
  // };

  return (
    <AppLayout>
      <h3 className="heading text-txl font-bold">Marketplace</h3>
      <p className="text-off">Local Giftcards from stores</p>

      <div className="mt-10 text-xs">
        <div className="flex justify-end">
          {/* <select
            name="category"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
            className="w-[200px] border-oslo border-[1px] p-3 rounded-full bg-offline text-[13px]"
          >
            <option value="">All Categories</option>
            <option value="Food & Drink">Food & Drink</option>
            <option value="Health care & fitness">Health care & fitness</option>
            <option value="Beauty & personal care">
              Beauty & personal care
            </option>
            <option value="Professional Services">Professional Services</option>
            <option value="Charities">Charities</option>
            <option value="Education">Education</option>
            <option value="Membership">Membership</option>
            <option value="Retail">Retail</option>
            <option value="Leisure & entertainment">
              Leisure & entertainment
            </option>
            <option value="Home & repair">Home & repair</option>
            <option value="Transportation">Transportation</option>
            <option value="Others">Others</option>
          </select> */}
        </div>

        {isFetching && <Loader />}

        <div className="md:h-[70vh] h-auto overflow-y-scroll">
          <div className="flex flex-wrap">
            {/* {JSON.stringify(data?.data?.data)} */}
            {data && !isFetching && data?.data?.data.length === 0 && (
              <Empty text="No stores available for this category." />
            )}

            {/* Display list of stores */}
            {data &&
              !isFetching &&
              data?.data?.data.map(
                ({
                  id,
                  logo,
                  priceRanges,
                  businessName,
                }: {
                  id: string;
                  logo: string;
                  priceRanges: string[];
                  businessName: string;
                }) => (
                  <Link
                    to={`/app/marketplace/${id}`}
                    key={id}
                    className="md:w-1/4 w-2/4 p-2"
                  >
                    <img
                      src={logo}
                      alt={`${businessName} logo`}
                      className="w-full h-[200px] object-cover"
                    />
                    <p className="font-medium heading text-[13px] mt-3 text-[#3d3d3d] truncate">
                      {businessName}
                    </p>
                    {/* <p className="text-sm text-gray-500">{priceRanges}</p> */}
                  </Link>
                )
              )}
          </div>
        </div>
      </div>
    </AppLayout>
  );
};

export default AppMarketplace;
