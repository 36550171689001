import toast from 'react-hot-toast';

export const getUpdatedDetails = () => {
  const stringifyToken = localStorage.getItem('BSbusinessToken');
  const parsedToken = stringifyToken ? JSON.parse(stringifyToken) : null;

  const url = `${process.env.REACT_APP_BASE_URL}/company/me`;

  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${parsedToken} `,
    },
  };

  fetch(url, options)
    .then((response) => {
      if (!response.ok) {
        toast('Network response was not ok');
      }
      return response.json();
    })
    .then((data) => {
      console.log(data);
      localStorage.setItem('@BSCompanyData', JSON.stringify(data?.data));
    })
    .catch((error) => {
      console.error('There was a problem with the fetch operation:', error);
      toast('Error, Kindly try again');
    });
};
