import React, { useState, ChangeEvent } from 'react';
import toast from 'react-hot-toast';
import { useUpdateOpeningHoursData } from '../../../../../hooks/api/business/profile/useUpdateOpeningHoursData';
import { useGetBusinessData } from '../../../../../hooks/api/business/profile/useGetBusinessData';

const times = [
  '12am',
  '1am',
  '2am',
  '3am',
  '4am',
  '5am',
  '6am',
  '7am',
  '8am',
  '9am',
  '10am',
  '11am',
  '12pm',
  '1pm',
  '2pm',
  '3pm',
  '4pm',
  '5pm',
  '6pm',
  '7pm',
  '8pm',
  '9pm',
  '10pm',
  '11pm',
];

const daysOfWeek = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];

interface BusinessHours {
  [key: string]: string;
}

const initialBusinessHours: BusinessHours = {
  Monday: '',
  Tuesday: '',
  Wednesday: '',
  Thursday: '',
  Friday: '',
  Saturday: '',
  Sunday: '',
};

const TimeSelector: React.FC = () => {
  const [businessHours, setBusinessHours] =
    useState<BusinessHours>(initialBusinessHours);

  const { mutate, isLoading } = useUpdateOpeningHoursData(businessHours);

  const handleSubmit = () => {
    mutate();
  };

  const handleToggle = (day: string) => {
    setBusinessHours((prev) => ({
      ...prev,
      [day]: prev[day] ? '' : '9am - 5pm', // Default time when toggled on
    }));
  };

  const handleChange = (
    day: string,
    startOrEnd: 'start' | 'end',
    value: string
  ) => {
    setBusinessHours((prev) => {
      const [start, end] = prev[day] ? prev[day].split(' - ') : ['', ''];
      if (
        startOrEnd === 'start' &&
        end &&
        times.indexOf(value) >= times.indexOf(end)
      ) {
        toast.error('Cannot select time, check again');
        return prev;
      } else if (
        startOrEnd === 'end' &&
        start &&
        times.indexOf(start) >= times.indexOf(value)
      ) {
        toast.error('Cannot select time, check again');
        return prev;
      }

      return {
        ...prev,
        [day]:
          startOrEnd === 'start'
            ? `${value} - ${end || '5pm'}`
            : `${start || '9am'} - ${value}`,
      };
    });
  };

  const { data } = useGetBusinessData();

  return (
    <div className="p-4 space-y-4">
      {/* {JSON.stringify(data?.data?.openingHours)} */}
      <div className="heading">Proceed to select opening days and timing</div>
      {daysOfWeek.map((day) => (
        <div key={day} className="flex items-center space-x-4">
          <label className="flex items-center space-x-2 w-[200px]">
            <div className="relative cursor-pointer">
              <input
                type="checkbox"
                checked={businessHours[day] !== ''}
                onChange={() => handleToggle(day)}
                className="sr-only"
              />
              <div
                className={`w-10 h-4 rounded-full shadow-inner transition ${
                  businessHours[day] !== '' ? 'bg-primary' : 'bg-water'
                }`}
              ></div>
              <div
                className={`dot absolute w-6 h-6 rounded-full shadow -left-1 -top-1 transition ${
                  businessHours[day] !== ''
                    ? 'transform translate-x-full bg-green'
                    : 'bg-white'
                }`}
              ></div>
            </div>
            <span className="font-medium">{day}</span>
          </label>
          {businessHours[day] === '' ? (
            <span className="text-center text-oslol">Closed</span>
          ) : (
            <div className="flex space-x-2 text-oslol">
              <select
                value={businessHours[day].split(' - ')[0]}
                onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                  handleChange(day, 'start', e.target.value)
                }
                className="form-select block w-full p-3"
              >
                {times.map((time) => (
                  <option key={time} value={time}>
                    {time}
                  </option>
                ))}
              </select>
              <span>-</span>
              <select
                value={businessHours[day].split(' - ')[1]}
                onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                  handleChange(day, 'end', e.target.value)
                }
                className="form-select block w-full"
              >
                {times.map((time) => (
                  <option key={time} value={time}>
                    {time}
                  </option>
                ))}
              </select>
            </div>
          )}
        </div>
      ))}
      <button
        className="bg-tertiary p-3 rounded-md text-center text-white font-medium cursor-pointer"
        disabled={isLoading}
        onClick={handleSubmit}
      >
        Submit
      </button>
    </div>
  );
};

export default TimeSelector;
