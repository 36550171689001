import React, { useState } from 'react';
import { Fade } from 'react-awesome-reveal';

type Props = {};

const Faq = () => {
  const [display, setDisplay] = useState(0);

  const faq = [
    {
      id: 1,
      title: 'What payment methods do you accept?',
      answer:
        'We accept various payment methods including credit/debit cards, bank transfers, and mobile payment services such as Apple Pay and Google Pay. You can choose the method that is most convenient for you.',
    },
    {
      id: 2,
      title: 'How long does it take for payments to process?',
      answer:
        'The time taken for payments to process can vary depending on the payment method and the utility provider. While most payments are made instantly, some take up to 1-3 business days to process.',
    },
    {
      id: 3,
      title: 'Is my payment information secure?',
      answer:
        'Yes, we prioritize the security of your payment information. We use industry-standard encryption and security measures to protect your data. Additionally, we do not store your payment details on our servers.',
    },
    {
      id: 4,
      title: 'Can I schedule recurring payments?',
      answer:
        'Yes, our app supports scheduling recurring payments for your convenience. You can set up automatic payments to ensure your bills are paid on time each month without the need for manual intervention.',
    },
    {
      id: 5,
      title: 'What should I do if my payment fails?',
      answer:
        'If your payment fails, please double-check the payment details you entered and ensure that you have sufficient funds in your account. If the issue persists, you can contact our customer support team for assistance.',
    },
    {
      id: 6,
      title: 'Can I view my payment history?',
      answer:
        "Yes, you can easily view your payment history within the app. Simply navigate to the 'Payment History' section where you'll find a detailed record of all your past payments, including dates and amounts.",
    },
    {
      id: 7,
      title: 'Are there any fees for using the app?',
      answer:
        // 'We do not charge any fees for using our bill payment app. However, please note that your bank or payment provider may impose transaction fees depending on your chosen payment method.',
        'We do not charge any fees for using our bill payment app. However, please note that SEVIS and WES Payments require processing fees which would be clearly outlined before you proceed to payment',
    },
  ];

  return (
    <Fade>
      <section className="container max-w-[1250px] mx-auto md:mb-40 bg-secondary md:rounded-[30px] md:p-[40px]">
        <div className="md:flex block md:gap-[20px] md:py-0 py-10">
          <div className="md:w-6/12 w-full">
            <h4 className="heading text-white md:text-dmd text-txs font-bold">
              Still got questions?
            </h4>
            <p className="mt-3 md:max-w-[80%] text-tlg md:w-[90%] w-full text-water md:mb-0 mb-10">
              Explore our list of frequently asked questions to find the
              information you need. If you don't see your question here, feel
              free to reach out to our support team for further assistance.
            </p>
          </div>
          <div className="md:w-6/12 w-full">
            {faq.map(({ id, title, answer }) => (
              <div
                className={`p-4 border-b-[1px] border-[#777] mb-4 ease-in-out duration-300 ${
                  display === id ? '' : ''
                }`}
                key={id}
              >
                <div
                  className={`cursor-pointer flex justify-between text-dxs ${
                    display === id ? 'text-yellow' : 'text-white'
                  }`}
                  onClick={() => setDisplay(display === id ? 0 : id)}
                >
                  <span
                    className={`manrope700 font-bold ${
                      display === id ? 'text-yellow' : 'text-white'
                    }`}
                  >
                    {title}
                  </span>
                  <span className={`text-[12px] ${display === id ? '' : ''}`}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.2}
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="m19.5 8.25-7.5 7.5-7.5-7.5"
                      />
                    </svg>
                  </span>
                </div>
                {display === id && (
                  <div className="mt-4 text-[#fff2bf]">{answer}</div>
                )}
              </div>
            ))}
          </div>
        </div>
      </section>
    </Fade>
  );
};

export default Faq;
