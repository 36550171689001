import React from 'react';

type Props = { children: React.ReactNode };

const AppAuthScreen = ({ children }: Props) => {
  return (
    <div className="md:flex h-dvh overflow-y-auto">
      <div className="md:w-6/12 w-full">{children}</div>
      <div className="md:block hidden w-6/12 bg-tertiary" />
    </div>
  );
};

export default AppAuthScreen;
