import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Store } from '../../../../components/Icons';
import Modal from '../../../../components/Modal';
import Sidebar from '../../../../components/SideBar';
import { useSidebar } from '../../../../contexts/SidebarContext';
import withAuth from '../../../../hoc/useAuth';
import { useGetBusinessData } from '../../../../hooks/api/business/profile/useGetBusinessData';
import { Fade } from 'react-awesome-reveal';

type Props = { children: React.ReactNode };

const BusinessDashboardLayout = ({ children }: Props) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };
  const { data, isLoading, handleRefresh } = useGetBusinessData();

  const logo = require('../../../../images/logo.png');

  const location = useLocation();
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.clear();
    navigate('/');
  };

  return (
    <div className="">
      <div className="border-[#ddd] border-b-[1px] py-3">
        <div className="max-w-[1400px] mx-auto px-3">
          <span className="flex justify-between items-center">
            <span className="flex items-center text-oslol">
              <Link
                to="/"
                className="text-[20px] font-bold text-tertiary flex gap-3 items-center heading"
              >
                <img src={logo} alt="" className="h-[40px]" />
                BillSpot
              </Link>
              <span className="ml-20 hidden md:flex gap-[40px] font-medium heading">
                <Link
                  to="/business/home"
                  className={`${
                    location?.pathname.includes('/business/home') &&
                    'font-bold text-green'
                  }`}
                >
                  Home
                </Link>
                <Link
                  to="/business/sales"
                  className={`${
                    location?.pathname.includes('/business/sales') &&
                    'font-bold text-green'
                  }`}
                >
                  Sales
                </Link>
                <Link
                  to="/business/profile"
                  className={`${
                    location?.pathname.includes('/business/profile') &&
                    'font-bold text-green'
                  }`}
                >
                  Profile
                </Link>
                <Link
                  to="/business/settings"
                  className={`${
                    location?.pathname.includes('/business/settings') &&
                    'font-bold text-green'
                  }`}
                >
                  Settings
                </Link>
              </span>
            </span>
            <span className="flex gap-3 items-center md:hidden">
              {/* Hamburger menu for smaller screens */}
              <button
                onClick={toggleSidebar}
                className="text-[30px] focus:outline-none"
              >
                &#9776;
              </button>
            </span>
            {/* Business info for larger screens */}
            <span className="hidden md:flex gap-3 items-center">
              <span className="flex gap-2">
                <img
                  src={data?.data?.logo}
                  alt="logo"
                  className="h-[40px] rounded-full"
                />
                <div className="flex flex-col">
                  <div className="font-medium w-[200px] truncate text-ellipsis">
                    {data?.data?.email}
                  </div>
                  <span className="text-xxs text-secondarysecondary">
                    {data?.data?.businessName}
                  </span>
                </div>
              </span>
              <span
                onClick={handleLogout}
                className="font-medium text-red-600 cursor-pointer flex gap-3"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m12.75 15 3-3m0 0-3-3m3 3h-7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                  />
                </svg>
              </span>
            </span>
          </span>

          {/* Sidebar for smaller screens */}
          <div
            className={`fixed top-0 left-0 h-full bg-white shadow-lg z-50 w-full transform transition-transform duration-300 ${
              sidebarOpen ? 'translate-x-0' : '-translate-x-full'
            }`}
          >
            <div className="p-5">
              {/* Close button */}
              <div className="text-right">
                <button
                  onClick={toggleSidebar}
                  className="text-[30px] mb-4 focus:outline-none text-right"
                >
                  &times;
                </button>
              </div>

              {/* Business info moved to the sidebar for smaller screens */}
              <div className="flex gap-2 mb-4">
                <img
                  src={data?.data?.logo}
                  alt="logo"
                  className="h-[40px] rounded-full"
                />
                <div className="flex flex-col">
                  <div className="font-medium w-[200px] truncate text-ellipsis">
                    {data?.data?.email}
                  </div>
                  <span className="text-xxs text-secondarysecondary">
                    {data?.data?.businessName}
                  </span>
                </div>
              </div>

              {/* Navigation Links */}
              <nav className="flex flex-col gap-4 font-medium heading">
                <Link
                  to="/business/home"
                  onClick={toggleSidebar}
                  className={`${
                    location?.pathname.includes('/business/home') &&
                    'font-bold text-green'
                  }`}
                >
                  Home
                </Link>
                <Link
                  to="/business/sales"
                  onClick={toggleSidebar}
                  className={`${
                    location?.pathname.includes('/business/sales') &&
                    'font-bold text-green'
                  }`}
                >
                  Sales
                </Link>
                <Link
                  to="/business/profile"
                  onClick={toggleSidebar}
                  className={`${
                    location?.pathname.includes('/business/profile') &&
                    'font-bold text-green'
                  }`}
                >
                  Profile
                </Link>
                <Link
                  to="/business/settings"
                  onClick={toggleSidebar}
                  className={`${
                    location?.pathname.includes('/business/settings') &&
                    'font-bold text-green'
                  }`}
                >
                  Settings
                </Link>
              </nav>

              {/* Logout option */}
              <span
                onClick={handleLogout}
                className="font-medium text-red-600 cursor-pointer flex gap-3 mt-5"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m12.75 15 3-3m0 0-3-3m3 3h-7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                  />
                </svg>
                Logout
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="max-w-[1400px] mx-auto px-4">{children}</div>
    </div>
  );
};

export default withAuth(BusinessDashboardLayout);
