import React, { useState } from 'react';
import { useKeypad } from '../../../../../../../contexts/KeypadContext';
import KeyPad from '../../../../../../../components/KeyPad';
import {
  useGetCablePlanQuery,
  useGetCableProvidersQuery,
  usePayCableTvSubscription,
} from '../../../../../../../hooks/api/app/Cable/useGetCableProvidersQuery';
import PricingOptions from './PricingOptions';

const Cable = () => {
  const [payload, setPayload] = useState({
    smartCardNo: '',
    totalAmount: '',
    productCode: '',
    serviceType: '',
    monthsPaidFor: '',
    transactionPin: '',
  });

  const { data } = useGetCableProvidersQuery();
  const { mutate, data: cablePlans } = useGetCablePlanQuery();
  const { mutate: submitPayload } = usePayCableTvSubscription(setPayload);

  const { toggleKeypad, isKeypadOpen } = useKeypad();

  const handleCableSelection = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setPayload({ ...payload, serviceType: e.target.value });
    let data = e.target.value;
    mutate(data);
  };

  const handleSubmit = () => {
    let updatedPayload = {
      ...payload,
      totalAmount: +payload?.totalAmount,
      monthsPaidFor: +payload?.monthsPaidFor,
    };

    submitPayload(updatedPayload);
  };

  return (
    <div>
      <div className="mb-4">
        <label htmlFor="network" className="mb-1 block heading text-tmd">
          Select Cable
        </label>
        <select
          name="network"
          id="network"
          className="w-full border-oslo border-[1px] p-3 rounded-md"
          onChange={handleCableSelection}
        >
          <option value="">Select Option</option>
          {data?.data?.providers.map((datum: any) => (
            <option value={datum.service_type} key={datum.name}>
              {datum.name}
            </option>
          ))}
        </select>
      </div>
      {cablePlans && (
        <PricingOptions
          data={cablePlans}
          payload={payload}
          setPayload={setPayload}
        />
      )}
      <button
        onClick={toggleKeypad}
        className="p-3 text-white bg-tertiary rounded-md text-center w-full font-bold"
        disabled={
          !payload.monthsPaidFor || !payload.productCode || !payload.serviceType
        }
      >
        Submit
      </button>
      <KeyPad
        isKeypadOpen={isKeypadOpen}
        toggleKeypad={toggleKeypad}
        payload={payload}
        setPayload={setPayload}
        handleSubmit={handleSubmit}
      />
    </div>
  );
};

export default Cable;
