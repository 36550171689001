import React, { ChangeEvent, FormEvent, useState } from 'react';
import toast from 'react-hot-toast';
import { useModal } from '../../../../contexts/ModalContext';
import VerificationWrapper from './VerificationWrapper';
import Modal from '../../../../components/Modal';
import { useVerifyBusiness } from '../../../../hooks/api/business/auth/useVerifyBusiness';
// import { useInitiateUserVerification } from '../../../../hooks/api/app/auth/useInitiateUserVerification';
// import { useGetBusinessFromLocalStorage } from '../../../../utils/useGetBusinessFromLocalStorage';

const EmailAndPhoneOTP = () => {
  const { isModalOpen, closeModal, openModal } = useModal();
  let rawData = localStorage.getItem('@BSCompanyData');
  let rawToken = localStorage.getItem('BSbusinessToken');
  let BSBusinessData = JSON.parse(`${rawData}`);
  let BSBusinessToken = JSON.parse(`${rawToken}`);

  // const { BSBusinessData } = useGetBusinessFromLocalStorage();

  const [verification, setVerification] = useState({
    otp: '',
    // phoneOtp: '',
  });

  const handleInitialization = () => {
    const url = `${process.env.REACT_APP_BASE_URL}/company/send-otp/${BSBusinessData?.id}`;

    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${BSBusinessToken}`,
      },
    };

    fetch(url, options)
      .then((response) => {
        if (!response.ok) {
          toast('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        console.log(data);
        toast.success('OTPs sent');
      })
      .catch((error) => {
        console.error('There was a problem with the fetch operation:', error);
        toast('Error, Kindly try again');
      });

    openModal();
  };

  const { mutateAsync: verify, isLoading: verifying } = useVerifyBusiness({
    verification,
  });

  const handleVerification = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    verify();
  };

  const handleVer = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setVerification((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <VerificationWrapper icon={''} title="Email and Phone Verification">
      <div
        className={`p-4 ${
          BSBusinessData?.data?.progressCount === 0 ? 'block' : ''
        }`}
      >
        <div className="mb-3 font-medium">
          Verify that the information below is correct.{' '}
        </div>
        <p className="my-3">
          An OTP would be sent to your email ({BSBusinessData?.email})
        </p>

        <button
          className="p-3 text-white bg-tertiary rounded-md text-center font-bold"
          onClick={handleInitialization}
        >
          Get Started
        </button>
      </div>
      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        title={'Email and Phone Verification'}
      >
        <form onSubmit={handleVerification} className="">
          <div className="my-4">
            <label htmlFor="otp" className="mb-3 block heading text-tmd">
              Email OTP
            </label>
            <input
              type="text"
              name="otp"
              placeholder="OTP"
              disabled={verifying}
              autoCorrect="none"
              className="w-full border-oslo border-[1px] p-3 rounded-md"
              value={verification.otp}
              onChange={handleVer}
            />
          </div>
          {/* <div className="mb-4">
            <label htmlFor="phoneOtp" className="mb-3 block heading text-tmd">
              Phone OTP
            </label>
            <input
              type="text"
              name="phoneOtp"
              placeholder="Phone OTP"
              disabled={verifying}
              autoCorrect="none"
              className="w-full border-oslo border-[1px] p-5 rounded-md"
              value={verification.phoneOtp}
              onChange={handleVer}
            />
          </div> */}
          <button
            type="submit"
            className="p-3 text-white bg-tertiary rounded-md text-center w-full font-bold"
            disabled={verifying}
          >
            {verifying ? 'Submitting...' : 'Submit'}
          </button>
        </form>
      </Modal>
    </VerificationWrapper>
  );
};

export default EmailAndPhoneOTP;
