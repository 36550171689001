import { useMutation } from 'react-query';
import toast from 'react-hot-toast';
import { apiConfig } from '../../../../constants/apiConfig';
import { axiosInstance } from '../../../../utils/axiosInstance';
import { useNavigate } from 'react-router-dom';

interface Props {
  email: string;
  password: string;
  phoneNumber: string;
}

export const useRegisterBusiness = () => {
  const navigate = useNavigate();

  return useMutation(
    (payload: Props) =>
      axiosInstance
        .post(`${apiConfig.REGISTER}`, payload)
        .then((res: any) => res.data),
    {
      onSuccess: async (data: any) => {
        // console.log('Registration successful', data);
        toast('Registration successful, Proceed to login');
        navigate('/business/signin');
      },
      onError: (error: any) => {
        // console.log('Registration failed', error);
        toast(error?.response?.data?.message);
      },
    }
  );
};
