import React, { useState } from 'react';
import AppLayout from '../../../components/AppLayout';
import Security from './tabs/Security';
import Profile from './tabs/Profile';
import GetHelp from './tabs/GetHelp';

const AppMore = () => {
  const [active, setActive] = useState('profile');

  const activeClass = 'border-b-[2px] border-secondary text-secondary';

  return (
    <AppLayout>
      <h3 className="heading text-txl font-bold">More</h3>
      <p className="text-off">Manage and personalize your experience</p>
      <div className="mt-10">
        <div className="border-b-[1px] border-[#ddd] w-full flex gap-3 mt-5 font-medium heading">
          <span
            className={`p-2 cursor-pointer ${
              active === 'profile' ? activeClass : 'text-oslo'
            }`}
            onClick={() => setActive('profile')}
          >
            Profile
          </span>
          <span
            className={`p-2 cursor-pointer ${
              active === 'security' ? activeClass : 'text-oslo'
            }`}
            onClick={() => setActive('security')}
          >
            Security
          </span>
          <span
            className={`p-2 cursor-pointer ${
              active === 'help' ? activeClass : 'text-oslo'
            }`}
            onClick={() => setActive('help')}
          >
            Get Help
          </span>
        </div>
        <div className="mt-10">
          {active === 'profile' && <Profile />}
          {active === 'security' && <Security />}
          {active === 'help' && <GetHelp />}
        </div>
      </div>
    </AppLayout>
  );
};

export default AppMore;
