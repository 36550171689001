import React, { ChangeEvent, useEffect, useState } from 'react';
import { useGetBusinessData } from '../../../../../hooks/api/business/profile/useGetBusinessData';
import { useUpdatePriceLimit } from '../../../../../hooks/api/business/profile/useUpdatePriceLimit';

const PriceLimits = () => {
  const { data } = useGetBusinessData();

  const [payload, setPayload] = useState({
    min: '',
    max: '',
  });
  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);

  const { mutate, isLoading } = useUpdatePriceLimit(payload);

  useEffect(() => {
    if (data) {
      setPayload({
        min: data?.data?.priceRanges?.min,
        max: data?.data?.priceRanges?.max,
      });
    }
  }, [data]);

  useEffect(() => {
    const { min, max } = payload;
    const isFormValid = max && min;
    if (isFormValid) {
      setIsSubmitEnabled(true);
    } else setIsSubmitEnabled(false);
  }, [payload]);

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;

    setPayload((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    mutate();
  };
  return (
    <div>
      <form onSubmit={handleSubmit} className="mt-10">
        <div className="grid md:grid-cols-2 grid-cols-1 gap-4">
          <div className="mb-4">
            <label htmlFor="min" className="block heading text-tmd mb-3">
              Minimum Amount
            </label>
            {/* <p className="mb-3">The company's registration number</p> */}
            <input
              type="number"
              name="min"
              placeholder=""
              autoComplete="off"
              value={payload.min}
              onChange={handleChange}
              className="w-full border-oslo border-[1px] p-3 rounded-md"
            />
          </div>

          <div className="mb-4">
            <label htmlFor="max" className="block heading text-tmd mb-3">
              Maximum Amount
            </label>
            {/* <p className="mb-3">The company's name</p> */}
            <input
              type="number"
              name="max"
              placeholder=""
              value={payload.max}
              autoComplete="off"
              onChange={handleChange}
              className="w-full border-oslo border-[1px] p-3 rounded-md"
            />
          </div>
        </div>

        <button
          type="submit"
          className={`bg-tertiary p-3 rounded-md text-center text-white font-medium cursor-pointer ${
            isSubmitEnabled === false
              ? 'opacity-50 cursor-not-allowed'
              : 'cursor-pointer'
          } `}
          disabled={isLoading || isSubmitEnabled === false}
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default PriceLimits;
