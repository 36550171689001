import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import VerificationWrapper from './VerificationWrapper';
import { useSubmitCompanyAccountDetails } from '../../../../hooks/api/business/Onboarding/useSubmitCompanyAccountDetails';
import { useGetBanks } from '../../../../hooks/api/business/Onboarding/useGetBanks';
import { useVerifyBankAccount } from '../../../../hooks/api/business/Onboarding/useVerifyBankAccount';

const CompanyAccountDetails = () => {
  const [accountNumber, setAccountNumber] = useState('');
  const [bankCode, setBankCode] = useState('');
  // const [isValidated, setIsValidated] = useState(false);

  // const { data } = useGetBanks();

  // const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
  //   e.preventDefault();
  //   mutateAsync();
  // };

  // Step 1 - FETCH BANKS
  const { data } = useGetBanks();

  // Step 2 - VALIDATE ACCOUNT AND BANK
  const {
    mutateAsync,
    isLoading: submitting,
    data: status,
  } = useVerifyBankAccount({
    accountNumber,
    bankCode,
  });

  // Step 3 - SEND TO DB
  // const { mutate, isLoading: submitting } = useSubmitCompanyAccountDetails({
  //   accountNumber,
  // });

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    mutateAsync();
  };

  return (
    <VerificationWrapper icon="" title="Company Account Details">
      <form onSubmit={handleSubmit}>
        <h3 className="mt-4">
          This is the account where all gift card sales would be paid to
        </h3>
        <div className="mb-4 flex md:flex-row flex-col gap-3 mt-4">
          <div className="md:w-6/12 w-full">
            <label htmlFor="bank" className="mb-3 block heading text-tmd">
              Select Bank
            </label>

            <select
              value={bankCode}
              onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                setBankCode(e.target.value)
              }
              className="w-full border-oslo border-[1px] p-3 rounded-md"
            >
              <option value="" disabled>
                Select a bank
              </option>
              {data &&
                data?.data.map(
                  ({
                    id,
                    name,
                    code,
                  }: {
                    id: string;
                    name: string;
                    code: string;
                  }) => (
                    <option key={id} value={code}>
                      {name}
                    </option>
                  )
                )}
            </select>
          </div>
          <div
            className={`md:w-6/12 w-full ${bankCode === '' && 'opacity-70'}`}
          >
            <label htmlFor="accountNumber">Account Number</label>
            <input
              type="text"
              name="accountNumber"
              disabled={bankCode === ''}
              maxLength={10}
              autoCorrect="off"
              className={`border-oslo border-[1px] p-3 rounded-md w-full mt-3 ${
                bankCode === '' && 'opacity-50'
              }`}
              value={accountNumber}
              onChange={(e) => setAccountNumber(e.target.value)}
            />
          </div>
        </div>
        {status && (
          <div className="my-2 text-tertiary flex gap-2 font-medium">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="size-6"
            >
              <path
                fillRule="evenodd"
                d="M8.603 3.799A4.49 4.49 0 0 1 12 2.25c1.357 0 2.573.6 3.397 1.549a4.49 4.49 0 0 1 3.498 1.307 4.491 4.491 0 0 1 1.307 3.497A4.49 4.49 0 0 1 21.75 12a4.49 4.49 0 0 1-1.549 3.397 4.491 4.491 0 0 1-1.307 3.497 4.491 4.491 0 0 1-3.497 1.307A4.49 4.49 0 0 1 12 21.75a4.49 4.49 0 0 1-3.397-1.549 4.49 4.49 0 0 1-3.498-1.306 4.491 4.491 0 0 1-1.307-3.498A4.49 4.49 0 0 1 2.25 12c0-1.357.6-2.573 1.549-3.397a4.49 4.49 0 0 1 1.307-3.497 4.49 4.49 0 0 1 3.497-1.307Zm7.007 6.387a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z"
                clipRule="evenodd"
              />
            </svg>

            {`${status?.data?.account_number} - ${status?.data?.account_name}`}
          </div>
        )}
        <button
          type="submit"
          className={`p-3 text-white bg-tertiary rounded-md text-center font-bold`}
          disabled={submitting}
        >
          {submitting ? 'Submitting...' : 'Submit'}
        </button>
      </form>
    </VerificationWrapper>
  );
};

export default CompanyAccountDetails;
