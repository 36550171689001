import { useQuery } from 'react-query';
import { userAxiosInstance } from '../../../../utils/userAxiosInstance';

export const useGetDataProviderQuery = () => {
  const token = localStorage.getItem('BSUserToken');

  return useQuery({
    queryKey: 'useGetDataProviderQuery',
    queryFn: () => userAxiosInstance.get('/bills/data').then((res) => res.data),
    onSuccess: (data) => {
      // console.log(`✅ useGetStoresQuery`);
    },
    onError: (error) => {
      console.log(`❌ useGetStoresQuery`);
    },
    enabled: token ? true : false,
  });
};
