import axios from 'axios';
import toast from 'react-hot-toast';

export const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`,
});

axiosInstance.interceptors.request.use(
  async (config) => {
    try {
      const stringifyToken = localStorage.getItem('BSbusinessToken');
      const tokenParsed = stringifyToken ? JSON.parse(stringifyToken) : null;

      if (tokenParsed && typeof tokenParsed === 'string' && config.headers) {
        config.headers.authorization = `Bearer ${tokenParsed}`;
      }
    } catch (error) {
      console.error('Error parsing token:', error);
      toast.error('Failed to parse authentication token.');
    }
    return config;
  },
  async (error) => {
    toast.error('Request error: ' + error.message);
    console.log('==> REFRESHHH <==', error);
    return Promise.reject(error);
  }
);
