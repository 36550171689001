import axios from 'axios';
import toast from 'react-hot-toast';

export const userAxiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`,
});

userAxiosInstance.interceptors.request.use(
  async (config) => {
    const stringifyToken = localStorage.getItem('BSUserToken');
    const tokenParsed = stringifyToken ? JSON.parse(stringifyToken) : null;

    if (tokenParsed && config.headers) {
      config.headers.authorization = `Bearer ${tokenParsed}`;
    }
    return config;
  },
  async (error) => {
    toast.error(error);
    console.log('==> REFRESHHH <==', error);
    return Promise.reject(error);
  }
);
