import { useMutation } from 'react-query';
import toast from 'react-hot-toast';
import { userAxiosInstance } from '../../../../../utils/userAxiosInstance';

interface Props {
  fullName: string;
  bvnNo: string;
  ninNo: string;
}

export const useTierUpgradeMutation = () => {
  return useMutation(
    (payload: Props) =>
      userAxiosInstance
        .post('/users/upgrade-to-tier-two', payload)
        .then((res: any) => res.data),
    {
      onSuccess: async (data: any) => {
        console.log('success', data);
        // toast('Verification Successful');
      },
      onError: (error: any) => {
        toast(error?.message);
      },
    }
  );
};
