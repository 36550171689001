import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import EmailAndPhoneOTP from './00EmailAndPhoneOTP';
import BasicInformation from './01BasicInformation';
import SocialInformation from './02SocialInformation';
import OpeningHours from './03OpeningHours';
import CompanyLogo from './04CompanyLogo';
import CompanyPhotos from './05CompanyPhotos';
import CompanyAccountDetails from './06CompanyAccountDetails';
import CompanyCACVerification from './07CompanyCACVerification';
import CompanyPriceRange from './08CompanyPriceRange';
import TransactionPin from './09TransactionPin';
import { useGetBusinessData } from '../../../../hooks/api/business/profile/useGetBusinessData';

const VerificationLayout = () => {
  const logo = require('../../../../images/logo.png');

  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.clear();
    navigate('/');
  };

  let options = [
    'Basic Information',
    'Opening Hours',
    'Social Information',
    'Company Logo',
    'Company Photos',
    'Company CAC Verification',
    'Gift Cards Price Range',
    'Set Transaction PIN',
    'Company Account Details',
    'Verify Email and Phone',
  ];

  const { data } = useGetBusinessData();

  return (
    <div className="container mx-auto px-3 mt-4">
      <div>
        <div className="container mx-auto border-b-[1px] border-[#ddd] mt-5 mb-3">
          <span className="flex justify-between items-center">
            <span className="flex items-center text-oslol">
              <Link
                to="/"
                className="mr-[60px] text-[20px] mb-4 font-bold text-tertiary flex gap-3 items-center heading"
              >
                <img src={logo} alt="" className="h-[40px]" />
                BillSpot
              </Link>
            </span>
            <span
              onClick={handleLogout}
              className="font-medium text-red-600 cursor-pointer"
            >
              Logout
            </span>
          </span>
        </div>
      </div>
      <div className="flex md:flex-row flex-col gap-10 mt-[40px]">
        <div className="md:w-4/12 w-full">
          <div className="rounded-md border-[1px] border-[#c8c8c8] overflow-hidden">
            <div className="p-[20px] border-b-[1px] border-[#c8c8c8]">
              <h3 className="font-bold  text-tlg text-black">
                Progress - ({data?.data?.progressCount}/10)
              </h3>

              <div className="mt-3 w-full rounded-[20px] overflow-hidden bg-[#dedede]">
                <div
                  className="p-[5px] bg-yellow rounded-[20px]"
                  style={{
                    width: `${(data?.data?.progressCount / 10) * 100}%`,
                  }}
                ></div>
              </div>
              <p className="text-[#3d3d3d] mt-3">
                Welcome {data?.data.email}, Complete your profile to get access
                to your Business Dashboard{' '}
              </p>
            </div>
            <div className="p-[20px] flex gap-[10px] overflow-x-scroll md:flex-wrap no-scrollbar whitespace-nowrap heading mb-4 text-[13px]">
              {options.map((item, index) => (
                <div
                  key={index}
                  className={`p-3 rounded-[15px] border-[1px] flex gap-2 items-center ${
                    data?.data?.progressCount > index
                      ? 'bg-[#dedede] text-[#3d3d3d] border-[1px] opacity-50'
                      : 'text-[#3d3d3d] border-[#c8c8c8]'
                  }`}
                >
                  {data?.data?.progressCount > index && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="size-4"
                    >
                      <path
                        fillRule="evenodd"
                        d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z"
                        clipRule="evenodd"
                      />
                    </svg>
                  )}
                  {index + 1}. {item}
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="md:w-8/12 w-full">
          {data?.data?.progressCount === 0 && <BasicInformation />}
          {data?.data?.progressCount === 1 && <OpeningHours />}
          {data?.data?.progressCount === 2 && <SocialInformation />}
          {data?.data?.progressCount === 3 && <CompanyLogo />}
          {data?.data?.progressCount === 4 && <CompanyPhotos />}
          {data?.data?.progressCount === 5 && <CompanyCACVerification />}
          {data?.data?.progressCount === 6 && <CompanyPriceRange />}
          {data?.data?.progressCount === 7 && <TransactionPin />}
          {data?.data?.progressCount === 8 && <CompanyAccountDetails />}
          {data?.data?.progressCount === 9 && <EmailAndPhoneOTP />}
        </div>
      </div>
    </div>
  );
};

export default VerificationLayout;
