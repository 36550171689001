import { useMutation } from 'react-query';
import toast from 'react-hot-toast';
import { axiosInstance } from '../../../../utils/axiosInstance';
import { useGetBusinessData } from '../profile/useGetBusinessData';

export const useSubmitAccount = () => {
  const { handleRefresh } = useGetBusinessData();

  return useMutation(
    ({ accountNumber }: { accountNumber: string }) =>
      axiosInstance
        .post('/company/create-set-account-number', {
          accountNumber: accountNumber,
        })
        .then((res: any) => res.data),
    {
      onSuccess: async (data) => {
        console.log('✅ useUpdateBasicInfo');

        // getUpdatedDetails();
        handleRefresh();
      },
      onError: (error: any) => {
        console.log('❌ useUpdateBasicInfo ');
        toast(error?.response?.data?.message);
      },
    }
  );
};
