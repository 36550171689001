import { useState } from 'react';
import { Link } from 'react-router-dom';

const NavBar = () => {
  const [isSidebarOpen, setSidebarOpen] = useState(false);

  const logo = require('../images/logo.png');

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };
  return (
    <div>
      <div className="bg-white py-[10px] px-3">
        <nav className="flex justify-between items-center max-w-[1250px] mx-auto">
          <span className="flex">
            <Link
              to="/"
              className="mr-[60px] text-[20px] font-bold flex gap-3 items-center heading"
            >
              <img src={logo} alt="BillSpot Logo" className="h-[40px]" />
              BillSpot
            </Link>
          </span>
          {/* Menu button */}
          <button
            className="gap-2 items-center md:hidden block font-medium cursor-pointer"
            onClick={toggleSidebar}
          >
            <div className="flex bg-tertiary text-white py-3 px-6 rounded-full shadow-md">
              Get Started
            </div>
          </button>

          {isSidebarOpen && (
            <div
              className="fixed inset-0 z-40 bg-black bg-opacity-50"
              onClick={toggleSidebar}
            />
          )}
          <div
            className={`fixed top-0 left-0 h-full w-[90%] bg-white z-50 transform transition-transform ${
              isSidebarOpen ? 'translate-x-0' : '-translate-x-full'
            }`}
          >
            <button
              className="absolute top-4 right-4 text-black"
              onClick={toggleSidebar}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18 18 6M6 6l12 12"
                />
              </svg>
            </button>
            <ul className="mt-[100px] text-black">
              <li className="p-4 font-medium">
                <Link to="/business/register">For Business</Link>
              </li>
              <li className="p-4 font-medium mb-5">
                <Link to="/business/register">Join as an Ambassador</Link>
              </li>
              {/* <li className="p-4 ">
                <Link to="/app/register"></Link>
              </li> */}
              <Link
                to="/app/register"
                className="mx-4 p-4 mt-[50px] text-white bg-tertiary rounded-md text-center w-full font-bold"
              >
                Pay Bills with BillSpot
              </Link>
            </ul>
          </div>

          {/* Desktop Links */}
          <span className="md:flex hidden gap-10 items-center">
            <Link
              to="/business"
              className="heading md:mt-0 mt-4 manrope500 flex items-center gap-3 text-tertiary font-medium cursor-pointer ease-in-out duration-300 hover:translate-y-[4px]"
            >
              For Business
            </Link>
            <Link
              to="/ambassadors/"
              className="heading md:mt-0 mt-4 manrope500 flex items-center gap-3 text-tertiary font-medium cursor-pointer ease-in-out duration-300 hover:translate-y-[4px]"
            >
              For Ambassadors
            </Link>

            <Link
              to="/app/register"
              className="heading shadow-md md:mt-0 mt-4 manrope500 flex items-center gap-3 bg-black text-white font-bold rounded-full py-2 px-5 cursor-pointer hover:shadow-md ease-in-out duration-300 hover:translate-y-[4px]"
            >
              Get Started
            </Link>
          </span>
        </nav>
      </div>
    </div>
  );
};

export default NavBar;
