import { useMutation } from 'react-query';
import toast from 'react-hot-toast';
import { axiosInstance } from '../../../../utils/axiosInstance';

export const useUpdateOpeningHoursData = (businessHours: any) => {
  return useMutation(
    () =>
      axiosInstance
        .patch('company/profile/update-opening-hours', businessHours)
        .then((res: any) => res.data),
    {
      onSuccess: async () => {
        console.log('✅ useUpdateOpeningHoursData');
      },
      onError: (error: any) => {
        console.log('❌ useUpdateOpeningHoursData ');
        toast(error?.response?.data?.message);
      },
    }
  );
};
