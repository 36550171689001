import { useMutation } from 'react-query';
import toast from 'react-hot-toast';
import { axiosInstance } from '../../../../utils/axiosInstance';
import { getUpdatedDetails } from '../getUpdatedDetails';
import { useGetBusinessData } from '../profile/useGetBusinessData';

export const useSubmitCompanyLogo = (medium: File | null) => {
  const { handleRefresh } = useGetBusinessData();

  return useMutation(
    async () => {
      if (!medium) throw new Error('No file selected');

      const formData = new FormData();
      formData.append('logo', medium);

      const response = await axiosInstance.post(
        'company/create-upload-logo',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      return response.data;
    },
    {
      onSuccess: () => {
        console.log('✅ useSubmitCompanyLogo');
        toast.success('Submitted Successfully');
        getUpdatedDetails();
        handleRefresh();
      },
      onError: (error: any) => {
        console.log('❌ useSubmitCompanyLogo');
        toast.error(
          error?.response?.data?.message || 'Error, please try again'
        );
      },
    }
  );
};
