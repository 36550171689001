import { useQuery } from 'react-query';
import { userAxiosInstance } from '../../../../../utils/userAxiosInstance';
import { apiConfig } from '../../../../../constants/apiConfig';

export const useGetWalletBalanceQuery = () => {
  const token = localStorage.getItem('BSUserToken');

  return useQuery({
    queryKey: 'walletbalance',
    queryFn: () =>
      userAxiosInstance.get(`${apiConfig.WALLET}`).then((res) => res.data),
    onSuccess: (data) => {
      console.log(`✅ useGetWalletBalanceQuery`);
    },
    onError: (error) => {
      console.log(`❌ useGetWalletBalanceQuery`);
    },
    enabled: token ? true : false,
  });
};
