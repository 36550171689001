import React, { useEffect, useState } from 'react';
import { useGetDataProviderQuery } from '../../../../../../../hooks/api/app/Data/useGetDataProviderQuery';
import { useGetDataPlanQuery } from '../../../../../../../hooks/api/app/Data/useGetDataPlanQuery';
import { useKeypad } from '../../../../../../../contexts/KeypadContext';
import KeyPad from '../../../../../../../components/KeyPad';
import { usePurchaseDataMutation } from '../../../../../../../hooks/api/app/Data/usePurchaseDataMutation';
import { useGetElectricityProviderQuery } from '../../../../../../../hooks/api/app/Electricity/useGetElectricityProviderQuery';
import { useValidateMeeterDetails } from '../../../../../../../hooks/api/app/Electricity/useValidateMeeterDetails';
import { usePurchaseElectricityMutation } from '../../../../../../../hooks/api/app/Electricity/usePurchaseElectricityMutation';

type Props = {};

const ElectricityBills = (props: Props) => {
  const [payload, setPayload] = useState({
    serviceType: '',
    accountNumber: '',
    amount: '',
    transactionPin: '',
  });

  const { data } = useGetElectricityProviderQuery();

  const { mutate, data: meterNumber, isSuccess } = useValidateMeeterDetails();
  const { mutate: buyPower } = usePurchaseElectricityMutation(setPayload);

  const { toggleKeypad, isKeypadOpen } = useKeypad();

  useEffect(() => {
    let newPayload = {
      serviceType: payload?.serviceType,
      accountNumber: payload?.accountNumber,
    };
    if (payload?.serviceType !== '' && payload?.accountNumber.length === 11) {
      mutate(newPayload);
    }
  }, [payload?.accountNumber, payload?.serviceType, mutate]);

  const handleSelectNetwork = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setPayload({ ...payload, serviceType: e.target.value });
  };

  const handleSubmit = () => {
    const newPayload = { ...payload, amount: +payload?.amount };
    buyPower(newPayload);
  };

  return (
    <div>
      {/* <p className="text-[10px]">{JSON.stringify(payload)}</p> */}
      {/* <p className="text-[10px]">{JSON.stringify(meterNumber)}</p> */}
      <div className="mb-4">
        <label htmlFor="network" className="mb-1 block heading text-tmd">
          Select Provider
        </label>
        <select
          name="network"
          id="network"
          className="w-full border-oslo border-[1px] p-3 rounded-md"
          onChange={handleSelectNetwork}
        >
          <option value="">Select Option</option>
          {data &&
            data?.data?.map((datum: any) => (
              <option value={datum.service_type} key={datum.name}>
                {datum.name}
              </option>
            ))}
        </select>
      </div>
      {payload?.serviceType !== '' && (
        <div className="mb-4">
          <label
            htmlFor="accountNumber"
            className="mb-1 block heading text-tmd"
          >
            Meter Number (04042404139)
          </label>
          <input
            type="number"
            name="accountNumber"
            // maxLength={13}
            autoCorrect="none"
            className="w-full border-oslo border-[1px] p-3 rounded-md"
            value={payload.accountNumber}
            onChange={(e) =>
              setPayload({ ...payload, accountNumber: e.target.value })
            }
          />
        </div>
      )}
      {meterNumber && (
        <p className="text-tertiary font-medium mb-3 flex gap-2 items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="size-6"
          >
            <path
              fillRule="evenodd"
              d="M8.603 3.799A4.49 4.49 0 0 1 12 2.25c1.357 0 2.573.6 3.397 1.549a4.49 4.49 0 0 1 3.498 1.307 4.491 4.491 0 0 1 1.307 3.497A4.49 4.49 0 0 1 21.75 12a4.49 4.49 0 0 1-1.549 3.397 4.491 4.491 0 0 1-1.307 3.497 4.491 4.491 0 0 1-3.497 1.307A4.49 4.49 0 0 1 12 21.75a4.49 4.49 0 0 1-3.397-1.549 4.49 4.49 0 0 1-3.498-1.306 4.491 4.491 0 0 1-1.307-3.498A4.49 4.49 0 0 1 2.25 12c0-1.357.6-2.573 1.549-3.397a4.49 4.49 0 0 1 1.307-3.497 4.49 4.49 0 0 1 3.497-1.307Zm7.007 6.387a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z"
              clipRule="evenodd"
            />
          </svg>
          {meterNumber?.data}
        </p>
      )}
      {isSuccess && (
        <>
          <div className="mb-4">
            <label htmlFor="amount" className="mb-1 block heading text-tmd">
              Amount
            </label>
            <input
              type="text"
              name="amount"
              autoCorrect="none"
              className="w-full border-oslo border-[1px] p-3 rounded-md"
              value={payload.amount}
              onChange={(e) =>
                setPayload({ ...payload, amount: e.target.value })
              }
            />
          </div>
          <button
            onClick={toggleKeypad}
            className="p-3 text-white bg-tertiary rounded-md text-center w-full font-bold"
            disabled={
              !payload.amount || !payload.accountNumber || !payload.serviceType
            }
          >
            Submit
          </button>
        </>
      )}

      <KeyPad
        isKeypadOpen={isKeypadOpen}
        toggleKeypad={toggleKeypad}
        payload={payload}
        setPayload={setPayload}
        handleSubmit={handleSubmit}
      />
    </div>
  );
};

export default ElectricityBills;
