import { useMutation, useQueryClient } from 'react-query';
import { userAxiosInstance } from '../../../../utils/userAxiosInstance';
import { useKeypad } from '../../../../contexts/KeypadContext';
import toast from 'react-hot-toast';
import { useSidebar } from '../../../../contexts/SidebarContext';

export const usePurchaseDataMutation = (setPayload: any) => {
  const { toggleKeypad } = useKeypad();
  const { closeUtilitySidebar } = useSidebar();
  const queryClient = useQueryClient();

  return useMutation(
    (payload: any) =>
      userAxiosInstance
        .post('/bills/data', payload)
        .then((res: any) => res.data),
    {
      onSuccess: async (data: any) => {
        setPayload({
          phone: '',
          serviceType: '',
          amount: '',
          dataCode: '',
        });
        toggleKeypad();
        closeUtilitySidebar();
        toast(data?.data?.transactionMessage);
        queryClient.invalidateQueries('walletbalance');
      },
      onError: (error: any) => {},
    }
  );
};
