import React from 'react';
import BusinessDashboardLayout from '../components/Layout/BusinessDashboardLayout';
import BusinessVerificationLayout from '../components/Layout/BusinessVerificationLayout';

type Props = {};

const BussinessProfile = (props: Props) => {
  return (
    <BusinessDashboardLayout>
      <div className="">
        <div className="heading text-txs font-medium mt-10">Management</div>
        <p className="text-oslol mb-4">Manage your business Profile here</p>
        <BusinessVerificationLayout />
      </div>
    </BusinessDashboardLayout>
  );
};

export default BussinessProfile;
