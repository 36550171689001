import { ChangeEvent, useState } from 'react';
import Sidebar from '../../../../components/SideBar';
import { useSidebar } from '../../../../contexts/SidebarContext';
import {
  useConfirmResetPin,
  useResetPin,
} from '../../../../hooks/api/app/auth/useResetTransactionPin';
import { InVisible, Visible } from '../../../../components/Icons';

const Security = () => {
  const { isSidebarOpen, closeSidebar, openSidebar } = useSidebar();
  const [email, setEmail] = useState('');
  const [payload, setPayload] = useState({
    email: '',
    token: '',
    password: '',
  });

  const [level, setLevel] = useState(0);
  const [passwordVisibility, setPasswordVisibility] = useState(false);

  const { mutateAsync, isLoading } = useResetPin(setLevel);
  const { mutate, isLoading: finalizing } = useConfirmResetPin();

  const handleSubmitEmail = () => {
    mutateAsync(email);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setPayload((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const isSecondButtonDisabled =
    !payload.email || !payload.token || !payload.password;

  const handleSubmit = () => {
    mutate(payload);
  };

  const handleClose = () => {
    closeSidebar();
    setLevel(0);
    setEmail('');
    setPayload({
      email: '',
      token: '',
      password: '',
    });
  };

  return (
    <div>
      <div className="flex gap-[20px]">
        <div className="w-[450px] flex flex-col gap-3">
          <div className="p-3 bg-water rounded-md">
            <div className="heading font-medium"> Transaction Pin</div>
            <p className="text-oslo">Change your transaction pin</p>
            <button
              onClick={() => {
                openSidebar();
              }}
              className="rounded-md bg-tertiary p-3 text-white text-tsm mt-4 font-medium"
            >
              Change Pin
            </button>
          </div>
        </div>
      </div>
      <Sidebar
        title={'Reset Transaction PIN'}
        subtitle={'Complete the process to reset yout transaction pin'}
        isOpen={isSidebarOpen}
        onClose={() => {
          handleClose();
        }}
      >
        {level === 0 ? (
          <div className="">
            <div className="mb-4">
              <label htmlFor="email" className="mb-3 block heading text-tmd">
                Email
              </label>
              <input
                type="email"
                name="email"
                placeholder=""
                disabled={isLoading}
                autoCorrect="none"
                className="w-full border-oslo border-[1px] p-3 rounded-md"
                value={email}
                onChange={(e) => setEmail(e?.target?.value)}
              />
            </div>
            <button
              type="submit"
              className="p-3 text-white bg-tertiary rounded-md text-center w-full font-bold"
              disabled={isLoading}
              onClick={handleSubmitEmail}
            >
              {isLoading ? 'Submitting...' : 'Submit'}
            </button>
          </div>
        ) : (
          <div>
            <div className="">
              <div className="mb-4">
                <label htmlFor="email" className="mb-3 block heading text-tmd">
                  Email
                </label>
                <input
                  type="email"
                  name="email"
                  placeholder=""
                  disabled={finalizing}
                  autoCorrect="none"
                  className="w-full border-oslo border-[1px] p-3 rounded-md"
                  value={payload.email}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-4">
                <label htmlFor="token" className="mb-3 block heading text-tmd">
                  Token
                </label>
                <input
                  type="number"
                  name="token"
                  placeholder=""
                  disabled={finalizing}
                  autoCorrect="none"
                  className="w-full border-oslo border-[1px] p-3 rounded-md"
                  value={payload.token}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-4">
                <label htmlFor="password" className="mb-3 block heading">
                  Password
                </label>
                <div className="relative">
                  <input
                    type={passwordVisibility ? 'text' : 'password'}
                    name="password"
                    placeholder=""
                    disabled={finalizing}
                    autoCorrect="none"
                    className="w-full border-oslo border-[1px] p-3 rounded-md"
                    value={payload.password}
                    onChange={handleChange}
                  />
                  <span
                    onClick={() => setPasswordVisibility(!passwordVisibility)}
                    className="cursor-pointer absolute right-5 top-3"
                  >
                    {passwordVisibility ? <InVisible /> : <Visible />}
                  </span>
                </div>
              </div>
              <button
                type="submit"
                className="p-3 text-white bg-tertiary rounded-md text-center w-full font-bold"
                disabled={finalizing || isSecondButtonDisabled}
                onClick={handleSubmit}
              >
                {isLoading ? 'Submitting...' : 'Submit'}
              </button>
            </div>
          </div>
        )}
      </Sidebar>
    </div>
  );
};

export default Security;
