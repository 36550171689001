import BusinessAuthWrapper from './BusinessAuthWrapper';
import BusinessSignup from './BusinessSignup';

const Register = () => {
  return (
    <BusinessAuthWrapper>
      <BusinessSignup />
    </BusinessAuthWrapper>
  );
};

export default Register;
