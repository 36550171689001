import { useQuery } from 'react-query';
import { userAxiosInstance } from '../../../../utils/userAxiosInstance';

export const useGetStoresQuery = (category?: string) => {
  const token = localStorage.getItem('BSUserToken');

  return useQuery({
    queryKey: ['useGetStoresQuery', category],
    queryFn: () => {
      const url = category
        ? `/giftcards/markets?category=${encodeURIComponent(category)}`
        : '/giftcards/markets';
      return userAxiosInstance.get(url).then((res) => res.data);
    },
    onSuccess: () => {
      console.log(`✅ useGetStoresQuery`);
    },
    onError: () => {
      console.log(`❌ useGetStoresQuery`);
    },
    enabled: token ? true : false,
  });
};
