import { AiFillApple } from 'react-icons/ai';
import { IoLogoGooglePlaystore } from 'react-icons/io5';
import { PiGlobeBold } from 'react-icons/pi';
import Footer from '../components/Footer';
import DownloadApp from '../components/DownloadApp';
import NavBar from '../components/NavBar';
import Faq from '../components/Faq';
import ScrollToTop from '../hooks/ScrollToTop';

type Props = {};

const Ambassadors = () => {
  const heroImg = require('../images/image.png');

  return (
    <ScrollToTop>
      <div className="bg-[#f1f4ff]">
        <section className="container max-w-[1250px] mx-auto">
          <div className="md:pt-[100px] md:pb-[200px] pb-[60px] flex md:flex-row flex-col items-center gap-4 relative">
            <div className="md:w-6/12 w-full md:order-first order-last">
              <h1 className="md:text-dlg text-dsm heading font-extrabold text-secondary">
                Becoming a <br /> BillSpot Ambassador
              </h1>
              <div className="mt-5 text-off text-tlg md:w-[80%] w-11/12">
                Join BillSpot’s Ambassadorship Program! Promote BillSpot to your
                followers and earn passive income. Share the convenience of
                seamless bill payments while benefiting financially. <br />
              </div>
              <div className="md:mt-[50px] mt-[20px] text-secondary text-lg">
                Join other BillSpotters
              </div>
              <div className="flex gap-4 mt-[20px] order-first md:order-last">
                <span className="md:mt-0 mt-4 manrope500 shadow-md flex items-center gap-3 bg-tertiary text-white rounded-full py-4 px-4 cursor-pointer ease-in-out duration-300 hover:shadow-md hover:bg-tertiary hover:text-yellow hover:translate-y-[4px]">
                  <a href="https://apple.com" rel="noreferrer" target="_blank">
                    <AiFillApple className="text-[30px]" />
                  </a>
                </span>
                <span className="md:mt-0 mt-4 manrope500 shadow-md flex items-center gap-3 bg-tertiary text-white rounded-full py-4 px-4 cursor-pointer ease-in-out duration-300 hover:shadow-md hover:bg-tertiary hover:text-yellow hover:translate-y-[4px]">
                  <a
                    href="https://play.google.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <IoLogoGooglePlaystore className="text-[30px]" />
                  </a>
                </span>
                <span className="md:mt-0 mt-4 manrope500 shadow-md flex items-center gap-3 bg-tertiary text-white rounded-full py-4 px-4 cursor-pointer ease-in-out duration-300 hover:shadow-md hover:bg-tertiary hover:text-yellow hover:translate-y-[4px]">
                  <PiGlobeBold className="text-[30px]" />
                </span>
              </div>
            </div>
            <div className="md:w-6/12 w-full md:mt-0 mt-10">
              <img
                src={heroImg}
                alt="placeholder"
                className="md:h-[400px] h-[250px] w-full md:rounded-[30px] rounded-md"
              />
            </div>
            <span className="absolute bottom-0 right-0"></span>
          </div>
        </section>
      </div>
      <div className="bg- water">
        <section className="container max-w-[1250px] mx-auto py-[100px]">
          <h3 className="heading text-center text-dsm font-extrabold text-secondary">
            Perks of becoming <br />
            an Ambassador
          </h3>

          <div className="flex flex-wrap flex-row md:justify-center gap-3 my-10">
            <div className="heading font-bold w-6/12 md:w-auto md:flex gap-4 p-[40px] hover:cursor-pointer hover:bg-secondary hover:text-white bg-water md:rounded-full rounded-[20px] ease-in-out duration-300">
              Financial Incentives
            </div>
            <div className="heading font-bold w-full md:w-auto md:flex gap-4 p-[40px] hover:cursor-pointer hover:bg-secondary hover:text-white bg-water md:rounded-full rounded-[20px] ease-in-out duration-300">
              Exclusive Access
            </div>
            <div className="heading font-bold w-full md:w-auto md:flex gap-4 p-[40px] hover:cursor-pointer hover:bg-secondary hover:text-white bg-water md:rounded-full rounded-[20px] ease-in-out duration-300">
              Professional Development
            </div>
            <div className="heading font-bold w-full md:w-auto md:flex gap-4 p-[40px] hover:cursor-pointer hover:bg-secondary hover:text-white bg-water md:rounded-full rounded-[20px] ease-in-out duration-300">
              Brand Visibility
            </div>
            <div className="heading font-bold w-full md:w-auto md:flex gap-4 p-[40px] hover:cursor-pointer hover:bg-secondary hover:text-white bg-water md:rounded-full rounded-[20px] ease-in-out duration-300">
              Merchandise and Swag
            </div>
            <div className="heading font-bold w-full md:w-auto md:flex gap-4 p-[40px] hover:cursor-pointer hover:bg-secondary hover:text-white bg-water md:rounded-full rounded-[20px] ease-in-out duration-300">
              Recognition and Rewards
            </div>
            <div className="heading font-bold w-full md:w-auto md:flex gap-4 p-[40px] hover:cursor-pointer hover:bg-secondary hover:text-white bg-water md:rounded-full rounded-[20px] ease-in-out duration-300">
              Community Engagement
            </div>
            <div className="heading font-bold w-full md:w-auto md:flex gap-4 p-[40px] hover:cursor-pointer hover:bg-secondary hover:text-white bg-water md:rounded-full rounded-[20px] ease-in-out duration-300">
              Career Advancement
            </div>
          </div>
        </section>
      </div>
      <Faq />
      <DownloadApp />
      <Footer />
    </ScrollToTop>
  );
};

export default Ambassadors;
