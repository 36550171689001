const UserRankingModal = () => {
  const image1 = require('../../images/ranking/1.png');
  const image2 = require('../../images/ranking/2.png');
  const image3 = require('../../images/ranking/3.png');
  const image4 = require('../../images/ranking/4.png');
  const image5 = require('../../images/ranking/5.png');

  return (
    <div>
      <div className="flex gap-4 items-center mb-3">
        <img src={image1} alt="rank" className="h-[70px]" />
        <div className="flex-1">
          <h3 className="heading font-medium">Explorer</h3>
          <p className="text-oslo normal">Transactions less than 100k</p>
        </div>
      </div>
      <div className="flex gap-4 items-center mb-3">
        <img src={image2} alt="rank" className="h-[70px]" />
        <div className="flex-1">
          <h3 className="heading font-medium">Rising Star</h3>
          <p className="text-oslo normal">Transactions over 100k</p>
        </div>
      </div>
      <div className="flex gap-4 items-center mb-3">
        <img src={image3} alt="rank" className="h-[70px]" />
        <div className="flex-1">
          <h3 className="heading font-medium">Go Getter</h3>
          <p className="text-oslo normal">Transactions over 500k</p>
        </div>
      </div>
      <div className="flex gap-4 items-center mb-3">
        <img src={image4} alt="rank" className="h-[70px]" />
        <div className="flex-1">
          <h3 className="heading font-medium">Mr. Money</h3>
          <p className="text-oslo normal">Transactions over 1M</p>
        </div>
      </div>
      <div className="flex gap-4 items-center">
        <img src={image5} alt="rank" className="h-[70px]" />
        <div className="flex-1">
          <h3 className="heading font-medium">Odogwu</h3>
          <p className="text-oslo normal">Transactions over 10M</p>
        </div>
      </div>
    </div>
  );
};

export default UserRankingModal;
