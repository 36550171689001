import React, { useState, ChangeEvent, FormEvent } from 'react';
import { Link } from 'react-router-dom';
import { InVisible, Visible } from '../../../components/Icons';
import { Fade } from 'react-awesome-reveal';
import toast from 'react-hot-toast';
import { useRegisterUser } from '../../../hooks/api/app/auth/useRegisterUser';
import AuthWrapper from './AuthWrapper';
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';

type Props = {};

const AppSignUp: React.FC<Props> = (props) => {
  const [phone, setPhone] = useState('');

  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const [agreement, setAgreemeent] = useState(false);

  const [payload, setPayload] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
  });

  const { mutateAsync: register, isLoading } = useRegisterUser();

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setPayload((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (agreement === false) {
      toast.error(
        'Ensure you fill all the fields and agree to the terms and conditions'
      );
      return;
    }

    const updatedPayload = { ...payload };

    let updatedPayload2 = {
      ...updatedPayload,
      phone: phone,
    };

    console.log('updatedPayload2', updatedPayload2);

    try {
      await register(updatedPayload2);
      // navigate('/app/home');
    } catch (error) {
      console.error('Registration error:', error);
    }
  };

  return (
    <AuthWrapper>
      <Fade duration={1000}>
        <form onSubmit={handleSubmit}>
          <div className="mt-10">
            <div className="flex gap-4">
              <div className="mb-4">
                <label
                  htmlFor="firstName"
                  className="mb-3 block heading text-tmd"
                >
                  First Name
                </label>
                <input
                  type="text"
                  name="firstName"
                  placeholder=""
                  disabled={isLoading}
                  autoCorrect="none"
                  autoComplete="off"
                  className="w-full border-oslo border-[1px] p-3 rounded-md"
                  value={payload.firstName}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="lastName"
                  className="mb-3 block heading text-tmd"
                >
                  Last Name
                </label>
                <input
                  type="text"
                  name="lastName"
                  placeholder=""
                  disabled={isLoading}
                  autoCorrect="none"
                  autoComplete="off"
                  className="w-full border-oslo border-[1px] p-3 rounded-md"
                  value={payload.lastName}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="mb-4">
              <label htmlFor="phone" className="mb-3 block heading text-tmd">
                Phone Number
              </label>
              <PhoneInput
                defaultCountry="ng"
                value={phone}
                onChange={(phone) => setPhone(phone)}
              />
            </div>

            <div className="mb-4">
              <label htmlFor="email" className="mb-3 block heading text-tmd">
                Email Address
              </label>
              <input
                type="email"
                name="email"
                placeholder=""
                disabled={isLoading}
                autoCorrect="none"
                autoComplete="off"
                className="w-full border-oslo border-[1px] p-3 rounded-md"
                value={payload.email}
                onChange={handleChange}
              />
            </div>

            <div className="mb-4">
              <label htmlFor="password" className="mb-3 block heading">
                Password
              </label>
              <div className="relative">
                <input
                  type={passwordVisibility ? 'text' : 'password'}
                  name="password"
                  placeholder=""
                  disabled={isLoading}
                  autoCorrect="none"
                  autoComplete="off"
                  className="w-full border-oslo border-[1px] p-3 rounded-md"
                  value={payload.password}
                  onChange={handleChange}
                />
                <span
                  onClick={() => setPasswordVisibility(!passwordVisibility)}
                  className="cursor-pointer absolute right-5 top-3"
                >
                  {passwordVisibility ? <InVisible /> : <Visible />}
                </span>
              </div>
            </div>

            <div className="mb-4 flex gap-3">
              <input
                type="checkbox"
                onClick={() => setAgreemeent(!agreement)}
              />
              <span>
                I agree to Billpot{' '}
                <Link
                  to="/terms"
                  className="text-tertiary underline underline-offset-[2px]"
                >
                  Terms and Conditions
                </Link>
              </span>
            </div>
            <button
              type="submit"
              className="p-3 text-white bg-tertiary rounded-md text-center w-full font-bold"
              disabled={isLoading}
            >
              {isLoading ? 'Submitting...' : 'Submit'}
            </button>
          </div>
        </form>
      </Fade>
    </AuthWrapper>
  );
};

export default AppSignUp;
