import React, { useEffect, useState } from 'react';
import { useUploadNINForSevisMutation } from '../../../../../../../hooks/api/app/SEVIS/useUploadNINForSevisMutation';
import toast from 'react-hot-toast';
import { useSidebar } from '../../../../../../../contexts/SidebarContext';
import { useGetPricesQuery } from '../../../../../../../hooks/api/app/WES/useGetPricesQuery';
import { useUploadNinNewFunc } from '../../../../../../../hooks/api/app/SEVIS/useUploadNin';
import { useKeypad } from '../../../../../../../contexts/KeypadContext';
import KeyPad from '../../../../../../../components/KeyPad';
import { PhoneInput } from 'react-international-phone';
import { useUploadSevisForSevisMutation } from '../../../../../../../hooks/api/app/SEVIS/useUploadSevisForSevisMutation';

const initialData = {
  firstName: '',
  lastName: '',
  amount: 0,
  email: '',
  sevisDocUrl: '',
  coupon: '',
  phone: '',
  receivedOn: '',
  ninDocUrl:
    'http://res.cloudinary.com/dtoazyfbn/image/upload/v1720540844/billspot-bucket/Screenshot%20from%202024-04-25%2016-26-04.png"',
};

const SevisFee = () => {
  const [formData, setFormData] = useState(initialData);
  const [couponImage, setCouponImage] = useState('');
  const [ninImage, setNinImage] = useState('');
  const [sevisDocument, setSevisDocument] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const { mutateAsync: uploadImageToApi, data } = useUploadNINForSevisMutation({
    couponImage,
  });
  const { mutateAsync: uploadNinToApi, data: ninData } = useUploadNinNewFunc({
    ninImage,
  });
  const { mutateAsync: uploadImageToSevis, data: sevisData } =
    useUploadSevisForSevisMutation({
      sevisDocument,
    });

  const { data: prices } = useGetPricesQuery();
  const [level, setLevel] = useState(0);
  const [agreement, setAgreement] = useState(false);
  const { toggleKeypad, isKeypadOpen } = useKeypad();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const { closeSidebar } = useSidebar();

  const handleFileChange = async (event) => {
    const file = event.target.files?.[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = async () => {
        const imageBase64 = reader.result;
        setCouponImage(imageBase64);

        try {
          await uploadImageToApi(imageBase64);
        } catch (error) {
          console.error('Image upload failed:', error);
          toast.error('Error uploading file');
        }
      };

      reader.readAsDataURL(file);
    }
  };

  const handleNINChange = async (event) => {
    const file = event.target.files?.[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = async () => {
        const imageBase64 = reader.result;
        setNinImage(imageBase64);

        try {
          await uploadNinToApi(imageBase64);
        } catch (error) {
          console.error('Image upload failed:', error);
          toast.error('Error uploading file');
        }
      };

      reader.readAsDataURL(file);
    }
  };

  const handleSevisChange = async (event) => {
    const file = event.target.files?.[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = async () => {
        const imageBase64 = reader.result;
        setSevisDocument(imageBase64);

        try {
          await uploadImageToSevis(imageBase64);
        } catch (error) {
          console.error('Image upload failed:', error);
          toast.error('Error uploading file');
        }
      };

      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    const {
      firstName,
      lastName,
      email,
      phone,
      sevisDocUrl,
      coupon,
      ninDocUrl,
    } = formData;

    setIsButtonDisabled(
      !firstName ||
        !lastName ||
        !email ||
        !phone ||
        !sevisDocUrl ||
        !coupon ||
        !ninDocUrl
    );
  }, [formData]);

  const handleSevisPayment = () => {
    const sevisPaymentHelper = async () => {
      const url = `${process.env.REACT_APP_BASE_URL}sevis/transact`;

      const stringifyToken = localStorage.getItem('BSUserToken');
      const tokenParsed = stringifyToken ? JSON.parse(stringifyToken) : null;

      const currentDateTime = new Date().toISOString();
      const payloadWithTimestamp = {
        ...formData,
        sevisDocUrl: sevisData?.data,
        coupon: data?.data,
        ninDocUrl:
          'http://res.cloudinary.com/dtoazyfbn/image/upload/v1720540844/billspot-bucket/Screenshot%20from%202024-04-25%2016-26-04.png"',
        receivedOn: currentDateTime,
        amount: prices[0]?.sevisAmount,
      };

      console.log('--- payloadWithTimestamp ---', payloadWithTimestamp);

      try {
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${tokenParsed}`,
          },
          body: JSON.stringify(payloadWithTimestamp),
        });

        // if (!response.ok) {
        //   throw new Error('Network response was not ok');
        // }

        await response.json();
        toast.success('Payment successful!');
        setFormData(initialData);
        closeSidebar();
        toggleKeypad();
      } catch (error) {
        console.error('Error with payment:', error);
        toast.error(error?.message);
      }
    };

    sevisPaymentHelper();
  };

  return (
    <div>
      {level === 0 && (
        <div>
          <div>
            <div className="flex gap-3">
              <div className="mb-4">
                <label
                  htmlFor="firstName"
                  className="mb-1 block heading text-tmd"
                >
                  First Name
                </label>
                <input
                  type="text"
                  name="firstName"
                  autoCorrect="off"
                  autoComplete="off"
                  className="w-full border-oslo border-[1px] p-3 rounded-md"
                  value={formData.firstName}
                  onChange={handleInputChange}
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="lastName"
                  className="mb-1 block heading text-tmd"
                >
                  Last Name
                </label>
                <input
                  type="text"
                  name="lastName"
                  autoCorrect="off"
                  autoComplete="off"
                  className="w-full border-oslo border-[1px] p-3 rounded-md"
                  value={formData.lastName}
                  onChange={handleInputChange}
                />
              </div>
            </div>

            <div className="mb-4">
              <label htmlFor="phone" className="mb-1 block heading text-tmd">
                Phone
              </label>
              {/* <input
                type="text"
                name="phone"
                maxLength={11}
                autoCorrect="none"
                className="w-full border-oslo border-[1px] p-5 rounded-md"
                value={formData.phone}
                onChange={handleInputChange}
              /> */}
              <PhoneInput
                defaultCountry="ng"
                value={formData.phone}
                onChange={(phone) => setFormData({ ...formData, phone: phone })}
                // className="w-full border-oslo border-[1px] p-5 rounded-md"
                // onChange={(e: any) =>
                //   setPayload((prevState) => ({
                //     ...prevState,
                //     phone: e?.target?.value,
                //   }))
                // }
              />
            </div>

            <div className="mb-4">
              <label htmlFor="email" className="mb-1 block heading text-tmd">
                Recepient's Email
              </label>
              <input
                type="email"
                name="email"
                autoCorrect="off"
                autoComplete="off"
                className="w-full border-oslo border-[1px] p-3 rounded-md"
                value={formData.email}
                onChange={handleInputChange}
              />
            </div>

            {/* <div className="mb-4">
              <label htmlFor="password" className="mb-1 block heading text-tmd">
                Password
              </label>
              <input
                type="text"
                name="password"
                maxLength={11}
                autoCorrect="none"
                className="w-full border-oslo border-[1px] p-5 rounded-md"
                value={formData.password}
                onChange={handleInputChange}
              />
            </div> */}

            <div className="mb-4">
              <p className="mb-1 block heading text-tmd">
                SEVIS Document (Coupon)
              </p>

              <label className="inline-block px-4 rounded-full py-2 mb-4 text-white bg-tertiary cursor-pointer">
                Select image
                <input
                  name="sevisDocUrl"
                  type="file"
                  accept="image/*"
                  multiple={false}
                  onChange={handleSevisChange}
                  className="mt-2 hidden"
                />
              </label>
              {sevisData && (
                <img
                  src={sevisData?.data}
                  alt="Sevis Document Preview"
                  className="w-full h-[150px] my-[10px] rounded-md"
                />
              )}
            </div>

            {/* <div className="mb-4">
              <p className="mb-1 block heading text-tmd">Coupon Code</p>

              <label
                name="coupon"
                className="inline-block px-4 rounded-full py-2 mb-4 text-white bg-tertiary cursor-pointer"
              >
                Select image
                <input
                  name="coupon"
                  type="file"
                  accept="image/*"
                  multiple={false}
                  onChange={handleFileChange}
                  className="mt-2 hidden"
                />
              </label>
              {data && (
                <img
                  src={data?.data}
                  alt="Coupon Document Preview"
                  className="w-full h-[150px] my-[10px] rounded-md"
                />
              )}
            </div> */}
            {/* <div className="mb-4">
              <p className="mb-1 block heading text-tmd">NIN Document</p>

              <label
                name="ninDocUrl"
                className="inline-block px-4 rounded-full py-2 mb-4 text-white bg-tertiary cursor-pointer"
              >
                Select image
                <input
                  name="ninDocUrl"
                  type="file"
                  accept="image/*"
                  multiple={false}
                  onChange={handleNINChange}
                  className="mt-2 hidden"
                />
              </label>
              {ninData && (
                <img
                  src={ninData?.data}
                  alt="NIN Document Preview"
                  className="w-full h-[150px] my-[10px] rounded-md"
                />
              )}
            </div> */}

            <div className="italic mb-3 text-oslol flex gap-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="size-6 text-red-400"
              >
                <path
                  fillRule="evenodd"
                  d="M9.401 3.003c1.155-2 4.043-2 5.197 0l7.355 12.748c1.154 2-.29 4.5-2.599 4.5H4.645c-2.309 0-3.752-2.5-2.598-4.5L9.4 3.003ZM12 8.25a.75.75 0 0 1 .75.75v3.75a.75.75 0 0 1-1.5 0V9a.75.75 0 0 1 .75-.75Zm0 8.25a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z"
                  clipRule="evenodd"
                />
              </svg>
              Confirm your information before proceeding to payment
            </div>

            <button
              disabled={!isButtonDisabled}
              onClick={() => setLevel(1)}
              className={`p-3 text-white bg-tertiary rounded-md text-center w-full font-bold ${
                isButtonDisabled === false && 'opacity-10'
              }`}
            >
              Submit
            </button>
          </div>
        </div>
      )}
      {level === 1 && (
        <div>
          <span
            className="flex gap-2 items-center cursor-pointer"
            onClick={() => {
              setLevel(0);
              setAgreement(false);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-10 p-3 rounded-full text-black bg-offline"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9 15 3 9m0 0 6-6M3 9h12a6 6 0 0 1 0 12h-3"
              />
            </svg>
            <span>Back</span>
          </span>
          <div className="mt-4 border-[1px] border-[#ddd] rounded-md p-4 mb-4">
            {['firstName', 'lastName', 'email', 'phone'].map((field) => (
              <div className="flex mb-3 flex-col" key={field}>
                <div className="w-4/12 font-medium text-black">
                  {field.charAt(0).toUpperCase() + field.slice(1)}
                </div>
                <div className="flex-1 text-oslol">{formData[field]}</div>
              </div>
            ))}
            {data && (
              <div>
                <h3 className="mb-3 font-medium text-black">Coupon Code</h3>
                <img
                  src={data?.data}
                  alt="Coupon Document Preview"
                  className="w-full h-[150px] my-[10px] rounded-md"
                />
              </div>
            )}
            {ninData && (
              <div>
                <h3 className="mb-3 font-medium text-black">NIN</h3>
                <img
                  src={ninData?.data}
                  alt="NIN Document Preview"
                  className="w-full h-[150px] my-[10px] rounded-md"
                />
              </div>
            )}
            {sevisData && (
              <div>
                <h3 className="mb-3 font-medium text-black">
                  SEVIS Document (Coupon)
                </h3>
                <img
                  src={sevisData?.data}
                  alt="Sevis Document Preview"
                  className="w-full h-[150px] my-[10px] rounded-md"
                />
              </div>
            )}
          </div>
          <div className="mb-4 flex gap-3">
            <input
              type="checkbox"
              checked={agreement}
              onChange={() => setAgreement(!agreement)}
            />
            <span>I have cross-checked the data to be submitted</span>
          </div>
          <button
            onClick={() => setLevel(2)}
            disabled={!agreement}
            className={`p-3 text-white bg-tertiary rounded-md text-center w-full font-bold ${
              !agreement ? 'opacity-50' : ''
            }`}
          >
            Proceed to Checkout
          </button>
          {/* <button
            onClick={toggleKeypad}
            disabled={!agreement}
            className={`p-5 text-white bg-tertiary rounded-md text-center w-full font-bold ${
              !agreement ? 'opacity-50' : ''
            }`}
          >
            Proceed to Checkout
          </button> */}
          <KeyPad
            isKeypadOpen={isKeypadOpen}
            toggleKeypad={toggleKeypad}
            payload={formData}
            setPayload={setFormData}
            handleSubmit={handleSevisPayment}
          />
        </div>
      )}
      {level === 2 && (
        <div>
          <span
            className="flex gap-2 items-center cursor-pointer"
            onClick={() => {
              setLevel(1);
              setAgreement(false);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-10 p-3 rounded-full text-black bg-offline"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9 15 3 9m0 0 6-6M3 9h12a6 6 0 0 1 0 12h-3"
              />
            </svg>
            <span>Back</span>
          </span>
          <div className="border-[1px] border-[#eeeeee] my-[10px] rounded-md">
            <p className="border-b-[1px] border-[#eeeeee] bg-black text-white rounded-t-md font-bold text-center py-[10px]">
              SEVIS FEE BREAKDOWN
            </p>
            <div className="p-[10px]">
              <div className="flex justify-between items-center mb-[15px]">
                <span className="">Subtotal</span>
                <span className="text-[#7b7b7b]">
                  ${prices[0]?.sevisAmount.toLocaleString()}
                </span>
              </div>
              <div className="flex justify-between items-center mb-[15px]">
                <span className="">Discount</span>
                <span className="text-[#7b7b7b]">$0</span>
              </div>
              <div className="flex justify-between items-center mb-[15px]">
                <span className="">Service Charge</span>
                <span className="text-[#7b7b7b]">15%</span>
              </div>
              <div className="flex justify-between items-center mb-[15px]">
                <span className="">VAT (1%)</span>
                <span className="text-[#7b7b7b]">
                  ${0.01 * prices[0]?.sevisAmount}
                </span>
              </div>
              <div className="flex justify-between items-center font-bold border-t-[1px] border-[#eeeeee] pt-[10px]">
                <span className="">Total</span>
                <span className="">
                  $
                  {(
                    prices[0]?.sevisAmount +
                    0.15 * prices[0]?.sevisAmount +
                    0.0 +
                    0.01 * prices[0]?.sevisAmount
                  ).toLocaleString()}
                </span>
              </div>
            </div>
          </div>
          <button
            onClick={toggleKeypad}
            disabled={!agreement}
            className={`p-3 text-white bg-tertiary rounded-md text-center w-full font-bold ${
              !agreement ? 'opacity-50' : ''
            }`}
          >
            Pay
          </button>
          {/* <button
            onClick={toggleKeypad}
            disabled={!agreement}
            className={`p-5 text-white bg-tertiary rounded-md text-center w-full font-bold ${
              !agreement ? 'opacity-50' : ''
            }`}
          >
            Proceed to Checkout
          </button> */}
          <KeyPad
            isKeypadOpen={isKeypadOpen}
            toggleKeypad={toggleKeypad}
            payload={formData}
            setPayload={setFormData}
            handleSubmit={handleSevisPayment}
          />
        </div>
      )}
    </div>
  );
};

export default SevisFee;
