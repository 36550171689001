import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useModal } from '../../../../contexts/ModalContext';
import VerificationWrapper from './VerificationWrapper';
import Modal from '../../../../components/Modal';
import { useVerifyBusiness } from '../../../../hooks/api/business/auth/useVerifyBusiness';
import { useUpdateBasicInfo } from '../../../../hooks/api/business/profile/useUpdateBasicInfo';
import { useSubmitBasicInfo } from '../../../../hooks/api/business/Onboarding/useSubmitBasicInfo';
import { PhoneInput } from 'react-international-phone';

const BasicInformation = () => {
  const [phone, setPhone] = useState('');

  const [payload, setPayload] = useState({
    alternativePhoneNumber: '',
    website: 'https://',
    businessName: '',
    address: '',
    category: '',
    bio: '',
  });
  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);

  let newPayload = { ...payload, alternativePhoneNumber: phone };

  const { mutate, isLoading } = useSubmitBasicInfo(newPayload);

  useEffect(() => {
    const { businessName, address, category, bio } = payload;
    const isFormValid = businessName && address && category && bio;
    if (isFormValid) {
      setIsSubmitEnabled(true);
    } else setIsSubmitEnabled(false);
  }, [payload]);

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;

    setPayload((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    mutate();
  };

  return (
    <VerificationWrapper icon={''} title="Basic Information">
      <form onSubmit={handleSubmit} className="mt-10">
        {/* <p className="text-xs">{JSON.stringify(payload)}</p> */}
        {/* <p className="text-xs">isFormValid {JSON.stringify(isSubmitEnabled)}</p> */}
        <div className="grid md:grid-cols-2 grid-cols-1 gap-4">
          <div className="mb-4">
            <label htmlFor="category" className="mb-3 block heading text-tmd">
              Category
            </label>
            <select
              name="category"
              value={payload.category}
              onChange={handleChange}
              className="w-full border-oslo border-[1px] p-3 rounded-md"
            >
              <option value="">Select a category</option>
              <option value="Food & Drink">Food & Drink</option>
              <option value="Health care & fitness">
                Health care & fitness
              </option>
              <option value="Beauty & personal care">
                Beauty & personal care
              </option>
              <option value="Professional Services">
                Professional Services
              </option>
              <option value="Charities">Charities</option>
              <option value="Education">Education</option>
              <option value="Education">Membership</option>
              <option value="Retail">Retail</option>
              <option value="Leisure & entertainment">
                Leisure & entertainment
              </option>
              <option value="Home & repair">Home & repair</option>
              <option value="Transportation">Transportation</option>
              <option value="Others">Others</option>
            </select>
          </div>
          <div className="mb-4">
            <label
              htmlFor="alternativePhoneNumber"
              className="mb-3 block heading text-tmd"
            >
              Alternative Phone Number (optional)
            </label>
            {/* <input
              type="tel"
              name="alternativePhoneNumber"
              placeholder="1234567890"
              maxLength={11}
              autoComplete="off"
              value={payload.alternativePhoneNumber}
              onChange={handleChange}
              className="w-full border-oslo border-[1px] p-5 rounded-md"
            /> */}
            <PhoneInput
              defaultCountry="ng"
              value={phone}
              onChange={(phone) => setPhone(phone)}
            />
          </div>

          <div className="mb-4">
            <label htmlFor="website" className="mb-3 block heading text-tmd">
              Website URL
            </label>
            <input
              type="url"
              name="website"
              placeholder="https://www.billspot.app"
              value={payload.website}
              autoComplete="off"
              onChange={handleChange}
              className="w-full border-oslo border-[1px] p-3 rounded-md"
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="businessName"
              className="mb-3 block heading text-tmd"
            >
              Business Name
            </label>
            <input
              type="text"
              name="businessName"
              placeholder="Business Name"
              value={payload.businessName}
              onChange={handleChange}
              className="w-full border-oslo border-[1px] p-3 rounded-md"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="address" className="mb-3 block heading text-tmd">
              Address / Head office
            </label>
            <textarea
              name="address"
              placeholder="Address"
              autoComplete="off"
              value={payload.address}
              onChange={handleChange}
              className="h-[150px] resize-none w-full border-oslo border-[1px] p-3 rounded-md"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="bio" className="mb-3 block heading text-tmd">
              <div className="flex justify-between">
                <span>Bio</span>
                <span>{payload.bio.length}/300</span>
              </div>
            </label>
            <textarea
              name="bio"
              placeholder="Bio"
              value={payload.bio}
              autoComplete="off"
              onChange={handleChange}
              maxLength={300}
              className="h-[150px] resize-none w-full border-oslo border-[1px] p-3 rounded-md"
            />
          </div>
        </div>

        <button
          type="submit"
          className={`bg-tertiary p-3 rounded-md text-center text-white font-medium cursor-pointer ${
            isSubmitEnabled === false
              ? 'opacity-50 cursor-not-allowed'
              : 'cursor-pointer'
          } `}
          disabled={isLoading || isSubmitEnabled === false}
        >
          Submit
        </button>
      </form>
    </VerificationWrapper>
  );
};

export default BasicInformation;
