import { useMutation } from 'react-query';
import { apiConfig } from '../../../../constants/apiConfig';
import { useNavigate } from 'react-router-dom';
import { axiosInstance } from '../../../../utils/axiosInstance';

interface Props {
  email: string;
  password: string;
}

export const useSigninUser = (payload: Props) => {
  const navigate = useNavigate();

  return useMutation(
    () =>
      axiosInstance
        .post(`${apiConfig.LOGIN_USER}`, payload)
        .then((res: any) => res.data),
    {
      onSuccess: async (data: any) => {
        // Store tokens in localStorage
        localStorage.setItem('BSUserToken', JSON.stringify(data?.data?.token));
        localStorage.setItem(
          'BSUserRefreshToken',
          JSON.stringify(data?.data?.refreshToken)
        );
        navigate('/app/home');
      },
      onError: (error: any) => {
        // toast(
        //   error?.response?.data?.message || 'Login failed. Please try again.'
        // );
      },
    }
  );
};
