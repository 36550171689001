import { useMutation, useQuery, useQueryClient } from 'react-query';
import { userAxiosInstance } from '../../../../utils/userAxiosInstance';
import { useSidebar } from '../../../../contexts/SidebarContext';
import { useKeypad } from '../../../../contexts/KeypadContext';
import toast from 'react-hot-toast';

export const useGetCableProvidersQuery = () => {
  const token = localStorage.getItem('BSUserToken');

  return useQuery({
    queryKey: 'useGetCableProvidersQuery',
    queryFn: () =>
      userAxiosInstance.get('/bills/cable').then((res) => res.data),
    onSuccess: (data) => {},
    onError: (error) => {
      console.log(`❌ useGetCableProvidersQuery`);
    },
    enabled: token ? true : false,
  });
};

export const useGetCablePlanQuery = () => {
  return useMutation(
    (data: string) =>
      userAxiosInstance
        .post('/bills/cable-list', {
          serviceType: data,
        })
        .then((res: any) => res.data),
    {
      onSuccess: async (data: any) => {},
      onError: (error: any) => {},
    }
  );
};

export const usePayCableTvSubscription = (setPayload: any) => {
  const { toggleKeypad } = useKeypad();
  const { closeUtilitySidebar } = useSidebar();
  const queryClient = useQueryClient();

  return useMutation(
    (payload: any) =>
      userAxiosInstance
        .post('/bills/cable', payload)
        .then((res: any) => res.data),
    {
      onSuccess: async (data: any) => {
        setPayload({
          serviceType: '',
          amount: '',
          phone: '',
          transactionPin: '',
        });

        toggleKeypad();
        closeUtilitySidebar();
        toast(data?.data?.transactionMessage);
        queryClient.invalidateQueries('walletbalance');
      },
      onError: (error: any) => {},
    }
  );
};
