import React from 'react';
import { Fade } from 'react-awesome-reveal';
import { AiFillApple } from 'react-icons/ai';
import { IoLogoGooglePlaystore } from 'react-icons/io5';

type Props = {};

const DownloadApp = () => {
  const heroImg = require('../assets/modelf.png');

  return (
    <section className="bg-[#f1f4ff]">
      <Fade>
        <div className="mx-auto md:max-w-[800px] md:py-[100px] pt-0">
          <div className="bg-[#f1f4ff] md:rounded-[30px] md:px-20 px-[20px] md:pt-0 pt-10">
            <img
              src={heroImg}
              alt=""
              className="md:h-[400px] w-auto h-[300px] mx-auto"
            />
            <h3 className="text-[20px] md:text-dmd text-dsm heading text-secondary text-center font-bold mt-4">
              Download BillSpot App
            </h3>
            <p className="mx-auto max-w-[450px] text-center md:text-txl text-tlg py-5 text-[#3d3d3d]">
              The one stop point for all essential bill payments, local gift
              cards , airtime recharge, digital wallets, and more...
            </p>
            <div className="md:flex gap-4 pb - [60px] mt-[20px] justify-center">
              <span className="manrope500 flex items-center gap-3 bg-white text-tertiary rounded-full py-4 px-8 cursor-pointer ease-in-out duration-300 hover:shadow-md hover:bg-tertiary hover:text-white hover:translate-y-[4px]">
                <AiFillApple className="text-[30px]" />
                iOS App
              </span>
              <span className="md:mt-0 mt-4 manrope500 flex items-center gap-3 bg-white text-tertiary rounded-full py-4 px-8 cursor-pointer ease-in-out duration-300 hover:shadow-md hover:bg-tertiary hover:text-white hover:translate-y-[4px]">
                <IoLogoGooglePlaystore className="text-[30px]" />
                Android App
              </span>
            </div>
          </div>
        </div>
      </Fade>
    </section>
  );
};

export default DownloadApp;
