import { useQuery } from 'react-query';
import { userAxiosInstance } from '../../../../utils/userAxiosInstance';

export const useGetPricesQuery = () => {
  const token = localStorage.getItem('BSUserToken');

  return useQuery({
    queryKey: 'usegetprices',
    queryFn: () =>
      userAxiosInstance.get('/price/users').then((res) => res.data),
    onSuccess: (data) => {
      console.log('prices', data);
    },
    onError: (error) => {
      console.log(`❌ useGetUserDataQuery`);
    },
    enabled: token ? true : false,
  });
};
