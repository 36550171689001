import { FormEvent, useState } from 'react';
import VerificationWrapper from '../../business/home/Verification/VerificationWrapper';
import { useGetUserDataQuery } from '../../../hooks/api/app/auth/useGetUserDataQuery';
import { useModal } from '../../../contexts/ModalContext';
import Modal from '../../../components/Modal';
import { useInitiateUserVerification } from '../../../hooks/api/app/profile/useInitiateUserVerification';
import { useReloadUser } from '../../../hooks/api/app/auth/useReloadUser';
import { useVerifyUserMutation } from '../../../hooks/api/app/profile/useVerifyUserMutation';

type Props = {};

const PhoneAndEmailVerification = (props: Props) => {
  const { data } = useGetUserDataQuery();
  const [otp, setOtp] = useState('');

  const { isModalOpen, closeModal, openModal } = useModal();

  const { mutate } = useInitiateUserVerification();

  const initializeValidation = () => {
    mutate();
    openModal();
  };

  const { mutateAsync: verify, isLoading: verifying } = useVerifyUserMutation({
    otp,
  });

  const handleVerification = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    verify();
    closeModal();
    setOtp('');
  };

  return (
    <VerificationWrapper title="Verify Email & Phone Number" icon={undefined}>
      <div className={`p-4 ${data?.data?.progressCount === 0 ? 'block' : ''}`}>
        <div className="mb-3 font-medium">
          Verify that the information below is correct.{' '}
        </div>
        <p className="mt-3 mb-4">
          An OTP would be sent to your email ({data?.data?.email})
        </p>
        {/* <p className="text-oslol">Email: </p> */}
        {/* <p className="text-oslol mb-3">Phone: {data?.data?.phone}</p> */}

        <button
          onClick={initializeValidation}
          className="bg-tertiary p-3 rounded-md text-center text-white font-medium cursor-pointer"
        >
          Submit
        </button>
      </div>
      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        title={'Email Verification'}
      >
        <form onSubmit={handleVerification} className="">
          <div className="my-4">
            <label htmlFor="otp" className="mb-3 block heading text-tmd">
              OTP
            </label>
            <input
              type="text"
              name="otp"
              placeholder="OTP"
              disabled={verifying}
              autoCorrect="none"
              className="w-full border-oslo border-[1px] p-3 rounded-md"
              value={otp}
              onChange={(e) => setOtp(e?.target?.value)}
            />
          </div>
          {/* <div className="mb-4">
            <label htmlFor="phoneOtp" className="mb-3 block heading text-tmd">
              Phone OTP
            </label>
            <input
              type="text"
              name="phoneOtp"
              placeholder="Phone OTP"
              disabled={verifying}
              autoCorrect="none"
              className="w-full border-oslo border-[1px] p-5 rounded-md"
              value={verification.phoneOtp}
              onChange={handleVer}
            />
          </div> */}
          <button
            type="submit"
            className="p-3 text-white bg-tertiary rounded-md text-center w-full font-bold"
            disabled={verifying}
          >
            {verifying ? 'Submitting...' : 'Submit'}
          </button>
        </form>
      </Modal>
    </VerificationWrapper>
  );
};
export default PhoneAndEmailVerification;
