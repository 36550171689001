import React, { FormEvent, useState } from 'react';
import VerificationWrapper from './VerificationWrapper';
import { useSubmitTransactionPin } from '../../../../hooks/api/business/Onboarding/useSubmitTransactionPin';

const TransactionPin = () => {
  const [transactionPin, steTransactionPin] = useState('');

  const { mutate, isLoading: submitting } =
    useSubmitTransactionPin(transactionPin);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    mutate();
  };

  return (
    <VerificationWrapper icon={''} title="Set Transaction PIN">
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label
            htmlFor="transactionPin"
            className="mt-4 block heading text-tmd"
          >
            Transaction PIN
          </label>
          <h3 className="mb-3">Select a 4 digit pin for all transactions</h3>
          <input
            type="text"
            name="transactionPin"
            disabled={submitting}
            maxLength={4}
            autoCorrect="none"
            className="w-full border-oslo border-[1px] p-3 rounded-md"
            value={transactionPin}
            onChange={(e) => steTransactionPin(e.target.value)}
          />
        </div>
        <button
          type="submit"
          className="p-3 text-white bg-tertiary rounded-md text-center font-bold"
          disabled={submitting}
        >
          {submitting ? 'Submitting...' : 'Submit'}
        </button>
      </form>
    </VerificationWrapper>
  );
};

export default TransactionPin;
