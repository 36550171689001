import { ChangeEvent, useState } from 'react';
import {
  useConfirmResetPassword,
  useResetPassword,
} from '../../../hooks/api/app/auth/useResetPassword';
import { InVisible, Visible } from '../../../components/Icons';

const ForgotPassword = () => {
  const [levels, setLevels] = useState(0);

  const [email, setEmail] = useState('');
  const [passwordVisibility, setPasswordVisibility] = useState(false);

  const [payload, setPayload] = useState({
    email: '',
    token: '',
    password: '',
  });

  const { mutateAsync, isLoading } = useResetPassword(setLevels);
  const { mutate, isLoading: finalizing } = useConfirmResetPassword();

  const handleSubmitEmail = () => {
    mutateAsync(email);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setPayload((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    mutate(payload);
  };

  const isSecondButtonDisabled =
    !payload.email || !payload.token || !payload.password;

  return (
    <div className="w-full h-dvh overflow-y-hidden bg-water">
      <div className="bg-white max-w-[400px] mx-auto rounded-md p-3 shadow-md mt-[100px]">
        <h3 className="heading text-black text-[18px] mt-2">
          {levels === 0 ? 'Forgot Password' : 'Confirm Password Reset'}
        </h3>
        <p className="text-off">
          Complete the process to retrive your password
        </p>

        {levels === 0 && (
          <div className="mt-4">
            <div className="mb-4">
              <label htmlFor="email" className="mb-3 block heading text-tmd">
                Email
              </label>
              <input
                type="email"
                name="email"
                placeholder=""
                disabled={isLoading}
                autoCorrect="none"
                className="w-full border-oslo border-[1px] p-3 rounded-md"
                value={email}
                onChange={(e) => setEmail(e?.target?.value)}
              />
            </div>
            <button
              type="submit"
              className="p-3 text-white bg-tertiary rounded-md text-center w-full font-bold"
              disabled={isLoading}
              onClick={handleSubmitEmail}
            >
              {isLoading ? 'Submitting...' : 'Submit'}
            </button>
          </div>
        )}
        {levels === 1 && (
          <div>
            <div className="mt-10">
              <div className="mb-4">
                <label htmlFor="email" className="mb-3 block heading text-tmd">
                  Email
                </label>
                <input
                  type="email"
                  name="email"
                  placeholder=""
                  disabled={finalizing}
                  autoCorrect="none"
                  className="w-full border-oslo border-[1px] p-3 rounded-md"
                  value={payload.email}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-4">
                <label htmlFor="token" className="mb-3 block heading text-tmd">
                  Token
                </label>
                <input
                  type="number"
                  name="token"
                  placeholder=""
                  disabled={finalizing}
                  autoCorrect="none"
                  className="w-full border-oslo border-[1px] p-3 rounded-md"
                  value={payload.token}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-4">
                <label htmlFor="password" className="mb-3 block heading">
                  Password
                </label>
                <div className="relative">
                  <input
                    type={passwordVisibility ? 'text' : 'password'}
                    name="password"
                    placeholder=""
                    disabled={finalizing}
                    autoCorrect="none"
                    className="w-full border-oslo border-[1px] p-3 rounded-md"
                    value={payload.password}
                    onChange={handleChange}
                  />
                  <span
                    onClick={() => setPasswordVisibility(!passwordVisibility)}
                    className="cursor-pointer absolute right-5 top-3"
                  >
                    {passwordVisibility ? <InVisible /> : <Visible />}
                  </span>
                </div>
              </div>
              <button
                type="submit"
                className="p-3 text-white bg-tertiary rounded-md text-center w-full font-bold"
                disabled={finalizing || isSecondButtonDisabled}
                onClick={handleSubmit}
              >
                {isLoading ? 'Submitting...' : 'Submit'}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ForgotPassword;
