import { useMutation } from 'react-query';
import toast from 'react-hot-toast';
import { useModal } from '../../../../contexts/ModalContext';
import { userAxiosInstance } from '../../../../utils/userAxiosInstance';
import useGetUserDataQuery from './useReloadUser';

type PropType = {
  otp: string;
};

export const useVerifyUserMutation = ({ otp }: PropType) => {
  const { closeModal } = useModal();
  const { handleRefresh } = useGetUserDataQuery();

  return useMutation(
    () =>
      userAxiosInstance
        .post('auth/verify-otp', { otp: otp })
        .then((res: any) => res.data),
    {
      onSuccess: async (data: any) => {
        toast('Verification Successful');
        handleRefresh();
        closeModal();
      },
      onError: (error: any) => {
        toast('Error occured, try again');
      },
    }
  );
};
