import React from 'react';

type Props = {
  icon: any;
  title: string;
  children: React.ReactNode;
};

const VerificationWrapper = ({ icon, title, children }: Props) => {
  return (
    <div className="mb-6 rounded-t-md bg-white shadow-md rounded-md overflow-hidden">
      <div className="heading p-4 text-white flex gap-3 bg-off">
        {icon}
        {title}
      </div>
      <div className="px-4 pb-4">{children}</div>
    </div>
  );
};

export default VerificationWrapper;
