import { useState, useEffect } from 'react';
import {
  useGetEducationalProducts,
  useGetJambProducts,
  useGetWaecProducts,
  usePayEducationalBills,
  usePurchaseJambProducts,
  usePurchaseWaecProducts,
} from '../../../../../../../hooks/api/app/Education/useEducationHandler';
import { useKeypad } from '../../../../../../../contexts/KeypadContext';
import KeyPad from '../../../../../../../components/KeyPad';
import toast from 'react-hot-toast';

type Props = {};

const ScratchCards = (props: Props) => {
  const [payload, setPayload] = useState({
    serviceType: '',
    accountNumber: '',
    productCode: '',
    phone: '',
    amount: '',
    transactionPin: '',
    numberOfPins: '1',
  });

  // const { mutate, data: options } = useGetEducationalProducts();
  const { mutate: payBill, data: payBillResult } = usePayEducationalBills();

  const { toggleKeypad, isKeypadOpen } = useKeypad();

  const { mutate: getWaec, data: waecData } = useGetWaecProducts();
  const { mutate: getJamb, data: jambData } = useGetJambProducts();

  const { mutateAsync: payJamb } = usePurchaseJambProducts();
  const { mutateAsync: payWaec } = usePurchaseWaecProducts();

  useEffect(() => {
    if (!payload.serviceType) return;

    if (payload.serviceType === 'waec') {
      // toast.success('getting waec');
      getWaec(payload.serviceType);
    } else {
      getJamb(payload.serviceType);
    }
  }, [payload.serviceType, getWaec, getJamb]);

  const handleProductSelection = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setPayload({ ...payload, serviceType: e.target.value });
  };

  const handleOptionSelection = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedOption = e.target.selectedOptions[0];
    setPayload({
      ...payload,
      amount: e.target.value,
      productCode: selectedOption.dataset.productCode || '',
    });
  };

  const handleSubmit = () => {
    let updatedPayload = { ...payload, amount: +payload?.amount };

    if (updatedPayload.phone.startsWith('0')) {
      updatedPayload.phone = '+234' + updatedPayload.phone.slice(1);
    }

    let waecPayload = {
      serviceType: updatedPayload?.serviceType,
      numberOfPins: +updatedPayload?.numberOfPins,
      pinValue: updatedPayload?.amount,
      amount: updatedPayload?.amount,
      transactionPin: updatedPayload?.transactionPin,
    };

    let jambPayload = {
      serviceType: updatedPayload?.serviceType,
      accountNumber: updatedPayload?.accountNumber,
      productCode: updatedPayload?.productCode,
      phone: updatedPayload?.phone,
      amount: updatedPayload?.amount,
      transactionPin: updatedPayload?.transactionPin,
    };

    if (payload?.serviceType === 'waec') {
      payWaec(waecPayload);
    } else if (payload?.serviceType === 'jamb') {
      payJamb(jambPayload);
    }
  };

  return (
    <div>
      {/* <p className="text-[10px]">{JSON.stringify(payload)}</p> */}
      <div className="mb-4">
        <label htmlFor="network" className="mb-1 block heading text-tmd">
          Select Service
        </label>
        <select
          name="network"
          id="network"
          className="w-full border-oslo border-[1px] p-3 rounded-md"
          onChange={handleProductSelection}
        >
          <option value="">--Select Option--</option>
          <option value="jamb">JAMB</option>
          <option value="waec">WAEC</option>
        </select>
      </div>
      <div className="">
        {payload?.serviceType === 'waec' && (
          // <div>{JSON.stringify(waecData?.data?.data)}</div>
          <div className="mb-4">
            <label htmlFor="product" className="mb-1 block heading text-tmd">
              Select Product (WAEC)
            </label>

            {JSON.stringify(waecData?.data?.data)}
            {waecData && waecData?.data?.data && (
              <select
                name="product"
                id="product"
                className="w-full border-oslo border-[1px] p-3 rounded-md"
                onChange={handleOptionSelection}
              >
                <option value="">--Select Option--</option>
                {payload?.serviceType === 'waec' &&
                  waecData &&
                  waecData?.data?.data.map(
                    ({
                      id,
                      amount,
                      description,
                    }: {
                      id: number;
                      amount: number;
                      description: string;
                    }) => (
                      <option
                        key={id}
                        value={amount}
                        data-product-code={description}
                      >
                        {description}-{amount}
                      </option>
                    )
                  )}
              </select>
            )}
          </div>
        )}
      </div>
      <div className="">
        {payload?.serviceType === 'jamb' && (
          // <div>{JSON.stringify(jambData?.data?.data)}</div>
          <div className="mb-4">
            <label htmlFor="product" className="mb-1 block heading text-tmd">
              Select Product (JAMB)
            </label>
            <select
              name="product"
              id="product"
              className="w-full border-oslo border-[1px] p-3 rounded-md"
              onChange={handleOptionSelection}
            >
              <option value="">--Select Option--</option>
              {payload?.serviceType === 'jamb' &&
                jambData &&
                jambData?.data?.data.map(
                  ({
                    id,
                    amount,
                    product_code,
                  }: {
                    id: number;
                    amount: number;
                    product_code: string;
                  }) => (
                    <option
                      key={id}
                      value={amount}
                      data-product-code={product_code}
                      className="text-tmd"
                    >
                      {product_code}
                    </option>
                  )
                )}
            </select>
          </div>
        )}
      </div>
      {payload?.serviceType === 'waec' &&
        payload?.amount !== '' &&
        payload?.productCode !== '' && (
          <div>
            <div className="mb-4">
              <label htmlFor="product" className="mb-1 block heading text-tmd">
                Select Quantity
              </label>
              <input
                type="number"
                name="numberOfPins"
                maxLength={1}
                autoCorrect="none"
                autoComplete="off"
                className="w-full border-oslo border-[1px] p-3 rounded-md"
                value={payload?.numberOfPins}
                onChange={(e) =>
                  setPayload({ ...payload, numberOfPins: e?.target?.value })
                }
              />
            </div>
            <div className="mb-4">
              <button
                onClick={toggleKeypad}
                className="p-3 text-white bg-tertiary rounded-md text-center w-full font-bold"
                disabled={!payload.productCode || !payload.serviceType}
              >
                Submit
              </button>
            </div>
          </div>
        )}
      {/* <p className="text-[10px]">
        jambData {JSON.stringify(jambData && 'JAMB DEEY')}
      </p> */}
      {/* {payload.serviceType && (
        <div className="mb-4">
          <label htmlFor="product" className="mb-1 block heading text-tmd">
            Select Product
          </label>
          <select
            name="product"
            id="product"
            className="w-full border-oslo border-[1px] p-3 rounded-md"
            onChange={handleOptionSelection}
          >
            <option value="">--Select Option--</option>
            {options?.data?.data.map(
              ({
                id,
                amount,
                product_code,
              }: {
                id: number;
                amount: number;
                product_code: string;
              }) => (
                <option
                  key={id}
                  value={amount}
                  data-product-code={product_code}
                >
                  {product_code}
                </option>
              )
            )}
          </select>
        </div>
      )} */}
      {payload.serviceType !== '' &&
        payload?.productCode !== '' &&
        payload?.serviceType !== '' &&
        payload?.serviceType !== 'waec' && (
          <>
            <div className="mb-4">
              <label htmlFor="phone" className="mb-1 block heading text-tmd">
                Phone Number
              </label>
              <input
                type="text"
                name="phone"
                maxLength={11}
                autoCorrect="none"
                autoComplete="off"
                className="w-full border-oslo border-[1px] p-3 rounded-md"
                value={payload?.phone}
                onChange={(e) =>
                  setPayload({ ...payload, phone: e?.target?.value })
                }
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="accountNumber"
                className="mb-1 block heading text-tmd"
              >
                Account Number
              </label>
              <input
                type="text"
                name="accountNumber"
                maxLength={11}
                autoCorrect="none"
                autoComplete="off"
                className="w-full border-oslo border-[1px] p-3 rounded-md"
                value={payload?.accountNumber}
                onChange={(e) =>
                  setPayload({ ...payload, accountNumber: e?.target?.value })
                }
              />
            </div>
            <button
              onClick={toggleKeypad}
              className="p-3 text-white bg-tertiary rounded-md text-center w-full font-bold"
              disabled={
                !payload.productCode ||
                !payload.accountNumber ||
                !payload.phone ||
                !payload.serviceType
              }
            >
              Submit
            </button>
          </>
        )}
      <KeyPad
        isKeypadOpen={isKeypadOpen}
        toggleKeypad={toggleKeypad}
        payload={payload}
        setPayload={setPayload}
        handleSubmit={handleSubmit}
      />
    </div>
  );
};

export default ScratchCards;
