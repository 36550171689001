import { useMutation } from 'react-query';
import toast from 'react-hot-toast';
import { userAxiosInstance } from '../../../../utils/userAxiosInstance';
import { useNavigate } from 'react-router-dom';

export const useResetPassword = (setLevels: any) => {
  return useMutation(
    (email: string) =>
      userAxiosInstance
        .post('auth/reset-password', { email: email })
        .then((res: any) => res.data),
    {
      onSuccess: async (data) => {
        setLevels(1);
        toast(data?.message);
      },
      onError: (error: any) => {
        console.log('❌ useResetPassword ', error?.response?.data?.message);
        toast(error?.response?.data?.message);
      },
    }
  );
};

export const useConfirmResetPassword = () => {
  const navigate = useNavigate();

  return useMutation(
    (payload: any) =>
      userAxiosInstance
        .post('auth/confirm-reset', payload)
        .then((res: any) => res.data),
    {
      onSuccess: async (data) => {
        // setLevels(1);
        toast(data?.message);
        navigate('/app/login');
      },
      onError: (error: any) => {
        console.log(
          '❌ useConfirmResetPassword ',
          error?.response?.data?.message
        );
        toast(error?.response?.data?.message);
      },
    }
  );
};
