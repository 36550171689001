import { SiInstagram } from 'react-icons/si';
import { MdOutlineAlternateEmail } from 'react-icons/md';
import { BsTwitterX } from 'react-icons/bs';
import { Link } from 'react-router-dom';

const GetHelp = () => {
  return (
    <div>
      <div className="flex gap-[20px]">
        <div className="w-[450px]">
          <div className="heading font-bold text-txl">Chat with us</div>
          <p className="mb-3">Speak to our friendly team via live chat</p>
          <div className="py-2 flex gap-3 items-center mb-2">
            <SiInstagram fontSize={20} />
            <Link
              to="https://www.instagram.com/billspotapp/"
              target="_blank"
              className="underline font-medium"
            >
              Instagram
            </Link>
          </div>
          <div className="py-2 flex gap-3 items-center mb-2">
            <MdOutlineAlternateEmail fontSize={20} />
            <Link
              to="mailto:info@billspot.app"
              target="_blank"
              className="underline font-medium"
            >
              Shoot us a mail
            </Link>
          </div>
          <div className="py-2 flex gap-3 items-center mb-2">
            <BsTwitterX fontSize={20} />
            <Link
              to="https://x.com/billspotapp"
              target="_blank"
              className="underline font-medium"
            >
              Message us on X
            </Link>
          </div>
          <div className="heading font-bold text-txl mt-4">Call us</div>
          <p className="mb-3">Speak to our friendly team via live chat</p>
          <div className="py-2 flex gap-3 items-center mb-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2}
              stroke="currentColor"
              className="size-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z"
              />
            </svg>

            <Link
              to="tel:08000000000"
              type="tel"
              className="underline font-medium"
            >
              +234-080-1234-5678
            </Link>
          </div>

          <div className="heading font-bold text-txl mt-4">Visit us</div>
          <p className="mb-3">Chat us in person at BillSpot HQ</p>
          <div className="py-2 flex gap-3 mb-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2}
              stroke="currentColor"
              className="size-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
              />
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z"
              />
            </svg>

            <div>
              5/7, Dhikirullohi Olasheu Crescent
              <br /> Agbowa, Ibeshe, <br />
              Lagos State.
            </div>
          </div>

          {/* <h3>Social Media Links</h3> */}
          {/* <h3>Customer Care WhatsApp Line</h3> */}
        </div>
      </div>
    </div>
  );
};

export default GetHelp;
