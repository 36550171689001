import React, { useState } from 'react';
// import BasicInformation from '../../Profile/Tabs/01BasicInformation';
// import SocialInformation from '../../Profile/Tabs/02SocialInformation';
import BasicInformation from './tabs/BasicInformation';
import SocialInformation from './tabs/SocialInformation';
import TimeSelector from './tabs/TimeSelector';
import CompanyImages from './tabs/CompanyImages';
import CompanyLogo from '../../home/Verification/04CompanyLogo';
import PriceLimits from './tabs/PriceLimits';
// import BasicInformation from './tabs/BasicInformation';
// import SocialInformation from './tabs/SocialInformation';
// import TimeSelector from './tabs/TimeSelector';
// import CACVerification from './tabs/CACVerification';
// import ImagesUpload from './tabs/ImagesUpload';

type Props = {};

const ProfileUpdate = (props: Props) => {
  const [active, setActive] = useState(1);
  return (
    <div>
      <div className="pb-[20px] flex gap-[10px] overflow-x-scroll md:flex-wrap no-scrollbar whitespace-nowrap heading mb-4 text-[13px]">
        {/* <span
          className={`cursor-pointer py-2 px-4 rounded-full ${
            active === 0
              ? 'bg-tertiary text-white font-semibold'
              : 'bg-water text-[#8d8d8d]'
          }`}
          onClick={() => setActive(0)}
        >
          Basic Information
        </span> */}
        <span
          className={`cursor-pointer py-2 px-4 rounded-full ${
            active === 1
              ? 'bg-tertiary text-white font-semibold'
              : 'bg-water text-[#8d8d8d]'
          }`}
          onClick={() => setActive(1)}
        >
          Social Information
        </span>

        <span
          className={`cursor-pointer py-2 px-4 rounded-full ${
            active === 2
              ? 'bg-tertiary text-white font-semibold'
              : 'bg-water text-[#8d8d8d]'
          }`}
          onClick={() => setActive(2)}
        >
          Opening Hours
        </span>
        <span
          className={`cursor-pointer py-2 px-4 rounded-full ${
            active === 3
              ? 'bg-tertiary text-white font-semibold'
              : 'bg-water text-[#8d8d8d]'
          }`}
          onClick={() => setActive(3)}
        >
          Price Limits
        </span>
        {/* <span
          className={`cursor-pointer py-2 px-4 rounded-full ${
            active === 3
              ? 'bg-tertiary text-white font-semibold'
              : 'bg-water text-[#8d8d8d]'
          }`}
          onClick={() => setActive(3)}
        >
          Company Logo
        </span> */}

        {/* <span
          className={`cursor-pointer py-2 px-4 rounded-full ${
            active === 5
              ? 'bg-tertiary text-white font-semibold'
              : 'bg-water text-[#8d8d8d]'
          }`}
          onClick={() => setActive(5)}
        >
          Branches/Annex(es)
        </span> */}
        {/* <span
          className={`cursor-pointer py-2 px-4 rounded-full ${
            active === 6
              ? 'bg-tertiary text-white font-semibold'
              : 'bg-water text-[#8d8d8d]'
          }`}
          onClick={() => setActive(6)}
        >
          Images
        </span> */}
      </div>
      {/* FOR UPDATING PROFILE */}
      {/* <div className="mt-3">{active === 0 && <BasicInformation />}</div> */}
      <div className="mt-3">{active === 1 && <SocialInformation />}</div>
      <div className="mt-3">{active === 2 && <TimeSelector />}</div>
      <div className="mt-3">{active === 3 && <PriceLimits />}</div>
      {/* <div className="mt-3">{active === 3 && <CompanyLogo />}</div> */}
      {/* <div className="mt-3">{active === 6 && <CompanyImages />}</div> */}
      {/* <div className="mt-3">{active === 4 && <CACVerification />}</div> */}
      {/* <div className="mt-3">{active === 5 && 'Branches/Annex(es)'}</div> */}
      {/* <div className="mt-3">{active === 6 && <ImagesUpload />}</div> */}
    </div>
  );
};

export default ProfileUpdate;
