import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useSidebar } from '../../../../../../../contexts/SidebarContext';
import KeyPad from '../../../../../../../components/KeyPad';
import { useKeypad } from '../../../../../../../contexts/KeypadContext';
import { useQueryClient } from 'react-query';
import { useGetPricesQuery } from '../../../../../../../hooks/api/app/WES/useGetPricesQuery';

const intialState = {
  firstName: '',
  lastName: '',
  amount: 0,
  email: '',
  password: '',
  wesId: '',
  receivedOn: '',
};

const WesFee: React.FC = () => {
  const [level, setLevel] = useState(0);
  const { isKeypadOpen, toggleKeypad } = useKeypad();
  const [agreement, setAgreement] = useState(false);
  const [validity, setValidity] = useState(false);

  const [payload, setPayload] = useState(intialState);

  const { closeSidebar } = useSidebar();

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setPayload((prevPayload) => ({
      ...prevPayload,
      [name]: value,
    }));
  };

  useEffect(() => {
    const { firstName, lastName, email, password, wesId } = payload;
    setValidity(!!(firstName && lastName && email && password && wesId));
  }, [payload]);

  const queryClient = useQueryClient();
  const { data: prices } = useGetPricesQuery();

  const handleWesPayment = () => {
    const wesPaymentHelper = async () => {
      const url = `${process.env.REACT_APP_BASE_URL}wes/transact`;

      const stringifyToken = localStorage.getItem('BSUserToken');
      const tokenParsed = stringifyToken ? JSON.parse(stringifyToken) : null;

      const currentDateTime = new Date().toISOString();
      const payloadWithTimestamp = {
        ...payload,
        receivedOn: currentDateTime,
        amount: prices[0]?.wesAmount,
      };

      try {
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${tokenParsed}`,
          },
          body: JSON.stringify(payloadWithTimestamp),
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        await response.json();
        toast.success('Payment successful!');
        setPayload(intialState);
        closeSidebar();
        toggleKeypad();
        queryClient.invalidateQueries('walletbalance');
      } catch (error) {
        console.error('Error with payment:', error);
        toast.error('Error, kindly try again');
      }
    };

    wesPaymentHelper();
  };

  return (
    <div>
      {level === 0 && (
        <div>
          <div className="flex gap-3">
            <div className="mb-4">
              <label
                htmlFor="firstName"
                className="mb-1 block heading text-tmd"
              >
                First Name
              </label>
              <input
                type="text"
                name="firstName"
                maxLength={11}
                autoCorrect="off"
                autoComplete="off"
                className="w-full border-oslo border-[1px] p-3 rounded-md"
                value={payload.firstName}
                onChange={handleInputChange}
              />
            </div>
            <div className="mb-4">
              <label htmlFor="lastName" className="mb-1 block heading text-tmd">
                Last Name
              </label>
              <input
                type="text"
                name="lastName"
                maxLength={11}
                autoCorrect="off"
                autoComplete="off"
                className="w-full border-oslo border-[1px] p-3 rounded-md"
                value={payload.lastName}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="mb-4">
            <label htmlFor="wesId" className="mb-1 block heading text-tmd">
              WES ID
            </label>
            <input
              type="text"
              name="wesId"
              autoCorrect="off"
              autoComplete="off"
              className="w-full border-oslo border-[1px] p-3 rounded-md"
              value={payload.wesId}
              onChange={handleInputChange}
            />
          </div>
          <div className="mb-4">
            <label htmlFor="email" className="mb-1 block heading text-tmd">
              Email
            </label>
            <input
              type="email"
              name="email"
              autoCorrect="off"
              autoComplete="off"
              className="w-full border-oslo border-[1px] p-3 rounded-md"
              value={payload.email}
              onChange={handleInputChange}
            />
          </div>
          <div className="mb-4">
            <label htmlFor="password" className="mb-1 block heading text-tmd">
              Password
            </label>
            <input
              type="text"
              name="password"
              maxLength={11}
              autoCorrect="off"
              autoComplete="off"
              className="w-full border-oslo border-[1px] p-3 rounded-md"
              value={payload.password}
              onChange={handleInputChange}
            />
          </div>
          <div className="italic mb-3 text-oslol flex gap-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="size-6 text-red-400"
            >
              <path
                fillRule="evenodd"
                d="M9.401 3.003c1.155-2 4.043-2 5.197 0l7.355 12.748c1.154 2-.29 4.5-2.599 4.5H4.645c-2.309 0-3.752-2.5-2.598-4.5L9.4 3.003ZM12 8.25a.75.75 0 0 1 .75.75v3.75a.75.75 0 0 1-1.5 0V9a.75.75 0 0 1 .75-.75Zm0 8.25a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z"
                clipRule="evenodd"
              />
            </svg>
            Confirm your information before proceeding to payment
          </div>
          <button
            onClick={() => setLevel(1)}
            disabled={!validity}
            className={`p-3 text-white bg-tertiary rounded-md text-center w-full font-bold ${
              !validity ? 'opacity-50' : ''
            }`}
          >
            Submit
          </button>
        </div>
      )}
      {level === 1 && (
        <div>
          <span
            className="flex gap-2 items-center cursor-pointer"
            onClick={() => setLevel(0)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-10 p-3 rounded-full text-black bg-offline"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9 15 3 9m0 0 6-6M3 9h12a6 6 0 0 1 0 12h-3"
              />
            </svg>
            <span>Back</span>
          </span>
          <div className="mt-4 border-[1px] border-[#ddd] rounded-md p-4 mb-4">
            {(
              ['firstName', 'lastName', 'email', 'password', 'wesId'] as const
            ).map((field) => (
              <div className="flex mb-3 flex-col" key={field}>
                <div className="w-4/12 font-medium text-black">
                  {field.charAt(0).toUpperCase() + field.slice(1)}
                </div>
                <div className="flex-1 text-oslol">{payload[field]}</div>
              </div>
            ))}
          </div>
          <div className="mb-4 flex gap-3">
            <input
              type="checkbox"
              checked={agreement}
              onChange={() => setAgreement(!agreement)}
            />
            <span>I have cross-checked the data to be submitted</span>
          </div>
          <button
            onClick={() => setLevel(2)}
            disabled={!validity || !agreement}
            className={`p-3 text-white bg-tertiary rounded-md text-center w-full font-bold ${
              !validity || !agreement ? 'opacity-50' : ''
            }`}
          >
            Proceed to Checkout
          </button>
        </div>
      )}
      {level === 2 && (
        <div>
          <span
            className="flex gap-2 items-center cursor-pointer"
            onClick={() => {
              setLevel(1);
              setAgreement(false);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-10 p-3 rounded-full text-black bg-offline"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9 15 3 9m0 0 6-6M3 9h12a6 6 0 0 1 0 12h-3"
              />
            </svg>
            <span>Back</span>
          </span>
          <div className="border-[1px] border-[#eeeeee] my-[10px] rounded-md">
            <p className="border-b-[1px] border-[#eeeeee] bg-black text-white rounded-t-md font-bold text-center py-[10px]">
              WES FEE BREAKDOWN
            </p>
            <div className="p-[10px]">
              <div className="flex justify-between items-center mb-[15px]">
                <span className="">Subtotal</span>
                <span className="text-[#7b7b7b]">
                  ${prices[0]?.sevisAmount.toLocaleString()}
                </span>
              </div>
              <div className="flex justify-between items-center mb-[15px]">
                <span className="">Discount</span>
                <span className="text-[#7b7b7b]">$0</span>
              </div>
              <div className="flex justify-between items-center mb-[15px]">
                <span className="">Service Charge</span>
                <span className="text-[#7b7b7b]">15%</span>
              </div>
              <div className="flex justify-between items-center mb-[15px]">
                <span className="">VAT (1%)</span>
                <span className="text-[#7b7b7b]">
                  ${0.01 * prices[0]?.sevisAmount}
                </span>
              </div>
              <div className="flex justify-between items-center font-bold border-t-[1px] border-[#eeeeee] pt-[10px]">
                <span className="">Total</span>
                <span className="">
                  $
                  {(
                    prices[0]?.sevisAmount +
                    0.15 * prices[0]?.sevisAmount +
                    0.0 +
                    0.01 * prices[0]?.sevisAmount
                  ).toLocaleString()}
                </span>
              </div>
            </div>
          </div>
          <button
            onClick={toggleKeypad}
            disabled={!agreement}
            className={`p-3 text-white bg-tertiary rounded-md text-center w-full font-bold ${
              !agreement ? 'opacity-50' : ''
            }`}
          >
            Pay
          </button>
          {/* <button
            onClick={toggleKeypad}
            disabled={!agreement}
            className={`p-5 text-white bg-tertiary rounded-md text-center w-full font-bold ${
              !agreement ? 'opacity-50' : ''
            }`}
          >
            Proceed to Checkout
          </button> */}
          <KeyPad
            isKeypadOpen={isKeypadOpen}
            toggleKeypad={toggleKeypad}
            payload={payload}
            setPayload={setPayload}
            handleSubmit={handleWesPayment}
          />
        </div>
      )}
    </div>
  );
};

export default WesFee;
