import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import './index.css';
import Home from './pages/Home';

import Terms from './pages/Terms';
import Ambassadors from './pages/Ambassadors';
import Business from './pages/Business';
import Error from './pages/Error';
import AppHome from './pages/app/AppHome';
import AppMore from './pages/app/AppMore';
import AppCards from './pages/app/AppCards';
import AppHistory from './pages/app/AppHistory';
import AppEarnings from './pages/app/AppEarnings';
import { SidebarProvider } from './contexts/SidebarContext';
import BusinessHome from './pages/business/home';
import { QueryClientProvider } from 'react-query';
import { queryClient } from './services/queryClient';
import { Toaster } from 'react-hot-toast';
import { ModalProvider } from './contexts/ModalContext';
import AppMarketplace from './pages/app/AppMarketplace';
import { AppUserProvider } from './contexts/UserData';
import AppStoreFront from './pages/app/AppMarketplace/AppStoreFront';
import BussinessProfile from './pages/business/Profile';
import Login from './pages/business/auth/Login';
import Register from './pages/business/auth/Register';
import BussinessSales from './pages/business/Sales';
import { KeypadProvider } from './contexts/KeypadContext';
import VerificationLayout from './pages/business/home/Verification/VerificationLayout';
import AppSignIn from './pages/app/auth/AppSignIn';
import AppSignUp from './pages/app/auth/AppSignUp';
import BusinessSettings from './pages/business/Settings';
import ProfileCompletion from './pages/app/ProfileCompletion';
import { HelmetProvider } from 'react-helmet-async';
import ForgotPassword from './pages/app/auth/ForgotPassword';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const router = createBrowserRouter([
  {
    path: '/',
    element: <Home />,
  },
  {
    path: '/ambassadors',
    element: <Ambassadors />,
  },
  {
    path: '/business',
    element: <Business />,
  },
  {
    path: '/terms',
    element: <Terms />,
  },
  {
    path: '/app/login',
    element: <AppSignIn />,
  },
  {
    path: '/app/onboarding',
    element: <ProfileCompletion />,
  },
  {
    path: '/app/register',
    element: <AppSignUp />,
  },
  {
    path: '/forgot-password',
    element: <ForgotPassword />,
  },
  {
    path: '/app/home',
    element: <AppHome />,
  },
  {
    path: '/app/marketplace',
    element: <AppMarketplace />,
  },
  {
    path: '/app/marketplace/:id',
    element: <AppStoreFront />,
  },
  {
    path: '/app/history',
    element: <AppHistory />,
  },
  {
    path: '/app/cards',
    element: <AppCards />,
  },
  {
    path: '/app/earnings',
    element: <AppEarnings />,
  },
  {
    path: '/app/more',
    element: <AppMore />,
  },
  {
    path: '/business/register',
    element: <Register />,
  },
  {
    path: '/business/login',
    element: <Login />,
  },
  {
    path: '/business/onboarding',
    element: <VerificationLayout />,
  },
  {
    path: '/business/home',
    element: <BusinessHome />,
  },
  {
    path: '/business/sales',
    element: <BussinessSales />,
  },
  {
    path: '/business/profile',
    element: <BussinessProfile />,
  },
  {
    path: '/business/settings',
    element: <BusinessSettings />,
  },
  { path: '*', element: <Error /> },
]);

root.render(
  <React.StrictMode>
    <HelmetProvider>
      <QueryClientProvider client={queryClient}>
        <Toaster
          position="top-center"
          reverseOrder={false}
          gutter={8}
          containerClassName=""
          containerStyle={{}}
          toastOptions={{
            duration: 5000,
            style: {
              background: '#363636',
              color: '#fff',
            },
            success: {
              duration: 3000,
            },
          }}
        />
        <KeypadProvider>
          <AppUserProvider>
            <SidebarProvider>
              <ModalProvider>
                <RouterProvider router={router} />
              </ModalProvider>
            </SidebarProvider>
          </AppUserProvider>
        </KeypadProvider>
      </QueryClientProvider>
    </HelmetProvider>
  </React.StrictMode>
);
