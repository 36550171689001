import { useQuery } from 'react-query';
import { apiConfig } from '../../../../constants/apiConfig';
import { userAxiosInstance } from '../../../../utils/userAxiosInstance';

export const useGetSingleStoreQuery = (id: string) => {
  const token = localStorage.getItem('BSUserToken');

  return useQuery({
    queryKey: 'useGetStoresQuery',
    queryFn: () =>
      userAxiosInstance
        .get(`${apiConfig.BUSINESS_STORES}/${id}`)
        .then((res) => res.data),
    onSuccess: (data) => {
      // console.log(`✅ useGetStoresQuery`);
    },
    onError: (error) => {
      console.log(`❌ useGetStoresQuery`);
    },
    enabled: token ? true : false,
  });
};
