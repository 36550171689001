import React from 'react';
import { FaFacebook, FaInstagram, FaLinkedin } from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';
import { Link } from 'react-router-dom';

const Footer = () => {
  let date = new Date();
  let year = date.getFullYear();

  const logo = require('../images/logo.png');

  return (
    <section className="container mx-auto max-w-[1250px]">
      <div className="md:flex py-20 border-notwhite border-b-2 mb-6">
        <span className="md:w-6/12 w-full">
          <Link
            to="/"
            className="mr-[60px] text-[30px] font-bold text-tertiary flex gap-3 items-center heading"
          >
            <img src={logo} alt="BillSpot Logo" className="h-[40px]" />
            BillSpot
          </Link>
          <div className="manrope400 text-oslo manrope500">
            Pay essential bills in few clicks
          </div>
          <div className="flex gap-3 items-center text-oslo text-txs mt-6">
            <Link to="/" target="_blank">
              <FaFacebook />
            </Link>
            <Link to="/" target="_blank">
              <FaXTwitter />
            </Link>
            <Link to="/" target="_blank">
              <FaLinkedin />
            </Link>
            <Link to="/" target="_blank">
              <FaInstagram />
            </Link>
          </div>
        </span>
        <div className="md:w-3/12 w-full md:mt-0 mt-[40px]">
          {/* <h3 className="font-bold heading text-cove mb-3">Contact</h3> */}
          <div className="font-bold heading text-secondary mb-2 text-tlg">
            Head Office
          </div>
          <div className="manrope500 text-oslo mb-2">
            5/7, Dhikirullohi Olasheu Crescent <br />
            Agbowa, Ibeshe,
            <br />
            Lagos State.
          </div>
          <div className="font-bold heading text-secondary mb-2 mt-8 text-tlg">
            Hotline
          </div>
          <span className="manrope500 text-oslo mb-2">
            <Link to="tel:08000000000">+234-8145-0000-0000</Link>
          </span>
          <div className="font-bold heading text-secondary mb-2 mt-8 text-tlg">
            Support & Enquiries
          </div>
          <Link
            to="mailto:info@billspot.app"
            target="_blank"
            className="manrope500 text-oslo mb-2"
          >
            info@billspot.app
          </Link>
        </div>
        <div className="md:w-3/12 w-full md:mt-0 mt-[40px]">
          <h3 className="font-bold heading text-secondary mb-3 text-tlg">
            Solutions
          </h3>
          <div className="manrope500 text-oslo mb-3">Airtime Top Up</div>
          <div className="manrope500 text-oslo mb-3">Cable Tv Subscription</div>
          <div className="manrope500 text-oslo mb-3">Data Bundle Purchases</div>
          <div className="manrope500 text-oslo mb-3">
            Educational Bills & Scratchcards
          </div>
          <div className="manrope500 text-oslo mb-3">SEVIS Fee Payments</div>
          <div className="manrope500 text-oslo mb-3">WES Fee Payments</div>
          <div className="manrope500 text-oslo mb-3">Electricity Bills</div>
          <div className="manrope500 text-oslo mb-3">Local Gift Cards</div>
          <div className="manrope500 text-oslo mb-3">Virtual Wallets</div>
        </div>
      </div>
      <section className="block md:flex text-oslo manrope500 mb-6">
        <div className="md:w-9/12 w-full text-oslo">
          &copy; {JSON.stringify(year)}, BillSpot
        </div>
        <div className="flex gap-8 items-center md:mt-0 mt-5">
          <Link to="/terms">Terms and Conditions</Link>
          <Link to="/privacy">Privacy Policy</Link>
        </div>
      </section>
    </section>
  );
};

export default Footer;

// import React, { useState } from 'react';
// import { Link } from 'react-router-dom';
// import { FaFacebook, FaLinkedin, FaInstagram } from 'react-icons/fa';
// import { FaXTwitter } from 'react-icons/fa6';

// import NavBarDark from './NavBarDark';
// import ScrollToTop from '../hooks/ScrollToTop';
// // import ScrollToTop from './ScrollToTop';

// const GiftDetails = () => {
//   const [payload, setPayload] = useState({
//     businessId: '',
//     cardTier: '',
//     amount: '',
//     recepientPhoneNumber: '',
//     recepientEmail: '',
//   });

// const handleInputChange = (e: any) => {
//   const { name, value } = e.target;
//   setPayload({
//     ...payload,
//     [name]: value,
//   });
// };

//   const sampleLogo = require('../images/sampleLogo.png');

//   return (
//     <ScrollToTop>
//       <div className="bg-tertiary">
//         <section className="container max-w-[1250px] mx-auto">
//           <NavBarDark />
//           <div className="md:pt-[50px] pb-[60px] flex md:flex-row flex-col items-center gap-4 relative">
//             <div className="md:w-6/12 w-full md:order-first order-last">
//               <Link to="/" className="">
//                 <svg
//                   xmlns="http://www.w3.org/2000/svg"
//                   fill="none"
//                   viewBox="0 0 24 24"
//                   strokeWidth="1.5"
//                   stroke="currentColor"
//                   className="size-10 p-3 rounded-full text-black bg-white"
//                 >
//                   <path
//                     strokeLinecap="round"
//                     strokeLinejoin="round"
//                     d="M9 15 3 9m0 0 6-6M3 9h12a6 6 0 0 1 0 12h-3"
//                   />
//                 </svg>
//               </Link>

//               <div className="md:text-dlg text-dsm heading font-extrabold text-white flex mt-6 gap-4">
//                 <span>ShopRite NG</span>
//               </div>
//               <div className="text-water mt-3 w-[80%]">
//                 At Shoprite, we’ve made sure to stock the widest range of your
//                 favourite drinks, all at supermarket prices. From truly
//                 exclusive whiskies to the world’s top wines, it’s never been
//                 more
//               </div>
//               <div className="flex gap-3 items-center text-water text-txs mt-6">
//                 <Link to="/" target="_blank">
//                   <FaFacebook />
//                 </Link>
//                 <Link to="/" target="_blank">
//                   <FaXTwitter />
//                 </Link>
//                 <Link to="/" target="_blank">
//                   <FaLinkedin />
//                 </Link>
//                 <Link to="/" target="_blank">
//                   <FaInstagram />
//                 </Link>
//               </div>
//             </div>
//             <span className="absolute bottom-0 right-0"></span>
//           </div>
//         </section>
//       </div>
//       <div className="container max-w-[1250px] mx-auto my-[50px] mt-10">
//         <div className="bg-water h-[350px] mb-4"></div>
//         <div className="heading font-bold text-txl mt-[60px]">
//           Select eGift card amount
//         </div>
//         <div className="my-4">
//           <div
//             className="p-3 cursor-pointer rounded-md border-[1px] mb-3 border-[#ddd] text-oslol max-w-[450px] w-full flex justify-between items-center"
//             onClick={() =>
//               setPayload({ ...payload, cardTier: 'Silver', amount: '50000' })
//             }
//           >
//             <span className="font-medium">Silver</span>
//             <span>&#8358; 50,000</span>
//           </div>
//           <div
//             className="p-3 cursor-pointer rounded-md border-[1px] mb-3 border-[#ddd] text-oslol max-w-[450px] w-full flex justify-between items-center"
//             onClick={() =>
//               setPayload({ ...payload, cardTier: 'Bronze', amount: '150000' })
//             }
//           >
//             <span className="font-medium">Bronze</span>
//             <span>&#8358; 150,000</span>
//           </div>
//           <div
//             className="p-3 cursor-pointer rounded-md border-[1px] mb-3 border-[#ddd] text-oslol max-w-[450px] w-full flex justify-between items-center"
//             onClick={() =>
//               setPayload({ ...payload, cardTier: 'Gold', amount: '1000000' })
//             }
//           >
//             <span className="font-medium">Gold</span>
//             <span>&#8358; 1,000,000</span>
//           </div>
//         </div>
//         <div className="heading font-bold text-txl mt-[60px]">Recepient(s)</div>
//         <div className="my-4 flex">
//           <input
//             name="recepientPhoneNumber"
//             value={payload.recepientPhoneNumber}
//             onChange={handleInputChange}
//             placeholder="Phone Number"
//             className="p-3 cursor-pointer rounded-l-md border-[1px] mb-3 border-[#ddd] text-oslol w-[225px]"
//           />
//           <input
//             name="recepientEmail"
//             value={payload.recepientEmail}
//             onChange={handleInputChange}
//             placeholder="Email"
//             className="p-3 cursor-pointer rounded-r-md border-[1px] mb-3 border-[#ddd] text-oslol w-[225px]"
//           />
//         </div>
//         <button className="bg-tertiary rounded-md text-white p-2">
//           Add more
//         </button>
//         <div className="heading font-bold text-txl mt-[60px]">Your Details</div>
//         <div className="my-4">
//           <div>
//             <input
//               placeholder="Phone"
//               className="p-3 cursor-pointer rounded-md border-[1px] mb-3 border-[#ddd] text-oslol w-[450px]"
//             />
//           </div>
//           <div>
//             <input
//               placeholder="Email"
//               className="p-3 cursor-pointer rounded-md border-[1px] mb-3 border-[#ddd] text-oslol w-[450px]"
//             />
//           </div>
//           <textarea
//             placeholder="Personal Message (optional)"
//             className="resize-none p-3 h-[300px] cursor-pointer rounded-md border-[1px] mb-3 border-[#ddd] text-oslol w-[450px]"
//           />
//         </div>
//         <button className="heading md:mt-0 mt-4 manrope500 shadow-md bg-tertiary text-white font-bold rounded-full py-4 px-10 cursor-pointer ease-in-out duration-300 hover:shadow-md hover:bg-yellow hover:text-tertiary w-[450px] text-center">
//           Checkout
//         </button>
//       </div>
//     </ScrollToTop>
//   );
// };

// export default GiftDetails;
