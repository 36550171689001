import React, { createContext, useContext, ReactNode } from 'react';

type AppUserContextType = {
  thisUser: any;
};

const AppUserContext = createContext<AppUserContextType | undefined>(undefined);

export const AppUserProvider = ({ children }: { children: ReactNode }) => {
  const user = localStorage.getItem('BSUSER');
  const thisUser = JSON.parse(`${user}`);

  return (
    <AppUserContext.Provider value={{ thisUser }}>
      {children}
    </AppUserContext.Provider>
  );
};

export const useUser = () => {
  const context = useContext(AppUserContext);
  if (context === undefined) {
    throw new Error('useUser must be used within a ModalProvider');
  }
  return context;
};
