import { useQuery } from 'react-query';
import { apiConfig } from '../../../../constants/apiConfig';
import { userAxiosInstance } from '../../../../utils/userAxiosInstance';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';

export const useGetUserDataQuery = () => {
  const token = localStorage.getItem('BSUserToken');
  const navigate = useNavigate();

  return useQuery({
    queryKey: 'useGetUserDataQuery',
    queryFn: () =>
      userAxiosInstance.get(`${apiConfig.USER_DATA}`).then((res) => res.data),
    onSuccess: (data) => {
      // console.log(`✅ useGetUserDataQuery`);
      let u = JSON.stringify(data?.data);
      localStorage.setItem('BSUSER', u);

      if (data?.data?.progressCount < 3) {
        navigate('/app/onboarding', { replace: true });
      }
    },
    onError: (error) => {
      console.log(`❌ useGetUserDataQuery`);
    },
    enabled: token ? true : false,
  });
};
