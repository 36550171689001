import { toast } from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';
import { userAxiosInstance } from '../../../../utils/userAxiosInstance';

export const useUploadProfileImage = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (imageBase64: string | Blob) => {
      if (!imageBase64) throw new Error('No file selected');

      const formData = new FormData();

      if (typeof imageBase64 === 'string') {
        const blob = await (await fetch(imageBase64)).blob();
        formData.append('profile-img', blob, 'image.png');
      } else {
        formData.append('files', imageBase64);
      }

      const response = await userAxiosInstance.patch(
        'auth/update-profile-image',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      return response.data;
    },
    {
      onSuccess: () => {
        console.log('✅ useUploadProfileImage');
        toast.success('Submitted Successfully');
        queryClient.invalidateQueries('useGetUserDataQuery');
      },
      onError: (error: any) => {
        console.log('❌ useUploadProfileImage');
        toast.error(
          error?.response?.data?.message || 'Error, please try again'
        );
      },
    }
  );
};
