import Empty from '../../../../components/Empty';
import Loader from '../../../../components/Loader';
import { useGetBusinessBranches } from '../../../../hooks/api/business/branches/useGetBranches';

const Branches = () => {
  const { data, isLoading } = useGetBusinessBranches();

  return (
    <section className="mt-4 text-black">
      {isLoading && <Loader />}

      {data && data?.data?.data.length === 0 && (
        <Empty text={'No branch available'} />
      )}

      {data && data?.data?.data.length > 0 && (
        <div>
          <div className="flex gap-2 py-3 border-b-[1px] border-ash font-bold bg-[#3d3d3d] text-white">
            <div className="w-3/12 truncate">Branch Address</div>
            <div className="w-3/12 truncate">Phone</div>
            <div className="w-2/12 truncate">City</div>
            <div className="w-2/12 truncate">State</div>
            <div className="w-2/12 truncate">Date Added </div>
          </div>

          {/* Correct placement of the map function */}
          {data?.data?.data.map(
            ({
              id,
              createdAt,
              phone,
              city,
              state,
              branchAddress,
            }: {
              id: string;
              createdAt: string;
              phone: string;
              city: string;
              state: string;
              branchAddress: string;
            }) => (
              <div
                key={id}
                className="flex gap-2 py-3 border-b-[1px] border-ash"
              >
                <div className="w-3/12 truncate">{branchAddress}</div>
                <div className="w-3/12 truncate">{phone}</div>
                <div className="w-2/12 truncate">{city}</div>
                <div className="w-2/12 truncate">{state}</div>
                <div className="w-2/12 truncate">{createdAt}</div>
              </div>
            )
          )}
        </div>
      )}
    </section>
  );
};

export default Branches;
