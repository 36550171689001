import React, { ReactNode } from 'react';
import AppAuthScreen from './AppAuthScreen';
import { Link, useLocation } from 'react-router-dom';

const AuthWrapper = ({ children }: { children: ReactNode }) => {
  const logo = require('../../../assets/logo.png');

  let location = useLocation();

  return (
    <AppAuthScreen>
      <div className="p-4 max-w-[500px] mx-auto md:mt-10 mt-10">
        <span className="flex mb-6">
          <Link
            to="/"
            className="mr-[60px] text-[20px] font-bold flex gap-3 items-center heading"
          >
            <img src={logo} alt="BillSpot Logo" className="h-[40px]" />
            BillSpot
          </Link>
        </span>

        {/* <Link to="/" className="flex items-center gap-2 cursor-pointer">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15.75 19.5 8.25 12l7.5-7.5"
            />
          </svg>
          back
        </Link> */}
        <h3 className="heading text-black md:text-dmd text-txs mt-2">
          Welcome to BillSpot
        </h3>
        <p className="text-off">Cater for all essential bills in one app.</p>
        <div className="my-5">
          <div className="flex gap-2">
            <Link
              to="/app/register"
              className={`cursor-pointer py-2 px-4 rounded-full ${
                location?.pathname?.includes('/app/register')
                  ? 'bg-tertiary text-white font-semibold'
                  : 'bg-water text-[#8d8d8d]'
              }`}
            >
              Register
            </Link>
            <Link
              to="/app/login"
              className={`cursor-pointer py-2 px-4 rounded-full ${
                location?.pathname?.includes('/app/login')
                  ? 'bg-tertiary text-white font-semibold'
                  : 'bg-water text-[#8d8d8d]'
              }`}
            >
              Log In
            </Link>
          </div>
        </div>
        {children}
      </div>
    </AppAuthScreen>
  );
};

export default AuthWrapper;
