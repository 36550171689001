import { useMutation } from 'react-query';
import { userAxiosInstance } from '../../../../utils/userAxiosInstance';

export const useValidateMeeterDetails = () => {
  return useMutation(
    (payload: any) =>
      userAxiosInstance
        .post('/bills/electricity-verify', payload)
        .then((res: any) => res.data),
    {
      onSuccess: async (data: any) => {},
      onError: (error: any) => {},
    }
  );
};
