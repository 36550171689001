import React, { useEffect, useState } from 'react';
import { useKeypad } from '../../../../contexts/KeypadContext';
import KeyPad from '../../../../components/KeyPad';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

interface AppStoreSelectorProps {
  data: {
    data: {
      id: string;
      priceRanges?: {
        min: number;
        max: number;
      };
    };
  };
}

const AppStoreSelector = ({ data }: AppStoreSelectorProps) => {
  const [payload, setPayload] = useState({
    transactionPin: '',
    email: '',
    amount: '',
    address: '',
    note: '',
  });

  const { isKeypadOpen, toggleKeypad } = useKeypad();
  const navigate = useNavigate();
  const [valid, setValid] = useState(false);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setPayload({
      ...payload,
      [name]: value,
    });
  };

  const handlePriceInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const sanitizedValue = value.replace(/\D/g, '');
    setPayload({
      ...payload,
      amount: sanitizedValue,
    });
  };

  const handleGiftcardPayment = () => {
    const url = `${process.env.REACT_APP_BASE_URL}/giftcards/${data?.data?.id}/order-giftcard`;

    const stringifyToken = localStorage.getItem('BSUserToken');
    const tokenParsed = stringifyToken ? JSON.parse(stringifyToken) : null;

    const newPayload = { ...payload, amount: +payload?.amount };

    const options: RequestInit = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${tokenParsed}`,
      },
      body: JSON.stringify(newPayload),
    };

    fetch(url, options)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((resData) => {
        toast.success('Payment successful!');
        toggleKeypad();
        navigate(-1); // Go back after payment
      })
      .catch((error) => {
        console.error('Error with payment:', error);
        toast.error('Error, kindly try again');
      });
  };

  useEffect(() => {
    const { amount, email, note } = payload;

    if (amount && email && note) {
      setValid(true);
    } else {
      setValid(false);
    }
  }, [payload, data?.data?.priceRanges]);

  return (
    <div className="mx-auto mt-[20px] mb-[50px]">
      <div className="heading font-bold mt-[30px] mb-4">Amount</div>
      <input
        type="text"
        name="amount"
        value={payload.amount}
        onChange={handlePriceInputChange}
        className="p-3 cursor-pointer rounded-md border-[1px] mb-3 border-oslo text-oslol md:w-[450px] w-full"
      />
      <p className="text-tsm text-oslo">
        Min. &#8358; {data?.data?.priceRanges?.min?.toLocaleString()} - Max.
        &#8358; {data?.data?.priceRanges?.max?.toLocaleString()}
      </p>

      <div className="heading font-bold mt-[30px]">Recepient Email</div>
      <div className="my-4">
        <input
          name="email"
          value={payload.email}
          onChange={handleInputChange}
          placeholder="Email"
          className="p-3 cursor-pointer rounded-md border-[1px] mb-3 border-oslo text-oslol md:w-[450px] w-full"
        />
      </div>

      <div className="mb-4">
        <label
          htmlFor="note"
          className="mb-3 heading text-tmd flex justify-between md:w-[450px] w-full"
        >
          Note
          <span>{payload.note.length}/40</span>
        </label>
        <textarea
          name="note"
          value={payload.note}
          onChange={handleInputChange}
          maxLength={40}
          className="resize-none md:w-[450px] w-full border-oslo border-[1px] p-3 rounded-md"
        />
      </div>

      <button
        disabled={!valid}
        onClick={toggleKeypad}
        className={`heading mt-4 manrope500 shadow-md bg-tertiary text-white font-bold rounded-md p-3 md:w-[450px] w-full ${
          valid ? 'cursor-pointer' : 'opacity-50'
        }`}
      >
        Checkout
      </button>

      <KeyPad
        isKeypadOpen={isKeypadOpen}
        toggleKeypad={toggleKeypad}
        payload={payload}
        setPayload={setPayload}
        handleSubmit={handleGiftcardPayment}
      />
    </div>
  );
};

export default AppStoreSelector;
