import { useMutation } from 'react-query';
import toast from 'react-hot-toast';
import { axiosInstance } from '../../../../utils/axiosInstance';
import { useModal } from '../../../../contexts/ModalContext';
import { useReloadUser } from '../../app/auth/useReloadUser';
import { useNavigate } from 'react-router-dom';

interface Props {
  verification: object;
}

export const useVerifyBusiness = ({ verification }: Props) => {
  const { closeModal } = useModal();
  const { handleRefresh } = useReloadUser();

  const navigate = useNavigate();

  return useMutation(
    () =>
      axiosInstance
        .post('company/verify-otp/', verification)
        .then((res: any) => res.data),
    {
      onSuccess: async (data: any) => {
        console.log('Registration successful', data);
        toast('Verification Successful');
        closeModal();
        handleRefresh();
        navigate('/business/home');
      },
      onError: (error: any) => {
        // console.log('Registration failed', error);
        toast('Error occured, try again');
      },
    }
  );
};
