// import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
// import faker from 'faker';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top' as const,
    },
    // title: {
    //   display: true,
    //   text: 'Chart.js Bar Chart',
    // },
  },
};

const labels = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sept',
  'Oct',
  'Nov',
  'Dec',
];

export const data = {
  labels,
  datasets: [
    {
      label: 'Sold',
      data: [
        2500769, 4506923, 5320123, 4706328, 3400985, 5055321, 8504321, 7703245,
        8748090, 8845067, 5432034, 7323045,
      ],
      backgroundColor: '#076536',
    },
    {
      label: 'Claimed',
      data: [
        1220093, 3243082, 5830212, 3200191, 7602405, 3450234, 6540321, 1320255,
        2340985, 4506793, 7504321, 9840321,
      ],
      backgroundColor: '#bac400',
    },
    // {
    //   label: 'Gold',
    //   data: [500, 567, 360, 495, 230, 799, 890, 900, 493, 203, 605, 340],
    //   backgroundColor: '#cb7b3d.',
    // },
  ],
};

export function Stats() {
  return <Bar options={options} data={data} className="w-full" />;
}
