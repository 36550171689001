import { ChangeEvent, FormEvent, useState, useEffect } from 'react';
import { useTierUpgradeMutation } from '../../../../hooks/api/app/wallet/TierUpgrade/useTierUpgradeMutation';
import { BiSolidBadgeCheck } from 'react-icons/bi';
import Sidebar from '../../../../components/SideBar';
import toast from 'react-hot-toast';
import { useUploadSevisForSevisMutation } from '../../../../hooks/api/app/SEVIS/useUploadSevisForSevisMutation';
import { useUploadNinNewFunc } from '../../../../hooks/api/app/SEVIS/useUploadNin';

// Defining the types for the payload and tierThreeData
interface PayloadState {
  fullName: string;
  bvnNo: string;
  ninNo: string;
}

interface TierThreeDataState {
  address: string;
  state: string;
  city: string;
  utilityDocUrl: string | null;
  utilityBillType: string;
}

const initialState: PayloadState = {
  fullName: '',
  bvnNo: '',
  ninNo: '',
};

const UpgradeAccountTierThree = () => {
  const [payload, setPayload] = useState<PayloadState>(initialState);
  const [sevisDocument, setSevisDocument] = useState<string | null>(null);
  const [tierThreeData, setTierThreeData] = useState<TierThreeDataState>({
    address: '',
    state: '',
    city: '',
    utilityDocUrl: sevisDocument,
    utilityBillType: '',
  });

  const [checker, setChecker] = useState(false);
  const [states, setStates] = useState<string[]>([]);
  const [lgas, setLgas] = useState<string[]>([]);
  const [selectedLGA, setSelectedLGA] = useState<string>('');
  const [selectedState, setSelectedState] = useState<string>('');

  const [levelThreeModal, setLevelModal] = useState(false);
  const { mutateAsync: upgrade, isLoading } = useTierUpgradeMutation();

  useEffect(() => {
    const getStatesFromApi = async () => {
      try {
        const response = await fetch(
          'https://nga-states-lga.onrender.com/fetch'
        );
        const data = await response.json();
        setStates(data);
        // toast.success('States fetched successfully');
      } catch (error) {
        console.error(error);
        // toast.error('Failed to fetch states');
      }
    };
    getStatesFromApi();
  }, []);

  const getLGAsFromApi = async (state: string) => {
    if (!state) return;
    try {
      const response = await fetch(
        `https://nga-states-lga.onrender.com/?state=${state}`
      );
      if (!response.ok) throw new Error('Failed to fetch LGAs');
      const data = await response.json();
      setLgas(data);
    } catch (error) {
      console.error(error);
      setLgas([]);
      toast.error('Failed to fetch LGAs');
    }
  };

  const handleStateChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const state = e.target.value;
    setSelectedState(state);
    setTierThreeData((prevState) => ({
      ...prevState,
      state,
      city: '', // Reset city when state changes
    }));
    setSelectedLGA(''); // Clear selected LGA
    getLGAsFromApi(state); // Fetch LGAs for the selected state
  };

  const handleLGAChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const selectedLGA = e.target.value;
    setSelectedLGA(selectedLGA);
    setTierThreeData((prevState) => ({
      ...prevState,
      city: selectedLGA,
    }));
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setPayload((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleThreeChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setTierThreeData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmission = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    upgrade(payload);
  };

  const handleClose = () => {
    setChecker(false);
    setPayload(initialState);
  };

  const closeLevelThreeModal = () => {
    setLevelModal(false);
  };

  const { mutateAsync: uploadImageToSevis, data: sevisData } =
    useUploadSevisForSevisMutation({
      sevisDocument,
    });

  const handleSevisChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = async () => {
        const imageBase64 = reader.result as string;
        setSevisDocument(imageBase64);

        try {
          await uploadImageToSevis(imageBase64);
        } catch (error) {
          console.error('Image upload failed:', error);
          toast.error('Error uploading file');
        }
      };

      reader.readAsDataURL(file);
    }
  };

  return (
    <div>
      {/* Tier 3 Upgrade */}
      <div
        onClick={() => setLevelModal(true)}
        className="mt-4 mb-5 border-[1px] border-[#ddd] p-3 rounded-md flex gap-3 bg-offline cursor-pointer"
      >
        <span className="text-[40px]">
          <BiSolidBadgeCheck />
        </span>
        <span>
          <h3 className="heading font-bold">Upgrade Account to Tier 3</h3>
          <p className="text-[#3D3D3D] text-tsm">
            Verify your identity to enjoy higher transaction limits
          </p>
        </span>
      </div>

      {/* Tier 2 Upgrade */}
      {/* <div
        onClick={() => setChecker(true)}
        className="mt-4 mb-5 border-[1px] border-[#ddd] p-3 rounded-md flex gap-3 bg-offline cursor-pointer"
      >
        <span className="text-[40px]">
          <BiSolidBadgeCheck />
        </span>
        <span>
          <h3 className="heading font-bold">Upgrade Account to Tier 2</h3>
          <p className="text-[#3D3D3D] text-tsm">
            Verify your identity to enjoy higher transaction limits
          </p>
        </span>
      </div> */}

      <Sidebar
        title="Upgrade Account to Tier 3"
        isOpen={levelThreeModal}
        onClose={closeLevelThreeModal}
      >
        <div>
          <label htmlFor="address" className="mb-3 block heading text-tmd">
            Address
          </label>
          <textarea
            name="address"
            placeholder="Address"
            autoComplete="off"
            value={tierThreeData.address}
            onChange={handleThreeChange}
            className="h-[150px] resize-none w-full border-oslo border-[1px] p-3 rounded-md"
          />
        </div>

        <div className="mb-4 mt-3">
          <label htmlFor="state" className="mb-1 block heading text-tmd">
            State
          </label>
          <select
            name="state"
            value={selectedState}
            onChange={handleStateChange}
            className="w-full border-oslo border-[1px] p-3 rounded-md"
          >
            <option value="">Select state</option>
            {states &&
              states.map((state) => (
                <option key={state} value={state}>
                  {state}
                </option>
              ))}
          </select>
        </div>

        <div className="mb-4">
          <label htmlFor="city" className="mb-1 block heading text-tmd">
            City
          </label>
          <select
            name="city"
            value={selectedLGA}
            onChange={handleLGAChange}
            className="w-full border-oslo border-[1px] p-3 rounded-md"
          >
            <option value="">Select City</option>
            {lgas &&
              lgas.map((lga) => (
                <option key={lga} value={lga}>
                  {lga}
                </option>
              ))}
          </select>
        </div>

        {/* Utility Document URL */}
        <div className="mb-4">
          <label
            htmlFor="utilityBillType"
            className="mb-1 block heading text-tmd"
          >
            Utility Document Type
          </label>
          <select
            name="utilityBillType"
            value={tierThreeData.utilityBillType || ''}
            onChange={handleThreeChange}
            className="w-full border-oslo border-[1px] p-3 rounded-md"
          >
            <option value="">Select Utility Document</option>
            <option value="Electricity Bill">Electricity Bill</option>
            <option value="Waste Bill">Waste Bill</option>
            <option value="Water Bill">Water Bill</option>
          </select>
        </div>

        {/* File Upload */}
        {tierThreeData?.utilityBillType !== '' && (
          <div className="mb-4">
            <label className="inline-block px-4 rounded-full py-2 mb-4 text-white bg-tertiary cursor-pointer">
              Select image
              <input
                name="sevisDocUrl"
                type="file"
                accept="image/*"
                multiple={false}
                onChange={handleSevisChange}
                className="mt-2 hidden"
              />
            </label>
          </div>
        )}
        <img src={sevisData} alt="" />

        {/* <p>{JSON.stringify(sevisDocument)}</p> */}
        <button
          type="submit"
          className="p-3 text-white bg-tertiary rounded-md text-center w-full font-bold"
          disabled={isLoading}
        >
          {isLoading ? 'Submitting...' : 'Submit'}
        </button>

        <p className="text-[10px]">{JSON.stringify(tierThreeData)}</p>
      </Sidebar>
    </div>
  );
};

export default UpgradeAccountTierThree;
