import React from 'react';
import Modal from '../Modal';
import toast from 'react-hot-toast';

const FundWalletModal = ({
  isModalOpen,
  closeModal,
  data,
}: {
  isModalOpen: boolean;
  closeModal: () => void;
  data: any;
}) => {
  const copyToClipboard = () => {
    if (!data?.data?.dedicatedAccountNumber) return;

    navigator.clipboard
      .writeText(data?.data?.dedicatedAccountNumber)
      .then(() => {
        toast.success('Copied Successfully');
      })
      .catch(() => {
        toast.error('Failed to copy Successfully');
      });
  };

  return (
    <Modal isOpen={isModalOpen} onClose={closeModal} title={'Account details'}>
      <div>
        <div className="font-bold mt-3 text-{#3d3d3d]">Bank Name</div>
        <div className="mt-3 text-[#8d8d8d]">
          {data?.data?.dedicatedAccountName}
        </div>
        <div className="font-bold mt-3 text-{#3d3d3d]">Account Number</div>
        <div className="mt-3 flex justify-between items-center text-[#8d8d8d]">
          {data?.data?.dedicatedAccountNumber}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-6 cursor-pointer"
            onClick={copyToClipboard}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 0 1-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 0 1 1.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 0 0-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 0 1-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 0 0-3.375-3.375h-1.5a1.125 1.125 0 0 1-1.125-1.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H9.75"
            />
          </svg>
        </div>
      </div>
    </Modal>
  );
};

export default FundWalletModal;
