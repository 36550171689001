import { useMutation } from 'react-query';
import { userAxiosInstance } from '../../../../utils/userAxiosInstance';
import toast from 'react-hot-toast';
import { useSidebar } from '../../../../contexts/SidebarContext';
import { useKeypad } from '../../../../contexts/KeypadContext';

export const useGetEducationalProducts = () => {
  return useMutation(
    (payload: any) =>
      userAxiosInstance
        .post('/bills/exam-pin-products', { serviceType: payload })
        .then((res: any) => res.data),
    {
      onSuccess: async (data: any) => {},
      onError: (error: any) => {},
    }
  );
};

export const usePayEducationalBills = () => {
  const { closeSidebar } = useSidebar();
  const { toggleKeypad } = useKeypad();
  return useMutation(
    (payload: any) =>
      userAxiosInstance
        .post('/bills/exam-products-purchase', payload)
        .then((res: any) => res.data),
    {
      onSuccess: async (data: any) => {
        toast.success(data?.message);
        closeSidebar();
        toggleKeypad();
      },
      onError: (error: any) => {},
    }
  );
};

// JAMB PRODUCTS
export const useGetJambProducts = () => {
  return useMutation(
    (payload: any) =>
      userAxiosInstance
        .post('/bills/exam-pin-products', { serviceType: payload })
        .then((res: any) => res.data),
    {
      onSuccess: async () => {
        // toast('gotten JAMB');
      },
      onError: (error: any) => {
        toast('failed to get JAMB');
      },
    }
  );
};

// WAEC PRODUCTS
export const useGetWaecProducts = () => {
  return useMutation(
    (payload: any) =>
      userAxiosInstance
        .post('/bills/exam-pin-products-waec', { serviceType: payload })
        .then((res: any) => res.data),
    {
      onSuccess: async () => {
        // toast('gotten WAEC');
      },
      onError: (error: any) => {
        // toast('failed to get WAEC');
      },
    }
  );
};

// PURCHASE WAEC
export const usePurchaseWaecProducts = () => {
  const { toggleKeypad } = useKeypad();
  const { closeSidebar } = useSidebar();
  return useMutation(
    (payload: any) =>
      userAxiosInstance
        .post('/bills/exam-products-purchase-waec', payload)
        .then((res: any) => res.data),
    {
      onSuccess: async (data) => {
        toast.success(data?.message);
        closeSidebar();
        toggleKeypad();
        // toast('purchased WAEC');
      },
      onError: (error: any) => {
        // toast('failed to get WAEC');
      },
    }
  );
};

// PURCHASE JAMB
export const usePurchaseJambProducts = () => {
  const { toggleKeypad } = useKeypad();
  const { closeSidebar } = useSidebar();

  return useMutation(
    (payload: any) =>
      userAxiosInstance
        .post('/bills/exam-products-purchase', payload)
        .then((res: any) => res.data),
    {
      onSuccess: async (data) => {
        toast.success(data?.message);
        closeSidebar();
        toggleKeypad();
      },
      onError: (error: any) => {
        // toast('failed to get JAMB');
      },
    }
  );
};
