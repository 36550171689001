import React, { useState } from 'react';

type Plan = {
  name: string;
  price: number;
  datacode: string;
  validity: string;
  allowance: string;
};

type DataPlans = {
  [key: string]: Plan[];
};

interface PlanSelectorProps {
  plans: DataPlans;
  setPayload: any;
  payload: any;
}

const PlanSelector: React.FC<PlanSelectorProps> = ({
  plans,
  setPayload,
  payload,
}) => {
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
  const [selectedPlan, setSelectedPlan] = useState<Plan | null>(null);

  const handleCategoryChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const category = e.target.value;
    setSelectedCategory(category);
    setSelectedPlan(null); // Reset selected plan when category changes
  };

  const handlePlanChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const planName = e.target.value;
    if (selectedCategory && plans[selectedCategory]) {
      const plan = plans[selectedCategory].find(
        (plan) => plan.name === planName
      );
      setSelectedPlan(plan || null);
      setPayload({ ...payload, dataCode: plan?.datacode, amount: plan?.price });
    }
  };

  return (
    <div>
      <div className="mb-4">
        <label htmlFor="category" className="mb-1 block heading text-tmd">
          Select Category
        </label>
        <select
          name="category"
          id="category"
          className="w-full border-oslo border-[1px] p-3 rounded-md"
          onChange={handleCategoryChange}
        >
          <option value="">-- Select a Category --</option>
          {plans &&
            Object.keys(plans).map((category) => (
              <option key={category} value={category}>
                {category
                  .replace(/([A-Z])/g, ' $1')
                  .replace(/^./, (str) => str.toUpperCase())}
              </option>
            ))}
        </select>
      </div>

      {selectedCategory && (
        <div className="mb-4">
          <label htmlFor="plan" className="mb-1 block heading text-tmd">
            Select a Plan
          </label>
          <select
            id="plan"
            className="w-full border-oslo border-[1px] p-3 rounded-md"
            onChange={handlePlanChange}
          >
            <option value="">-- Select a Plan --</option>
            {plans && plans[selectedCategory] ? (
              plans[selectedCategory].map((plan) => (
                <option key={plan.datacode} value={plan.name}>
                  {plan.name} - {plan.price} NGN
                </option>
              ))
            ) : (
              <option disabled>No plans available</option>
            )}
          </select>
        </div>
      )}

      {/* {JSON.stringify(payload)} */}

      {/* {selectedPlan ? (
        <div>
          <h2>Plan Details:</h2>
          <p>Name: {selectedPlan.name}</p>
          <p>Price: {selectedPlan.price} NGN</p>
          <p>Validity: {selectedPlan.validity}</p>
          <p>Allowance: {selectedPlan.allowance}</p>
          <p>DAA: {selectedPlan.datacode}</p>
        </div>
      ) : selectedCategory ? (
        <p className="text-[10px] text-ash">
          No plan selected or no plans available for the selected category.
        </p>
      ) : null} */}
    </div>
  );
};

export default PlanSelector;
