import { useQuery } from 'react-query';
import { userAxiosInstance } from '../../../../../utils/userAxiosInstance';

export const useGetTotalTransactionsQuery = () => {
  const token = localStorage.getItem('BSUserToken');

  return useQuery({
    queryKey: 'totalbalance',
    queryFn: () =>
      userAxiosInstance.get('wallet/total-transaction').then((res) => res.data),
    onSuccess: (data) => {
      console.log(`✅ useGetTotalTransactionsQuery`);
    },
    onError: (error) => {
      console.log(`❌ useGetTotalTransactionsQuery`);
    },
    enabled: token ? true : false,
  });
};
