import React from 'react';
import { useNavigate } from 'react-router-dom';

type Props = { images: string[] };

const AppStoreFrontGallery = ({ images }: Props) => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  return (
    <div>
      <span
        className="flex gap-2 items-center cursor-pointer font-medium mt-[10px]"
        onClick={goBack}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="currentColor"
          className="size-10 p-3 rounded-full text-black bg-offline"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M9 15 3 9m0 0 6-6M3 9h12a6 6 0 0 1 0 12h-3"
          />
        </svg>
        <span>Back</span>
      </span>

      <div className="flex gap-3 overflow-x-scroll no-scrollbar mt-4">
        {images &&
          images.map((image) => (
            <img
              key={image}
              src={image}
              className="h-[200px] w-[400px] rounded-md"
              alt=""
            />
          ))}
      </div>
    </div>
  );
};

export default AppStoreFrontGallery;
