import { useMutation, useQuery, useQueryClient } from 'react-query';
import { userAxiosInstance } from '../../../../utils/userAxiosInstance';
import { useKeypad } from '../../../../contexts/KeypadContext';
import { useSidebar } from '../../../../contexts/SidebarContext';

export const useGetInternationalAirtime = () => {
  const { data, isLoading, error } = useQuery({
    queryKey: 'useGetInternationalAirtime',
    queryFn: () =>
      userAxiosInstance
        .get('/bills/int-countries?page=3')
        .then((res) => res.data),
    onSuccess: (data) => {},
    onError: (error) => {
      console.log('❌ useGetInternationalAirtime ==>', error);
    },
  });

  return { data, isLoading, error };
};

export const useGetCountryOperators = () => {
  return useMutation(
    (exPayload: { countryCode: string }) =>
      userAxiosInstance
        .post('/bills/int-countries/operators', exPayload)
        .then((res: any) => res.data),
    {
      onSuccess: async (data: any) => {},
      onError: (error: any) => {},
    }
  );
};

export const useBuyInternationalAirtime = () => {
  const { toggleKeypad } = useKeypad();
  const { closeSidebar } = useSidebar();
  const queryClient = useQueryClient();

  return useMutation(
    (payload: any) =>
      userAxiosInstance
        .post('/bills/int-purchase', payload)
        .then((res: any) => res.data),
    {
      onSuccess: async (data: any) => {
        toggleKeypad();
        closeSidebar();
        // toast(data?.message);
        queryClient.invalidateQueries('walletbalance');
      },
      onError: (error: any) => {},
    }
  );
};
