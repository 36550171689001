/* eslint-disable react/display-name */
import { useEffect } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

const withAuth = (WrappedComponent) => {
  return (props) => {
    const navigate = useNavigate();

    const stringifyToken = localStorage.getItem('BSUserToken');
    const parsedToken = stringifyToken ? JSON.parse(stringifyToken) : null;

    useEffect(() => {
      if (!parsedToken) {
        navigate('/');
        // toast('Unauthorized, proceed to sign in');
        // return null;
      }
    }, [navigate, parsedToken]);

    return <WrappedComponent {...props} />;
  };
};

export default withAuth;
