import { Helmet } from 'react-helmet-async';
import { Fade } from 'react-awesome-reveal';
import { AiFillApple } from 'react-icons/ai';
import { IoLogoGooglePlaystore } from 'react-icons/io5';
import { PiGlobeBold } from 'react-icons/pi';
import { Typewriter } from 'react-simple-typewriter';
import Slider from 'react-slick';
import Footer from '../components/Footer';
import DownloadApp from '../components/DownloadApp';
import Faq from '../components/Faq';
import ScrollToTop from '../hooks/ScrollToTop';
import { Link } from 'react-router-dom';
import InitialLoader from '../components/InitialLoader';

const Home = () => {
  const money = require('../images/image.png');
  const img2 = require('../assets/modelL.png');

  var settings = {
    dots: false,
    infinite: true,
    speed: 400,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    fade: true,
    pauseOnHover: false,
  };

  const spa = require('../assets/slider/spa.jpg');

  return (
    <ScrollToTop>
      <InitialLoader />
      <Helmet>
        <title>
          BillSpot - Bills, Local Gift Cards, International Airtime, SEVIS and
          WES Fee Payment
        </title>
        <meta
          name="description"
          content="The one stop point for all essential bill payments, local gift cards, airtime recharge, digital wallets, and more"
        />
        <meta name="robots" content="index,follow" />
        <link rel="canonical" href="https://www.billspot.app" />
      </Helmet>

      <div className="bg-tertiary py-[50px]">
        <div className="container max-w-[1250px] mx-auto">
          <div className="md:flex md:flex-row md:gap-4 md:py-[30px]">
            <div className="md:w-6/12 w-full md:order-first order-last md:px-[30px] px-0">
              <h1 className="md:text-dxl text-dsm heading font-extrabold text-white">
                Cater for all your
                <br /> essential bills <br />
                in one app.
              </h1>
              <div className="mt-5 text-water text-tlg">
                The one stop point for all essential bill payments,
                <br className="hidden md:block" />
                local gift cards, airtime recharge, digital wallets,{' '}
                <br className="hidden md:block" />
                and more...
              </div>
              <div className="md:mt-[50px] mt-[15px] text-water text-lg italic">
                Join other BillSpotters on
              </div>
              <div className="flex gap-4 mt-[20px] order-first md:order-last">
                <span className="md:mt-0 mt-4 manrope500 shadow-md flex items-center gap-3 bg-green text-white rounded-full py-4 px-4 cursor-pointer hover:text-green hover:bg-white ease-in-out duration-300 hover:translate-y-[4px]">
                  <a
                    href="https://apple.com"
                    rel="noreferrer"
                    target="_blank"
                    className="flex gap-3 items-center"
                  >
                    <AiFillApple className="text-[25px]" />{' '}
                    <span className="font-medium md:block hidden">iOS</span>
                  </a>
                </span>
                <span className="md:mt-0 mt-4 manrope500 shadow-md flex items-center gap-3 bg-green text-white rounded-full py-4 px-4 cursor-pointer hover:text-green hover:bg-white ease-in-out duration-300 hover:translate-y-[4px]">
                  <a
                    href="https://play.google.com/"
                    target="_blank"
                    rel="noreferrer"
                    className="flex gap-3 items-center"
                  >
                    <IoLogoGooglePlaystore className="text-[25px]" />
                    <span className="font-medium md:block hidden">Android</span>
                  </a>
                </span>
                <Link
                  to="/app/register"
                  className="md:mt-0 mt-4 manrope500 shadow-md flex items-center gap-3 bg-green text-white rounded-full py-4 px-4 cursor-pointer hover:text-green hover:bg-white ease-in-out duration-300 hover:translate-y-[4px]"
                >
                  <PiGlobeBold className="text-[25px]" />
                  <span className="font-medium md:block hidden">Web</span>
                </Link>
              </div>
            </div>
            <span className="md:w-6/12 w-full">
              <img src={img2} alt="" className="md:block hidden" />
            </span>
          </div>
        </div>
      </div>
      <section className="container max-w-[1250px] mx-auto py-[100px]">
        <h3 className="heading text-center text-dsm font-extrabold text-off">
          BillSpot Offers
        </h3>
        <div className="mt-10 grid md:grid-cols-3 grid-cols-1 gap-[1px] bg-water">
          <div className="p-3 bg-[#fff]">
            <div className="flex gap-4">
              <span className="w-[30px] mt-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M7.5 21 3 16.5m0 0L7.5 12M3 16.5h13.5m0-13.5L21 7.5m0 0L16.5 12M21 7.5H7.5"
                  />
                </svg>
              </span>
              <div className="flex-1">
                <h3 className="heading text-txl font-semibold text-tertiary">
                  Airtime Recharge
                </h3>
                <p className="text-[#616161] text-tsm">
                  Enjoy fast transactions and stay connected without any
                  interruptions. Top up your phone anytime, anywhere. Recharge
                  now!
                </p>
              </div>
            </div>
          </div>
          <div className="p-3 bg-[#fff]">
            <div className="flex gap-4">
              <span className="w-[30px] mt-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M7.5 21 3 16.5m0 0L7.5 12M3 16.5h13.5m0-13.5L21 7.5m0 0L16.5 12M21 7.5H7.5"
                  />
                </svg>
              </span>
              <div className="flex-1">
                <h3 className="heading text-txl font-semibold text-tertiary">
                  Data Bundles
                </h3>
                <p className="text-[#616161] text-tsm">
                  Purchase data bundles online to stay connected anytime,
                  anywhere. Our secure platform offers a variety of plans to
                  suit your needs.
                </p>
              </div>
            </div>
          </div>
          <div className="p-3 bg-[#fff]">
            <div className="flex gap-4">
              <span className="w-[30px] mt-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 20.25h12m-7.5-3v3m3-3v3m-10.125-3h17.25c.621 0 1.125-.504 1.125-1.125V4.875c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125Z"
                  />
                </svg>
              </span>
              <div className="flex-1">
                <h3 className="heading text-txl font-semibold text-tertiary">
                  Cable TV Subscription
                </h3>
                <p className="text-[#616161] text-tsm">
                  Easily renew your cable TV subscription online. Enjoy
                  uninterrupted access to your favorite channels with our secure
                  and convenient payment options.
                </p>
              </div>
            </div>
          </div>
          <div className="p-3 bg-[#fff]">
            <div className="flex gap-4">
              <span className="w-[30px] mt-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m3.75 13.5 10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75Z"
                  />
                </svg>
              </span>
              <div className="flex-1">
                <h3 className="heading text-txl font-semibold text-tertiary">
                  Electricity bills
                </h3>
                <p className="text-[#616161] text-tsm">
                  Pay electricity bills online quickly and securely. Our
                  easy-to-use platform ensures timely payments, helping you
                  avoid interruptions.
                </p>
              </div>
            </div>
          </div>
          <div className="p-3 bg-[#fff]">
            <div className="flex gap-4">
              <span className="w-[30px] mt-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 0 0 2.25-2.25V6.75A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25v10.5A2.25 2.25 0 0 0 4.5 19.5Z"
                  />
                </svg>
              </span>
              <div className="flex-1">
                <h3 className="heading text-txl font-semibold text-tertiary">
                  Virtual Wallets
                </h3>
                <p className="text-[#616161] text-tsm">
                  Manage your finances effortlessly with our secure and
                  user-friendly digital wallets. Store, send, and receive money
                  with ease.
                </p>
              </div>
            </div>
          </div>
          <div className="p-3 bg-[#fff]">
            <div className="flex gap-4">
              <span className="w-[30px] mt-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 21a9.004 9.004 0 0 0 8.716-6.747M12 21a9.004 9.004 0 0 1-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 0 1 7.843 4.582M12 3a8.997 8.997 0 0 0-7.843 4.582m15.686 0A11.953 11.953 0 0 1 12 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0 1 21 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0 1 12 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 0 1 3 12c0-1.605.42-3.113 1.157-4.418"
                  />
                </svg>
              </span>
              <div className="flex-1">
                <h3 className="heading text-txl font-semibold text-tertiary">
                  SEVIS Fee Payment
                </h3>
                <p className="text-[#616161] text-tsm">
                  Pay SEVIS fee online to secure your student visa. We ensure
                  your application process is seamless and stress-free.
                </p>
              </div>
            </div>
          </div>
          <div className="p-3 bg-[#fff]">
            <div className="flex gap-4">
              <span className="w-[30px] mt-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9 6.75V15m6-6v8.25m.503 3.498 4.875-2.437c.381-.19.622-.58.622-1.006V4.82c0-.836-.88-1.38-1.628-1.006l-3.869 1.934c-.317.159-.69.159-1.006 0L9.503 3.252a1.125 1.125 0 0 0-1.006 0L3.622 5.689C3.24 5.88 3 6.27 3 6.695V19.18c0 .836.88 1.38 1.628 1.006l3.869-1.934c.317-.159.69-.159 1.006 0l4.994 2.497c.317.158.69.158 1.006 0Z"
                  />
                </svg>
              </span>
              <div className="flex-1">
                <h3 className="heading text-txl font-semibold text-tertiary">
                  WES Fee Payment
                </h3>
                <p className="text-[#616161] text-tsm">
                  Our system ensures a smooth transaction, helping you get one
                  step closer to achieving your educational and career goals.
                </p>
              </div>
            </div>
          </div>
          <div className="p-3 bg-[#fff]">
            <div className="flex gap-4">
              <span className="w-[30px] mt-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M21 11.25v8.25a1.5 1.5 0 0 1-1.5 1.5H5.25a1.5 1.5 0 0 1-1.5-1.5v-8.25M12 4.875A2.625 2.625 0 1 0 9.375 7.5H12m0-2.625V7.5m0-2.625A2.625 2.625 0 1 1 14.625 7.5H12m0 0V21m-8.625-9.75h18c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125h-18c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125Z"
                  />
                </svg>
              </span>
              <div className="flex-1">
                <h3 className="heading text-txl font-semibold text-tertiary">
                  Local Gift Cards
                </h3>
                <p className="text-[#616161] text-tsm">
                  Get gift cards for local stores and services in Nigeria easily
                  from our wide range of options, making gifting convenient and
                  hassle-free.
                </p>
              </div>
            </div>
          </div>
          <div className="p-3 bg-[#fff]">
            <div className="flex gap-4">
              <span className="w-[30px] mt-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M4.26 10.147a60.438 60.438 0 0 0-.491 6.347A48.62 48.62 0 0 1 12 20.904a48.62 48.62 0 0 1 8.232-4.41 60.46 60.46 0 0 0-.491-6.347m-15.482 0a50.636 50.636 0 0 0-2.658-.813A59.906 59.906 0 0 1 12 3.493a59.903 59.903 0 0 1 10.399 5.84c-.896.248-1.783.52-2.658.814m-15.482 0A50.717 50.717 0 0 1 12 13.489a50.702 50.702 0 0 1 7.74-3.342M6.75 15a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Zm0 0v-3.675A55.378 55.378 0 0 1 12 8.443m-7.007 11.55A5.981 5.981 0 0 0 6.75 15.75v-1.5"
                  />
                </svg>
              </span>
              <div className="flex-1">
                <h3 className="heading text-txl font-semibold text-tertiary">
                  Educational Bills
                </h3>
                <p className="text-[#616161] text-tsm">
                  Easily purchase JAMB EPINS online, enabling you to access your
                  educational exam services instantly.
                </p>
              </div>
            </div>
          </div>
          {/* <div className="p-3 bg-[#fff]">
            <div className="flex gap-4">
              <span className="w-[30px] mt-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M4.26 10.147a60.438 60.438 0 0 0-.491 6.347A48.62 48.62 0 0 1 12 20.904a48.62 48.62 0 0 1 8.232-4.41 60.46 60.46 0 0 0-.491-6.347m-15.482 0a50.636 50.636 0 0 0-2.658-.813A59.906 59.906 0 0 1 12 3.493a59.903 59.903 0 0 1 10.399 5.84c-.896.248-1.783.52-2.658.814m-15.482 0A50.717 50.717 0 0 1 12 13.489a50.702 50.702 0 0 1 7.74-3.342M6.75 15a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Zm0 0v-3.675A55.378 55.378 0 0 1 12 8.443m-7.007 11.55A5.981 5.981 0 0 0 6.75 15.75v-1.5"
                  />
                </svg>
              </span>
              <div className="flex-1">
                <h3 className="heading text-txl font-semibold text-tertiary">
                  International Airtime
                </h3>
                <p className="text-[#616161] text-tsm">
                  Easily purchase JAMB EPINS online, enabling you to access your
                  educational exam services instantly.
                </p>
              </div>
            </div>
          </div> */}
        </div>
      </section>
      <section className="bg-secondary">
        <Fade>
          <div className="container py-20 text-white">
            <div className="grid gap-2 justify-items-center items-center md:text-dlg text-txs text-center mx-auto heading">
              BillSpot has made it easy to
              <div className="md:text-dxl text-txs text-yellow">
                <Typewriter
                  words={[' Buy', ' Earn', ' Recharge', 'Pay Bills']}
                  loop={false}
                  cursor
                  cursorStyle="|"
                  typeSpeed={70}
                  deleteSpeed={50}
                  delaySpeed={1000}
                />
              </div>
            </div>
          </div>
        </Fade>
      </section>
      <Fade>
        <section className="container max-w-[1250px] mx-auto md:py-[200px] py-[100px]">
          <div className="md:flex md:gap-[40px] block">
            <div className="md:w-6/12 w-full md:mb-0 mb-10">
              <img
                src={money}
                alt="ambassador"
                className="md:h-[400px] h-[250px] w-full md:rounded-[30px] rounded-md"
              />
            </div>
            <div className="md:w-6/12 w-full">
              <div>
                <h4 className="heading text-secondary md:text-dmd text-txs font-bold">
                  How to Become a BillSpot <br /> Ambassador
                </h4>
                <p className="mt-3 mb-[20px] max-w-[600px] text-off text-tlg">
                  {/* Are you an influential person with a large audience? */}
                  Join BillSpot’s Ambassadorship Program! Promote BillSpot to
                  your followers and earn passive income. Share the convenience
                  of seamless bill payments while benefiting financially. <br />
                </p>
                <h4 className="md:text-tlg text-tlg  text-secondary mb-4">
                  1. Sign up on BillSpot as a user
                </h4>
                <h4 className="md:text-tlg text-tlg  text-secondary mb-4">
                  2. Upgrade your account to tier 3
                </h4>
                <h4 className="md:text-tlg text-tlg  text-secondary mb-4">
                  3. Apply to become an ambassador
                </h4>
                {/* <button className="heading md:mt-0 mt-4 manrope500 shadow-md flex items-center gap-3 bg-tertiary text-white font-bold rounded-full py-4 px-10 cursor-pointer ease-in-out duration-300 hover:shadow-md hover:bg-white hover:text-tertiary">
                  Get Started
                </button> */}
              </div>
            </div>
          </div>
        </section>
      </Fade>
      <Fade>
        <section className="container max-w-[1250px] mx-auto mb-40">
          <h4 className="heading text-secondary md:text-dmd text-txs md:text-center font-bold">
            BillSpot for Business
          </h4>
          <p className="mt-3 mb-[20px] md:max-w-[80%] text-off text-tlg mx-auto md:w-[90%] md:text-center">
            Open up your business beyond limitless boundaries with BillSpot's
            online gift card feature! Sign up and create personalized gift cards
            for your customers, enhancing their shopping experience and driving
            more patronage.
          </p>

          <Slider {...settings}>
            <span className="p-2 pb-6">
              <img
                src="https://images.pexels.com/photos/2649771/pexels-photo-2649771.jpeg?auto=compress&cs=tinysrgb&w=1200&lazy=load"
                alt="ambassador"
                className="w-full md:h-[500px] rounded-[30px]"
              />
              <div className="font-semibold -mt-[15px] text-center text-black">
                <span className="bg-black text-white rounded-full p-3">
                  Restaurants and Cafe
                </span>
              </div>
            </span>
            <span className="p-2 pb-6">
              <img
                src={spa}
                alt="ambassador"
                className="w-full md:h-[500px] rounded-[30px]"
              />
              <div className="font-semibold -mt-[15px] text-center text-black">
                <span className="bg-black text-white rounded-full p-3">
                  Spa
                </span>
              </div>
            </span>
            <span className="p-2 pb-6">
              <img
                src="https://media.istockphoto.com/id/1265592131/photo/handsome-young-african-american-man-is-spraying-cleaning-foam-to-a-modern-red-car-in-outdoor.jpg?s=612x612&w=0&k=20&c=uTE-G1iKcswDBs1meaaYlLOECFxAkON_wQpQwZO_yeo="
                alt="ambassador"
                className="w-full md:h-[500px] rounded-[30px]"
              />
              <div className="font-semibold -mt-[15px] text-center text-black">
                <span className="bg-black text-white rounded-full p-3">
                  Car Wash
                </span>
              </div>
            </span>
            {/* <span className="p-2 pb-6">
              <img
                src="https://images.pexels.com/photos/14515104/pexels-photo-14515104.jpeg?auto=compress&cs=tinysrgb&w=1200&lazy=load"
                alt="ambassador"
                className="w-full md:h-[500px] rounded-[30px]"
              />
              <div className="font-semibold -mt-[15px] text-center text-black">
                <span className="bg-black text-white rounded-full p-3">
                  Restaurants
                </span>
              </div>
            </span> */}
            <span className="p-2 pb-6">
              <img
                src="https://media.istockphoto.com/id/492965017/photo/interior-of-shoe-store-in-modern-european-mall.jpg?s=612x612&w=0&k=20&c=lEonnte8UzI24b3chmpgHqS12YgQ5S1n3Za_JsdPFK4="
                alt="ambassador"
                className="w-full md:h-[500px] rounded-[30px]"
              />
              <div className="font-semibold -mt-[15px] text-center text-black">
                <span className="bg-black text-white rounded-full p-3">
                  Fashion items
                </span>
              </div>
            </span>
            <span className="p-2 pb-6">
              <img
                src="https://images.pexels.com/photos/1954524/pexels-photo-1954524.jpeg?auto=compress&cs=tinysrgb&w=1200"
                alt="ambassador"
                className="w-full md:h-[500px] rounded-[30px]"
              />
              <div className="font-semibold -mt-[15px] text-center text-black">
                <span className="bg-black text-white rounded-full p-3">
                  Gym
                </span>
              </div>
            </span>
            <span className="p-2 pb-6">
              <img
                src="https://media.istockphoto.com/id/1360028066/photo/looking-for-a-book.jpg?s=612x612&w=0&k=20&c=b56rUpAt4nApchLyUPNjf-oUmoTatRElClVk2ud9rS4="
                alt="ambassador"
                className="w-full md:h-[500px] rounded-[30px]"
              />
              <div className="font-semibold -mt-[15px] text-center text-black">
                <span className="bg-black text-white rounded-full p-3">
                  Bookstore
                </span>
              </div>
            </span>
            <span className="p-2 pb-6">
              <img
                src="https://media.istockphoto.com/id/514375117/photo/flower-shop.jpg?s=612x612&w=0&k=20&c=wXcHH-VbOZ9qhaadMsGi88JY71nUNKtZzeRECCadlt8="
                alt="ambassador"
                className="w-full md:h-[500px] rounded-[30px]"
              />
              <div className="font-semibold -mt-[15px] text-center text-black">
                <span className="bg-black text-white rounded-full p-3">
                  Florists
                </span>
              </div>
            </span>
            <span className="p-2 pb-6">
              <img
                src="https://media.istockphoto.com/id/1414375324/photo/chihuahua-and-west-highland-terrier-dogs-sitting-in-petshop.jpg?s=612x612&w=0&k=20&c=brkSzuJPp-86n0emKjNqMvMvZ3sRvJOwZD4BIaVUt5w="
                alt="ambassador"
                className="w-full md:h-[500px] rounded-[30px]"
              />
              <div className="font-semibold -mt-[15px] text-center text-black">
                <span className="bg-black text-white rounded-full p-3">
                  Pet Store
                </span>
              </div>
            </span>
            <span className="p-2 pb-6">
              <img
                src="https://media.istockphoto.com/id/1346705134/photo/laundry-shop-interior-with-counter-and-washing-machines-3d-rendering.jpg?s=612x612&w=0&k=20&c=xJWp30P7LzfnFTWtXaCmhTRSxdowWITOKhqNlrM1hc0="
                alt="ambassador"
                className="w-full md:h-[500px] rounded-[30px]"
              />
              <div className="font-semibold -mt-[15px] text-center text-black">
                <span className="bg-black text-white rounded-full p-3">
                  Laundromat
                </span>
              </div>
            </span>
          </Slider>
        </section>
      </Fade>
      <Faq />
      <DownloadApp />
      <Footer />
    </ScrollToTop>
  );
};

export default Home;
