import React, { ChangeEvent, useEffect, useState } from 'react';
import { useUpdateSocialInfo } from '../../../../../hooks/api/business/profile/useUpdateSocialInfo';
import { useGetBusinessData } from '../../../../../hooks/api/business/profile/useGetBusinessData';
import toast from 'react-hot-toast';

// Define the payload type for the social media data
type SocialMediaPayload = {
  instagram: string;
  facebook: string;
  twitter: string;
  whatsapp: string;
  linkedin: string;
};

const SocialInformation = () => {
  const { data } = useGetBusinessData();

  // Initialize the payload state with an empty string for each social media link
  const [payload, setPayload] = useState<SocialMediaPayload>({
    instagram: '',
    facebook: '',
    twitter: '',
    whatsapp: '',
    linkedin: '',
  });

  // Store the initial fetched data to compare with current payload
  const [initialData, setInitialData] = useState<SocialMediaPayload>({
    instagram: '',
    facebook: '',
    twitter: '',
    whatsapp: '',
    linkedin: '',
  });

  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);

  const { mutate, isLoading } = useUpdateSocialInfo(payload);

  // Prefill the form when business data is available and store it as initial data
  useEffect(() => {
    if (data) {
      const fetchedData: SocialMediaPayload = {
        instagram: data?.data?.socials?.instagram || '',
        facebook: data?.data?.socials?.facebook || '',
        twitter: data?.data?.socials?.twitter || '',
        whatsapp: data?.data?.socials?.whatsapp || '',
        linkedin: data?.data?.socials?.linkedin || '',
      };

      setPayload(fetchedData);
      setInitialData(fetchedData); // Save initial data for comparison
    }
  }, [data]);

  // Validate the form fields and check if any changes have been made
  useEffect(() => {
    const { instagram, facebook, twitter, whatsapp } = payload;

    // Ensure form is valid (non-empty fields)
    const isFormValid = Boolean(instagram && facebook && twitter && whatsapp);

    // Check if the payload is different from the initial data
    const isChanged = JSON.stringify(payload) !== JSON.stringify(initialData);

    // Enable submit only if form is valid and changes have been made
    setIsSubmitEnabled(isFormValid && isChanged);
  }, [payload, initialData]);

  // Handle input changes
  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setPayload((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Handle form submission
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (isSubmitEnabled) {
      mutate();
    } else {
      toast('Not able to submit');
    }
  };

  return (
    <form onSubmit={handleSubmit} className="mt-10">
      <div className="grid md:grid-cols-2 grid-cols-1 gap-4">
        <div className="mb-4">
          <label htmlFor="instagram" className="mb-3 block heading text-tmd">
            Instagram URL
          </label>
          <input
            type="url"
            name="instagram"
            placeholder=""
            autoComplete="off"
            value={payload.instagram}
            onChange={handleChange}
            className="w-full border-oslo border-[1px] p-3 rounded-md"
          />
        </div>

        <div className="mb-4">
          <label htmlFor="facebook" className="mb-3 block heading text-tmd">
            Facebook URL
          </label>
          <input
            type="url"
            name="facebook"
            placeholder=""
            value={payload.facebook}
            autoComplete="off"
            onChange={handleChange}
            className="w-full border-oslo border-[1px] p-3 rounded-md"
          />
        </div>

        <div className="mb-4">
          <label htmlFor="twitter" className="mb-3 block heading text-tmd">
            Twitter URL
          </label>
          <input
            type="url"
            name="twitter"
            placeholder=""
            value={payload.twitter}
            onChange={handleChange}
            className="w-full border-oslo border-[1px] p-3 rounded-md"
          />
        </div>

        <div className="mb-4">
          <label htmlFor="whatsapp" className="mb-3 block heading text-tmd">
            WhatsApp Number
          </label>
          <input
            type="tel"
            name="whatsapp"
            placeholder=""
            autoComplete="off"
            maxLength={11}
            value={payload.whatsapp}
            onChange={handleChange}
            className="resize-none w-full border-oslo border-[1px] p-3 rounded-md"
          />
        </div>

        <div className="mb-4">
          <label htmlFor="linkedin" className="mb-3 block heading text-tmd">
            LinkedIn URL
          </label>
          <input
            type="url"
            name="linkedin"
            placeholder=""
            autoComplete="off"
            value={payload.linkedin}
            onChange={handleChange}
            className="resize-none w-full border-oslo border-[1px] p-3 rounded-md"
          />
        </div>
      </div>

      <button
        type="submit"
        className="bg-tertiary p-3 rounded-md text-center text-white font-medium cursor-pointer"
        disabled={!isSubmitEnabled || isLoading}
      >
        Submit
      </button>
    </form>
  );
};

export default SocialInformation;
