import { useMutation } from 'react-query';
import toast from 'react-hot-toast';
import { userAxiosInstance } from '../../../../utils/userAxiosInstance';
import { useNavigate } from 'react-router-dom';
import { useSidebar } from '../../../../contexts/SidebarContext';

export const useResetPin = (setLevels: any) => {
  return useMutation(
    (email: string) =>
      userAxiosInstance
        .post('auth/reset-transaction-pin', { email: email })
        .then((res: any) => res.data),
    {
      onSuccess: async (data) => {
        setLevels(1);
        toast(data?.message);
      },
      onError: (error: any) => {
        console.log('❌ useResetPassword ', error?.response?.data?.message);
        toast(error?.response?.data?.message);
      },
    }
  );
};

export const useConfirmResetPin = () => {
  const { closeSidebar } = useSidebar();

  return useMutation(
    (payload: any) =>
      userAxiosInstance
        .post('auth/confirm-transaction-pin-reset', payload)
        .then((res: any) => res.data),
    {
      onSuccess: async (data) => {
        closeSidebar();
        toast(data?.message);
      },
      onError: (error: any) => {
        console.log(
          '❌ useConfirmResetPassword ',
          error?.response?.data?.message
        );
        toast(error?.response?.data?.message);
      },
    }
  );
};
