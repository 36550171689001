import { useQuery } from 'react-query';
import { axiosInstance } from '../../../../utils/axiosInstance';

export const useGetBanks = () => {
  return useQuery({
    queryKey: 'useGetBanks',
    queryFn: () =>
      // axiosInstance.get('misc/banks?next=YmFuazo0OTY=').then((res) => res.data),
      axiosInstance.get('misc/banks').then((res) => res.data),
    onSuccess: (data) => {
      console.log('✅ useGetBanks');
    },
    onError: (error: any) => {
      console.log('❌ useGetBanks', error.message);
    },
  });
};
